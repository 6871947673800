import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../components/Home';
import GrandHome from '../components/GrandHome';
import routes from '../constants/routes.json';
import SignIn from '../components/SignIn';
import PrivateRouter from './PrivateRouter';
import WriteArticle from '../components/WriteArticle';
import UserInfo from '../components/UserInfo';
import ArticleView from '../components/Article';
import Admin from '../components/Admin';
// import OnlyPublicRouter from './OnlyPublicRouter';

export default function Routes() {
  return (
    <Switch>
      {/* <OnlyPublicRouter path={routes.SIGN_IN} component={SignIn} /> */}
      {/* <Route path={routes.SIGN_IN} component={SignIn} /> */}
      {/* <PrivateRouter path={routes.ADMIN} component={Admin} />
      <PrivateRouter
        exact
        path={`${routes.WRITE_ARTICLE}/:id?`}
        component={WriteArticle}
      />*/}
      <PrivateRouter
        exact
        path={`${routes.ARTICLE}/:id?`}
        component={ArticleView}
      />
      {/* <PrivateRouter path={`${routes.USER_INFO}/:tab?`} component={UserInfo} /> */}
      <PrivateRouter path={routes.USER} component={Home} />
      <Route path={routes.HOME} component={GrandHome} />
    </Switch>
  );
}
