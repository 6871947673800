import { Button, Icon, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import React, { useEffect, useState } from 'react';
import { highlightText } from '../../../../../utils/highlightText';

import styles from '../index.module.scss';

const Selection = Select.ofType<string>();

type SelectGroupProps = Readonly<{
  label: string | JSX.Element;
  value: string;
  labelKey?: string;
  onChange?: (payload: {
    newValue: string;
    labelKey: string | undefined;
  }) => void;
  optionList: string[];
}>;

const SelectGroup: React.FC<SelectGroupProps> = (props) => {
  const { label, labelKey, value, onChange, optionList } = props;

  const [activeValue, updateActiveValue] = useState<string>(value);

  useEffect(() => {
    updateActiveValue(value);
  }, [value]);

  const renderSelectItem: ItemRenderer<string> = (
    selectItem,
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={activeValue === selectItem}
        // disabled={modifiers.disabled}
        key={selectItem}
        text={highlightText(selectItem, query)}
        onClick={handleClick}
      />
    );
  };

  return (
    <div className={styles.labelValueGroup}>
      <div>{label}</div>

      <div>
        <Selection
          popoverProps={{
            popoverClassName: styles.popoverClassName,

            modifiers: { arrow: { enabled: false } },
          }}
          items={optionList}
          itemRenderer={renderSelectItem}
          onItemSelect={(item) => {
            const newValue = item;
            updateActiveValue(newValue);

            if (typeof onChange === 'function') {
              onChange({ newValue, labelKey });
            }
          }}
          inputProps={{
            placeholder: '搜索内容',
            rightElement: <Icon icon="caret-up" color="#aaa" />,
          }}
        >
          <button className={styles.selectBtn}>
            <span>
              {activeValue === null || activeValue === ''
                ? '未选择'
                : activeValue}
            </span>
            <Icon icon="caret-down" color="#aaa" />
          </button>
        </Selection>
      </div>
    </div>
  );
};

export default SelectGroup;
