import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Button, Dialog } from '@blueprintjs/core';

import styles from './index.module.scss';
import ReportView from './Report';
import { withRouter, RouteComponentProps } from 'react-router';
import { RelationReport, relationReportTitles } from './types';
import requests from '../../../../../utils/requests';
import { apiConfig } from '../../../../../constants/apiConfig';
import { AxiosResponse } from 'axios';
import { AuthState } from '../../../../../reducers/auth';
import PyrSpinner from '../../../../common/PyrSpinner';

/**
 * 量表统计信息状态类型
 */
type RelationStatisticsState = {
  isLoading: boolean;
  data: RelationStatisticsItem[];
};

/**
 * 量表统计信息单元类型
 */
type RelationStatisticsItem = Readonly<{
  queTotal: number;
  type: number;
  userAnswerTotal: number;
  value: string | null;
  finishTime: number | null;
}>;

/**
 * 默认量表统计信息状态
 */
const defaultRelationStatisticsState: RelationStatisticsState = {
  isLoading: false,
  data: [],
};

type RelationListOwnProps = Readonly<{
  isExpanded: boolean;
  treatLogId: number;
  authModel: AuthState;
}>;

const RelationList: React.FC<
  RelationListOwnProps & RouteComponentProps<{ id: string }>
> = (props) => {
  const { treatLogId, match, authModel, isExpanded } = props;

  const [
    relationStatisticsState,
    updateRelationStatisticsState,
  ] = useState<RelationStatisticsState>(defaultRelationStatisticsState);

  const retrieveRelationStatistics = async () => {
    updateRelationStatisticsState({ isLoading: true, data: [] });

    const res: AxiosResponse<{
      object: RelationStatisticsItem[];
    }> = await requests.get(
      `${apiConfig.GET_RELATION_STATISTICS}?${queryString.stringify({
        treatLogId,
        patientId: match.params.id,
      })}`,
      { headers: { token: authModel.token } }
    );

    // @ts-ignore
    updateRelationStatisticsState({
      isLoading: false,
      // @ts-ignore
      data: res.data.success ? res.data.object : [],
    });
  };

  useEffect(() => {
    if (isExpanded) {
      retrieveRelationStatistics();
    }
  }, []);

  useEffect(() => {
    if (isExpanded) {
      retrieveRelationStatistics();
    } else {
      updateRelationStatisticsState(defaultRelationStatisticsState);
    }
  }, [isExpanded]);

  const [
    /**
     * 选择的量表
     */
    selectedReport,
    updateSelectedReport,
  ] = useState<RelationReport | null>(null);

  // TODO: 代码结构修改
  return relationStatisticsState.data.length === 0 ? (
    <></>
  ) : (
    <div className={styles.wrapper}>
      <Button
        minimal
        onClick={() => {
          updateSelectedReport(RelationReport.SchwabEngland);
        }}
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.SchwabEngland].title}</p>
          <p>{relationReportTitles[RelationReport.SchwabEngland].subTitle}</p>
          <div>
            <span>{relationStatisticsState.data[0].value?.split('%')[0]}</span>
            <span>%</span>
          </div>
        </div>
      </Button>

      <Button
        onClick={() => {
          updateSelectedReport(RelationReport.NMSS);
        }}
        minimal
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.NMSS].title}</p>
          <p>{relationReportTitles[RelationReport.NMSS].subTitle}</p>
          <div>
            <span>{relationStatisticsState.data[1].userAnswerTotal}</span>
            <span>/{relationStatisticsState.data[1].queTotal}</span>
          </div>
        </div>
      </Button>

      <Button
        onClick={() => {
          updateSelectedReport(RelationReport.QueenSquare);
        }}
        minimal
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.QueenSquare].title}</p>
          <p>{relationReportTitles[RelationReport.QueenSquare].subTitle}</p>
          <div>
            <span>{relationStatisticsState.data[2].userAnswerTotal}</span>
            <span>/{relationStatisticsState.data[2].queTotal}</span>
          </div>
        </div>
      </Button>

      <Button
        onClick={() => {
          updateSelectedReport(RelationReport.AutonomicNerve);
        }}
        minimal
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.AutonomicNerve].title}</p>
          <p>{relationReportTitles[RelationReport.AutonomicNerve].subTitle}</p>
          <div>
            <span>{relationStatisticsState.data[3].userAnswerTotal}</span>
            <span>/{relationStatisticsState.data[3].queTotal}</span>
          </div>
        </div>
      </Button>

      <Button
        onClick={() => {
          updateSelectedReport(RelationReport.MAES);
        }}
        minimal
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.MAES].title}</p>
          <p>{relationReportTitles[RelationReport.MAES].subTitle}</p>
          <div className={styles.relationBtn__MAESValue}>
            {relationStatisticsState.data[4].userAnswerTotal}
          </div>
        </div>
      </Button>

      <Button
        onClick={() => {
          updateSelectedReport(RelationReport.PDSS);
        }}
        minimal
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.PDSS].title}</p>
          <p>{relationReportTitles[RelationReport.PDSS].subTitle}</p>
          <div>
            <span>{relationStatisticsState.data[5].userAnswerTotal}</span>
            <span>/{relationStatisticsState.data[5].queTotal}</span>
          </div>
        </div>
      </Button>

      <Button
        onClick={() => {
          updateSelectedReport(RelationReport.REM);
        }}
        minimal
      >
        <div className={styles.relationBtn}>
          <p>{relationReportTitles[RelationReport.REM].title}</p>
          <p></p>

          <div>
            <span>{relationStatisticsState.data[6].userAnswerTotal}</span>
            <span>/{relationStatisticsState.data[6].queTotal}</span>
          </div>
        </div>
      </Button>

      <Dialog
        isOpen={selectedReport !== null}
        onClose={() => {
          updateSelectedReport(null);
        }}
        // title={<GroupCreateModalTitle activeStep={activeStep} />}
        style={{ width: 980 }}
      >
        {selectedReport !== null && (
          <>
            <ReportView
              treatLogId={treatLogId}
              patientId={parseInt(props.match.params.id)}
              relationReport={selectedReport}
              updateSelectedReport={updateSelectedReport}
              finishTime={
                relationStatisticsState.data[selectedReport].finishTime
              }
            />
          </>
        )}
      </Dialog>
    </div>
  );
};

export default withRouter(RelationList);
