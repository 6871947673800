import React from 'react';

import { ReportRow } from '../types';
import styles from './index.module.scss';

const AutonomicNerveReportTable: React.FC<{ reportData: ReportRow[] }> = (
  props
) => {
  const { reportData } = props;

  /**
   *
   * @param startingRowNum 表格真实起始序号（非数组index）
   * @param endingRowNum 表格真实结束序号（非数组index）
   */
  const generateRowData = (startingRowNum: number, endingRowNum: number) => {
    return reportData
      .slice(startingRowNum - 1, endingRowNum)
      .map((reportRow, index) => {
        const rowNumber = startingRowNum + index;

        return (
          <tr key={reportRow.id}>
            <td>
              {rowNumber < 23
                ? rowNumber
                : rowNumber === 24
                ? '23a'
                : rowNumber - 1}
            </td>
            <td>{reportRow.content}</td>
            <td className={styles.autonomicNerveAnswerTd}>{reportRow.relationAnswerList[0].userAnswer}</td>
          </tr>
        );
      });
  };

  return (
    <>
      <p>评判标准：1、从不 2、有时 3、时常 4、频繁</p>
      <table className={styles.reportTable}>
        <thead>
          <tr>
            <th>序号</th>
            <th>问题</th>
            <th>回答</th>
          </tr>
        </thead>
        <tbody>
          {generateRowData(1, 21)}
          <tr>
            <td colSpan={3}>
              <p style={{ color: 'red' }}>接下来的3个问题只需男性回答</p>
              <p>1、从不 2、有时 3、时常 4、频繁 5、使用导管</p>
            </td>
          </tr>
          {generateRowData(22, 24)}
          <tr>
            <td colSpan={3}>
              <p style={{ color: 'red' }}>接下来的2个问题只需女性回答。</p>
              <p>1、从不 2、有时 3、时常 4、频繁 5、不适用</p>
            </td>
          </tr>
          {generateRowData(25, 27)}
        </tbody>
      </table>
    </>
  );
};

export default AutonomicNerveReportTable;
