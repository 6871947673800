/**
 * 危险因素的药物列表
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import produce from 'immer';
import { AxiosResponse } from 'axios';

import { GlobalState } from '../../../../../store/configureStore';
import { AuthState } from '../../../../../reducers/auth';
import { apiConfig } from '../../../../../constants/apiConfig';
import { CommonHTTPResponse } from '../../../../../commonTypes';
import CheckableProperty from '../components/CheckableProperty';

import requests from '../../../../../utils/requests';
import PyrSpinner from '../../../../common/PyrSpinner';
import styles from './index.module.scss';
import OthersEditPopover from '../components/OthersEditPopover';

/**
 * 公共药物列表资源请求状态
 */
type CommonMedicineList = Readonly<{
  data: CommonMedicineItem[];
  isLoading: boolean;
}>;

type CommonMedicineItem = Readonly<{
  id: number;
  name: string;
}>;

type CommonMedicineResponse = CommonHTTPResponse<{
  data: CommonMedicineItem[];
}>;

const defaultCommonMedicineListState: CommonMedicineList = {
  data: [],
  isLoading: false,
};

/**
 * 组件 props 类型定义
 */
type MedicineListOwnProps = Readonly<{
  medicineListStrSet: string;
  updateMedicineListStrSet: (value: string) => void;
}>;

type MedicineListStateProps = Readonly<{
  authModel: AuthState;
}>;

const MedicineList: React.FC<MedicineListOwnProps & MedicineListStateProps> = (
  props
) => {
  const { medicineListStrSet, authModel, updateMedicineListStrSet } = props;

  // 公共药物列表
  const [commonMedicineList, updateCommonMedicineList] = useState<
    CommonMedicineList
  >(defaultCommonMedicineListState);

  // 获取公共药物列表
  const retrieveCommonMedicineList = async () => {
    updateCommonMedicineList({ isLoading: true, data: [] });

    const res: AxiosResponse<CommonMedicineResponse> = await requests.post(
      apiConfig.GET_TAKING_MEDICINE_LIST,
      {},
      { headers: { token: authModel.token } }
    );

    updateCommonMedicineList({
      data: res.data.success ? res.data.data : [],
      isLoading: false,
    });
  };

  const defaultCommonUsingMedicineList: string[] = medicineListStrSet
    .split('|')[0]
    .split(',');

  const defaultCustomizedUsingMedicineList: string[] =
    typeof medicineListStrSet.split('|')[1] === 'string'
      ? medicineListStrSet
          .split('|')[1]
          .split(',')
          .filter((item) => item.length > 0)
      : [];

  // 公共用药列表
  const [commonUsingMedicineList, updateCommonUsingMedicineList] = useState<
    string[]
  >(defaultCommonUsingMedicineList);

  // 自定义用药列表
  const [
    customizedUsingMedicineList,
    updateCustomizedUsingMedicineList,
  ] = useState<string[]>(defaultCustomizedUsingMedicineList);

  const generatecustomizedUsingMedicineList = (
    commonUsingMedicineList: string[],
    customizedUsingMedicineList: string[]
  ) => {
    return `${commonUsingMedicineList.join(
      ','
    )}|${customizedUsingMedicineList.join(',')}`;
  };

  /**
   *  更改公共药物列表的选择状态
   * @param value 药物名称
   * @param selected 选择与否
   */
  const updateCommonUsingMedicineListByValue = (
    value: string,
    selected: boolean
  ) => {
    let newCommonUsingMedicineList: string[] = [];

    if (selected) {
      newCommonUsingMedicineList = [...commonUsingMedicineList, value];
    } else {
      const index = commonUsingMedicineList.indexOf(value);

      newCommonUsingMedicineList = produce(commonUsingMedicineList, (draft) => {
        draft.splice(index, 1);
      });
    }

    updateCommonUsingMedicineList(newCommonUsingMedicineList);

    updateMedicineListStrSet(
      generatecustomizedUsingMedicineList(
        newCommonUsingMedicineList,
        customizedUsingMedicineList
      )
    );
  };

  // /**
  //  *  更改自定义药物列表的选择状态
  //  * @param value 药物名称
  //  * @param selected 选择与否
  //  */
  // const updateCustomizedUsingMedicineListByValue = (
  //   value: string,
  //   selected: boolean
  // ) => {
  //   if (selected) {
  //     updateCustomizedUsingMedicineList([
  //       ...customizedUsingMedicineList,
  //       value,
  //     ]);
  //   } else {
  //     const index = customizedUsingMedicineList.indexOf(value);

  //     const newCustomizedUsingMedicineList = produce(
  //       customizedUsingMedicineList,
  //       (draft) => {
  //         draft.splice(index, 1);
  //       }
  //     );

  //     updateCustomizedUsingMedicineList(newCustomizedUsingMedicineList);

  //     updateMedicineListStrSet(
  //       generatecustomizedUsingMedicineList(
  //         newCustomizedUsingMedicineList,
  //         commonUsingMedicineList
  //       )
  //     );
  //   }
  // };

  useEffect(() => {
    retrieveCommonMedicineList();
  }, []);

  // 添加自定义药物输入框
  const [isAddMedicineInputActive, setAddMedicineInputActive] = useState<
    boolean
  >(false);

  return (
    <section className={styles.medicineSection}>
      {commonMedicineList.isLoading ? (
        <PyrSpinner />
      ) : (
        commonMedicineList.data.map((medicineItem) => (
          <CheckableProperty
            key={medicineItem.id}
            title={medicineItem.name}
            checked={commonUsingMedicineList.includes(medicineItem.name)}
            onChange={(selected) => {
              updateCommonUsingMedicineListByValue(medicineItem.name, selected);
            }}
          />
        ))
      )}

      <div className={styles.othersBtnWrapper}>
        <OthersEditPopover
          customizedList={customizedUsingMedicineList}
          updateCustomizedList={(newList) => {
            updateCustomizedUsingMedicineList(newList);

            updateMedicineListStrSet(
              generatecustomizedUsingMedicineList(
                commonUsingMedicineList,
                newList
              )
            );
          }}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  MedicineListStateProps,
  {},
  MedicineListOwnProps,
  GlobalState
>(mapStateToProps)(MedicineList);
