// import {

import {
  UpdateAdminDoctorListAction,
  UPDATE_ADMIN_DOCTOR_LIST,
} from '../../actions/adminDoctorManagement';
import { AdminDoctorListItem } from './types';

// }

export type AdminDoctorManagementState = Readonly<{
  list: AdminDoctorListItem[];
  isLoading: boolean;
  pageNum: number;
  pageSize: number;
  totalCount: number;
}>;

const defaultState: AdminDoctorManagementState = {
  list: [],
  isLoading: false,
  pageNum: 0,
  pageSize: 6,
  totalCount: 0,
};

export default function adminDoctorManagement(
  state = defaultState,
  action: UpdateAdminDoctorListAction
) {
  switch (action.type) {
    case UPDATE_ADMIN_DOCTOR_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }
  }

  return state;
}
