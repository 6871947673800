// 带有 checkbox 的属性选择器
import React from 'react';
import { Checkbox } from '@blueprintjs/core';

import styles from './index.module.scss';

type CheckablePropertyProps = Readonly<{
  title: string;
  checked: boolean;
  onChange?: (value: boolean) => void;
  reverseColor?: boolean;
}>;

const CheckableProperty: React.FC<CheckablePropertyProps> = (props) => {
  const { title, checked, onChange, reverseColor } = props;

  return (
    <button
      className={styles.checkableProperty}
      onClick={() => {
        if (typeof onChange === 'function') {
          onChange(!checked);
        }
      }}
    >
      <div className={styles.checkboxBtn}>
        {checked ? (
          <i
            className="pyr pyrpain_selection"
            style={{
              color: reverseColor ? '#fff' : '#404040',
            }}
          />
        ) : (
          <i
            className="pyr pyrunselected"
            style={{
              color: reverseColor ? '#fff' : '#8f8f94',
            }}
          />
        )}
      </div>
      <span
        style={{
          color: reverseColor
            ? checked
              ? '#fff'
              : '#8f8f94'
            : checked
            ? '#404040'
            : '#8f8f94',
        }}
      >
        {title}
      </span>
    </button>
    // <Checkbox
    //   checked={checked}
    //   label={title}
    //   onChange={() => {
    //     if (typeof onChange === 'function') {
    //       onChange(!checked);
    //     }
    //   }}
    // />
  );
};

export default CheckableProperty;
