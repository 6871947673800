import {
  UpdateAtGroupQnaListAction,
  UpdateAtMineQnaListAction,
  UpdateQnaChatDetailsAction,
  UpdateSelectedChatIdAction,
  UPDATE_AT_GROUP_QNA_LIST,
  UPDATE_AT_MINE_QNA_LIST,
  UPDATE_QNA_CHAT_DETAILS,
  UPDATE_SELECTED_CHAT_ID_ACTION,
  UpdateQuestionListAction,
  UPDATE_QUESTION_LIST,
  UPDATE_NOT_READ_CHAT_COUNT,
  UpdateNotReadChatCountAction,
} from '../../actions/qna';
import { QnaChatDetail, QnaRecord } from './types';

const QNA_PAGE_SIZE = 50;

export type QnaState = Readonly<{
  atMineList: {
    data: QnaRecord[];
    isLoading: boolean;
    pageNumber: number;
    pageSize: 50;
    hasBeenToTheEnd: boolean;
  };
  atGroupList: {
    data: QnaRecord[];
    isLoading: boolean;
    pageNumber: number;
    pageSize: 50;
    hasBeenToTheEnd: boolean;
  };
  qnaChatDetails: {
    data: QnaChatDetail[];
    isLoading: boolean;
    // pageNumber: number;
    // pageSize: 20;
    // hasBeenToTheEnd: boolean;
  };
  /**
   * 所选问答 id
   */
  selectedChatRecord: (QnaRecord & { mainChatId?: number }) | null;

  /**
   * 问题列表
   */
  questionList: {
    data: QnaRecord[];
    isLoading: boolean;
    patientId: number | null;
  };

  /**
   * 未读消息数量
   */
  unreadMsgCount: number;
}>;

const defaultQnaState: QnaState = {
  atMineList: {
    data: [],
    isLoading: false,
    pageNumber: 0,
    pageSize: QNA_PAGE_SIZE,
    hasBeenToTheEnd: false,
  },
  atGroupList: {
    data: [],
    isLoading: false,
    pageNumber: 0,
    pageSize: QNA_PAGE_SIZE,
    hasBeenToTheEnd: false,
  },

  /**
   * 与选择患者的聊天详情
   */
  qnaChatDetails: {
    data: [],
    isLoading: false,
    // pageNumber: 0,
    // pageSize: 20,
    // hasBeenToTheEnd: false,
  },
  selectedChatRecord: null,

  /**
   * 问题列表
   */
  questionList: {
    data: [],
    isLoading: false,
    patientId: null,
  },

  /**
   * 未读消息数量
   */
  unreadMsgCount: 0,
};

export default function qna(
  state = defaultQnaState,
  actions:
    | UpdateAtMineQnaListAction
    | UpdateAtGroupQnaListAction
    | UpdateQnaChatDetailsAction
    | UpdateSelectedChatIdAction
    | UpdateQuestionListAction
    | UpdateNotReadChatCountAction
) {
  switch (actions.type) {
    case UPDATE_AT_MINE_QNA_LIST:
      return {
        ...state,
        atMineList: {
          ...state.atMineList,
          ...actions.payload,
        },
      };

    case UPDATE_AT_GROUP_QNA_LIST:
      return {
        ...state,
        atGroupList: {
          ...state.atGroupList,
          ...actions.payload,
        },
      };

    case UPDATE_QNA_CHAT_DETAILS:
      return {
        ...state,
        qnaChatDetails: {
          ...state.qnaChatDetails,
          ...actions.payload,
        },
      };

    case UPDATE_SELECTED_CHAT_ID_ACTION:
      return {
        ...state,
        selectedChatRecord:
          actions.payload.record === null
            ? null
            : {
                ...actions.payload.record,
                mainChatId:
                  actions.payload.record.mainChatId ??
                  state.selectedChatRecord?.mainChatId,

                avatar:
                  actions.payload.record.avatar === null
                    ? state.selectedChatRecord?.avatar
                    : actions.payload.record.avatar,
              },
      };

    case UPDATE_QUESTION_LIST:
      return {
        ...state,
        questionList: {
          ...state.questionList,
          ...actions.payload,
        },
      };

    case UPDATE_NOT_READ_CHAT_COUNT:
      return {
        ...state,
        unreadMsgCount: actions.payload.count,
      };
  }
  return state;
}
