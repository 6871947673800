import {
  UPDATE_PATIENT_LIST,
  UpdatePatientListAction,
} from '../actions/patients';

export type PatientsState = Readonly<{
  list: PatientItem[];
  isLoading: boolean;
  page: number;
  total: number;
  pageSize: number;
  hasBeenToTheEnd: boolean;
}>;

export type PatientItem = Readonly<{
  patientId: number;
  age: number;
  currentGroup: number;
  currentGroupId: number;
  follow: number;
  groupName: string;
  label: number;
  reply: number;
  sex: number;
  userName: string;
  avatar: string;
  confirmedDisease: string;
  dataUpdateTime: number;
  treatLogName: string;
}>;

const defaultPatientsState: PatientsState = {
  list: [],
  isLoading: false,
  page: 0,
  total: 0,
  pageSize: 30,
  hasBeenToTheEnd: false,
};

export default function articles(
  state = defaultPatientsState,
  actions: UpdatePatientListAction
) {
  switch (actions.type) {
    case UPDATE_PATIENT_LIST:
      return { ...state, ...actions.payload };
  }
  return state;
}
