/**
 * 文章预览
 */

import { AxiosResponse } from 'axios';
import { ContentState, convertFromRaw, RawDraftContentState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { stateToHTML } from 'draft-js-export-html';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import { RouteComponentProps } from 'react-router';
import { apiConfig } from '../../constants/apiConfig';
import { ArticleItem } from '../../reducers/articles';
import { AuthState } from '../../reducers/auth';
import { GlobalState, Dispatch } from '../../store/configureStore';
import requests from '../../utils/requests';
import { ArticleDetailsResponse } from '../UserInfo/types';
import styles from './index.module.scss';
import { UserInfoState } from '../../reducers/userInfo';
import routes from '../../constants/routes.json';
import PyrSpinner from '../common/PyrSpinner';
import { retrieveUserInfo } from '../../actions/userInfo';
import { formatTimestamp } from '../../utils/time';
import articleStyles from '../articleCommonFiles/article.module.scss';

type ArticleOwnProps = RouteComponentProps<{ id: string | undefined }>;

type ArticleStateProps = Readonly<{
  authState: AuthState;
  userInfo: UserInfoState;
}>;

type ArticleDispatchProps = Readonly<{
  retrieveUserInfo: () => void;
}>;

const ArticleView: React.FC<
  ArticleOwnProps & ArticleStateProps & ArticleDispatchProps
> = (props) => {
  const [isArticleDetailsLoading, setArticleDetailsLoading] = useState<boolean>(
    false
  );

  const [contentState, updateContentState] = useState<ContentState | null>(
    null
  );

  const [article, updateArticle] = useState<ArticleItem | null>(null);

  // 获取文章详情
  const retrieveArticleDetails = async () => {
    const articleId = props.match.params.id;

    setArticleDetailsLoading(true);

    const res: AxiosResponse<ArticleDetailsResponse> = await requests.post(
      `${apiConfig.GET_ARTICLE_DETAILS}?articleId=${articleId}`,
      {},
      { headers: { token: props.authState.token } }
    );

    const resData = res.data;

    if (resData.success) {
      const { title, content } = resData.data;

      updateArticle(resData.data);
      const convertedContentState = convertFromRaw(
        JSON.parse(content) as RawDraftContentState
      );

      updateContentState(convertedContentState);
    }

    setArticleDetailsLoading(false);
  };

  useEffect(() => {
    retrieveArticleDetails();
  }, []);

  useEffect(() => {
    props.retrieveUserInfo();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isArticleDetailsLoading && <PyrSpinner />}
      {article !== null && (
        <>
          {article.cover ? (
            <img style={{ width: '100%' }} src={article.cover} alt="封面" />
          ) : (
            ''
          )}
          <article className={styles.header}>
            <div>
              <h1>
                <span>{article?.title}</span>
              </h1>
              <div className={styles.createInfo}>
                <div className={styles.creatorAvatar}>
                  <img src={article.avatar} alt="医生头像" />
                </div>
                <span>发表于</span>
                <span className={styles.createTime}>
                  {formatTimestamp(article.createTime.toString())}
                </span>
              </div>
            </div>
            <div className={articleStyles.article}>
              {contentState !== null && parse(stateToHTML(contentState))}
            </div>
          </article>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authState: state.auth,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveUserInfo: () => {
    dispatch(retrieveUserInfo());
  },
});

export default connect<
  ArticleStateProps,
  ArticleDispatchProps,
  ArticleOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(ArticleView);
