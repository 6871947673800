// const HOST = 'http://118.31.66.124';
// const HOST = 'http://payouran.zjhospital.com.cn';
const HOST = '';

/**
 * 发布脚本：
 *
 * npm run build; scp -r build  root@47.104.99.247:/usr/share/nginx/html; ssh root@47.104.99.247 "cd /usr/share/nginx/html/; rm -rf pyr-doctor-fed; mv build pyr-doctor-fed"
 */

export const apiConfig = {
  SIGN_IN: `${HOST}/pyr/user/1/login`,
  POST_ARTICLE: `${HOST}/pyr/article/add`,
  GET_ARTICLE_LIST: `${HOST}/pyr/article/getList`,
  GET_ARTICLE_DETAILS: `${HOST}/pyr/article/detail`,
  GET_DOCTOR_DETAILS: `${HOST}/pyr/doctor/detail`,
  UPDATE_DOCTOR_ARTICLE: `${HOST}/pyr/article/edit`,
  UPDATE_DOCTOR_INFO: `${HOST}/pyr/doctor/editDoctor`,
  GET_DOCTOR_PATIENT_GROUP_LIST: `${HOST}/pyr/doctorLabel/customGroupList`,
  DELETE_LABEL: `${HOST}/pyr/doctorLabel/deleteLabel`,
  DELETE_PATIENT_GROUP_LABEL: `${HOST}/pyr/doctorLabel/deleteGroupLabel`,
  // 获取医生加入的团队列表
  GET_DOCTOR_GROUP_LIST: `${HOST}/pyr/group/getList`,
  // 获取医生加入的（患者列表向）团队列表
  GET_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST: `${HOST}/pyr/group/doctorGroupsJoined`,
  GET_LABLE_LIST: `${HOST}/pyr/doctorLabel/doctorLabelList`,
  // 添加医生名下患者分组
  ADD_DOCTOR_PATIENT_GROUP: `${HOST}/pyr/doctorLabel/add`,
  // 获取全部医院列表
  GET_WHOLE_HOSPITAL_LIST: `${HOST}/pyr/hospital/getAllHospital`,
  // 患者接口
  GET_PATIENT_LIST: `${HOST}/pyr/doctorFollowPatient/getList`,
  GET_PATIENT_DETAILS: `${HOST}/pyr/patient/getPatientById`,
  FOLLOW_PATIENT: `${HOST}/pyr/doctorFollowPatient/add`,
  UNFOLLOW_PATIENT: `${HOST}/pyr/doctorFollowPatient/remove`,
  // 患者标签列表
  GET_PATIENT_LABEL_LIST: `${HOST}/pyr/doctorLabel/patientLabelList`,
  // 根据标签labelId获取该医生下的患者列表
  GET_PATIENT_LIST_BY_LABEL_ID: `${HOST}/pyr/doctorLabel/getPatientListByLabelId`,
  // 获取患者就诊记录/病程记录列表
  GET_PATIENT_TREAT_LOG: `${HOST}/pyr/treatLog/getList`,
  // 获取医生名下患者列表
  GET_PATIENT_LIST_BY_DOCTOR: `${HOST}/pyr/doctorPatient/myPatientList`,
  // 获取病程记录
  GET_MEDICAL_RECORD: `${HOST}/pyr/payiran/quest/getDoctorQuest`,
  GET_RELATION_STATISTICS: `${HOST}/pyr/payiran/questionRelation/getRelationStatistics`,
  // 就诊详情-动作视频列表
  TREAT_LOG_ACTION_VIDEO_LIST: `${HOST}/pyr/treatLogResult/actionVideoList`,
  // 患者用药列表
  MEDICINE_PATIENT_LIST: `${HOST}/pyr/medicinePatient/getList`,
  /**
   * 就诊详情
   */
  TREAT_LOG_DETAIL: `${HOST}/pyr/treatLogResult/treatLogDetail`,
  // 更新患者信息
  UPDATE_PATIENT_INFO: `${HOST}/pyr/patient/edit`,
  //
  GET_GROUP_LIST_WITH_HOSPITAL_NAME: `${HOST}/pyr/group/getGroupListByHospitalName`,
  //
  GET_GROUP_LIST_BY_HOSPITAL_ID: `${HOST}/pyr/group/getGroupListByHospitalId`,
  // 医生加入团队
  JOIN_GROUP: `${HOST}/pyr/groupMember/addGroup`,
  // 获取医生列表
  GET_DOCTOR_LIST: `${HOST}/pyr/doctor/getDoctorList`,
  // 创建团队
  CREATE_NEW_GROUP: `${HOST}/pyr/group/createGroupName`,
  // 查询团队成员列表
  GET_DOCTOR_LIST_FROM_GROUP: `${HOST}/pyr/groupMember/getList`,
  // 退出团队
  SIGN_OUT_GROUP: `${HOST}/pyr/groupMember/signOutGroup`,
  // 查询医生统计信息
  GET_DOCTOR_STATISTICS_INFO: `${HOST}/pyr/doctorStatistics/getStatistics`,
  // 根据团队id获取团队下的患者列表信息（不包括当前医生患者）
  GET_PATIENT_LIST_BY_GROUP_ID: `${HOST}/pyr/group/getPatientListByGroupId`,
  GET_RELATION_LIST: `${HOST}/pyr/payiran/questionRelation`,
  // 根据患者分组列表获取患者列表信息
  GET_PATIENT_LIST_BY_GID: `${HOST}/pyr/doctorLabel/getPatientListByGId`,
  // 变更分组患者列表
  EDIT_PATIENT_LABEL: `${HOST}/pyr/doctorLabel/editPatientLabel`,

  /**
   * 公共信息接口
   */

  // 获取全部职称列表
  GET_ACADEMIC_TITLE: `${HOST}/pyr/common/academicTitle`,
  // 获取公共药物列表
  GET_TAKING_MEDICINE_LIST: `${HOST}/pyr/common/takingMedicine`,
  // 获取公共从业经历列表
  GET_WORKING_EXP_LIST: `${HOST}/pyr/common/workingExperience`,
  // 获取健康史列表
  GET_HEALTH_HISTORY: `${HOST}/pyr/common/healthHistory`,
  // 获取每日吸烟列表
  GET_SMOKE_AMOUNT: `${HOST}/pyr/common/smokeAmount`,
  // 上传附件
  UPLOAD: `${HOST}/pyr/common/upload`,

  /**
   * 医院数据接口
   */
  GET_HOSPITAL_LIST: `${HOST}/pyr/hospital/getAllHospital`,

  /**
   * qna
   */
  GET_QNA_LIST: `${HOST}/pyr/chat/getChatList`,
  GET_QNA_CHAT_DETAILS: `${HOST}/pyr/chatLog/getChatLogList`,
  DOCTOR_REPLY: `${HOST}/pyr/chatLog/doctorReply`,
  REMOVE_CHAT: `${HOST}/pyr/chat/remove`,
  CLOSE_CHAT: `${HOST}/pyr/chat/close`,
  OPEN_CHAT: `${HOST}/pyr/chat/open`,
  GET_PATIENT_QUESTION_LIST: `${HOST}/pyr/chat/selectPatientQuestionList`,
  // 公开问答记录
  PUBLISH_CHATS: `${HOST}/pyr/questionShare/openQuestions`,

  GET_DOCTOR_LIST_BY_CHAT_ID: `${HOST}/pyr/chatLog/getDoctorListByCharId`,
  // 获取未读问答会话数量（这里接口英文单词拼写有误）
  GET_NOT_READ_BY_DOCTOR_ID: `${HOST}/pyr/chat/getNotRedByDoctorId`,
  // 查看当前医生能否与指定患者进行聊天
  GET_CHAT_AVAILABILITY: `${HOST}/pyr/chat/getChatAvailability`,

  /**
   * 管理员端
   */
  ADMIN_GET_DOCTOR_LIST: `${HOST}/pyr/manager/doctor/getDoctorList`,
  UPLOAD_DOCTOR_IMPORT_FILE: `${HOST}/pyr/manager/importDoctor`,
  ADMIN_UPDATE_HOSPITAL: `${HOST}/pyr/manager/updateHospital`,
  ADMIN_GET_DOCTOR_EMAIL_LIST: `${HOST}/pyr/manager/doctor/getEmailList`,
  ADMIN_UPDATE_DOCTOR: `${HOST}/pyr/manager/doctor/editDoctor`,
};

// body {
//   background-color: #000;
// }

// div {
//   position: absolute;
//   top: calc(50% - 150px);
//   left: calc(50% - 150px);
//   width: 300px;
//   height: 300px;
//   border-radius: 50%;
//   box-shadow: 0 0 20px #fff;
//   animation: shinning ease 1.7s infinite;
// }

// @keyframes shinning {
//   0% { box-shadow: 0 0 20px #fff; }
//   100% { box-shadow: 0 0 70px #fff; }
// }
