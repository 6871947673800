import queryString from 'query-string';

import { ArticleItem } from '../reducers/articles';
import { Dispatch, GlobalState, ThunkFunction } from '../store/configureStore';
import { apiConfig } from '../constants/apiConfig';
import { CommonHTTPResponse } from '../commonTypes';
import { AxiosResponse } from 'axios';
import requests from '../utils/requests';

export const RETRIEVE_ARTICLE_LIST = 'RETRIEVE_ARTICLE_LIST';
export const UPDATE_ARTICLE_LIST = 'UPDATE_ARTICLE_LIST';

// 更新文章 model
export type UpdateArticleListPayload = Readonly<{
  list?: ArticleItem[];
  isLoading?: boolean;
  page?: number;
  total?: number;
  pageSize?: number;
  hasBeenToTheEnd?: boolean;
}>;

export type UpdateArticleListAction = Readonly<{
  type: 'UPDATE_ARTICLE_LIST';
  payload: UpdateArticleListPayload;
}>;

export const updateArticleList = (
  payload: UpdateArticleListPayload
): UpdateArticleListAction => ({
  type: UPDATE_ARTICLE_LIST,
  payload,
});

// 拉取文章列表
export type RetrieveArticleListAction = Readonly<{
  type: 'RETRIEVE_ARTICLE_LIST';
}>;

type ArticleListResponse = Readonly<
  CommonHTTPResponse<{
    page: {
      list: ArticleItem[];
      lastPage: 1 | 0;
      firstPage: 1 | 0;
      hasNextPage: boolean;
      total: number;
      size: number;
      pageNum: number;
      pageSize: number;
    };
  }>
>;

/**
 * 获取文章列表
 * @param ifGetFromFirstPage 是否从第一页开始获取数据
 */
export const retrieveArticleList = (
  ifGetFromFirstPage: boolean = false
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, articles } = state;
    if (auth.token === null) return;

    dispatch(updateArticleList({ isLoading: true }));

    const newPage = ifGetFromFirstPage ? 1 : articles.page + 1;

    const res: AxiosResponse<ArticleListResponse> = await requests.post(
      `${apiConfig.GET_ARTICLE_LIST}?${queryString.stringify({
        page: newPage,
        pageSize: articles.pageSize,
      })}`,
      {},
      {
        headers: {
          token: auth.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updateArticleList({
          list: ifGetFromFirstPage
            ? resData.page.list
            : [...articles.list, ...resData.page.list],
          page: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );
    } else {
      updateArticleList({
        isLoading: false,
      });
    }
  };
};
