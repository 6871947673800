import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import produce from 'immer';

import { PatientDetails } from '../../PatientDetails';
import { AuthState } from '../../../../reducers/auth';
import { GlobalState } from '../../../../store/configureStore';
import styles from './index.module.scss';
import { loadUserTokenFromLocal } from '../../../../utils/auth';
import requests from '../../../../utils/requests';
import { apiConfig } from '../../../../constants/apiConfig';
import { Button, Intent } from '@blueprintjs/core';
import LabelValueGroup from './components/LabelValueGroup';
import SelectGroup from './components/SelectGroup';

type SmokeExpTabContentOwnProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
  isUpdatePatientLoading: boolean;
}>;

type SmokeExpTabContextStateProps = Readonly<{
  authModel: AuthState;
}>;

const cigaretteTakingAmount = [
  '少于1支',
  '1~5支',
  '6~10支',
  '10~20支',
  '20支以上',
];

const SmokeExpTabContent: React.FC<
  SmokeExpTabContentOwnProps & SmokeExpTabContextStateProps
> = (props) => {
  // 获取吸烟史列表
  const retrieveSmokeHistory = async () => {
    const userTokenRes = loadUserTokenFromLocal();
    if (!userTokenRes.success) {
      return;
    }

    // updateCommonHealthHistoryList({ isLoading: true, data: [] });

    // const res: AxiosResponse<CommonHTTPResponse<{
    //   data: CommonHealthHistoryItem[];
    // }>> =
    await requests.post(
      apiConfig.GET_SMOKE_AMOUNT,
      {},
      { headers: { token: userTokenRes.token } }
    );

    // updateCommonHealthHistoryList({
    //   data: res.data.success ? res.data.data : [],
    //   isLoading: false,
    // });
  };

  useEffect(() => {
    retrieveSmokeHistory();
  }, []);

  const generateCheckbox = (
    checked: boolean,
    labelName: string,
    onClick: () => void
  ) => (
    <button
      className={styles.smokeTabBtn}
      onClick={() => {
        if (checked) {
          updateLocalSmokeStr('');
          return;
        }
        onClick();
      }}
    >
      <p>
        {checked ? (
          <i className="pyr pyrchoise" />
        ) : (
          <i className="pyr pyrcheck" />
        )}
        {labelName}
      </p>
    </button>
  );

  const [localSmokerStr, updateLocalSmokeStr] = useState<string>(
    props.patientDetails.smoker
  );

  const smokeInfoStrings: string[] =
    typeof localSmokerStr === 'string' ? localSmokerStr.split(',') : [];

  // return <div className={styles.diagnosisWrapper}>{props.smoker}</div>;
  return (
    <>
      <div className={`${styles.diagnosisWrapper} ${styles.smokeInfo}`}>
        <div>
          {generateCheckbox(smokeInfoStrings[0] === '吸烟', '吸烟', () => {
            updateLocalSmokeStr('吸烟,,');
          })}
          {smokeInfoStrings[0] === '吸烟' && (
            <>
              <SelectGroup
                optionList={cigaretteTakingAmount}
                label="日均吸烟量"
                value={smokeInfoStrings[1]}
                onChange={(payload) => {
                  updateLocalSmokeStr(
                    produce(smokeInfoStrings, (draft) => {
                      draft[1] = payload ? payload.newValue : '';
                    }).join(',')
                  );
                }}
              />
              <LabelValueGroup
                disableOnBlurClear
                label="烟龄"
                value={smokeInfoStrings[2]}
                onChange={(payload) => {
                  updateLocalSmokeStr(
                    produce(smokeInfoStrings, (draft) => {
                      draft[2] = payload ? payload.newValue : '';
                    }).join(',')
                  );
                }}
              />
            </>
          )}
        </div>
        <div>
          {generateCheckbox(smokeInfoStrings[0] === '已戒烟', '已戒烟', () => {
            updateLocalSmokeStr('已戒烟,,');
          })}
          {smokeInfoStrings[0] === '已戒烟' && (
            <>
              <SelectGroup
                label="日均吸烟量"
                value={smokeInfoStrings[1]}
                optionList={cigaretteTakingAmount}
                onChange={(payload) => {
                  updateLocalSmokeStr(
                    produce(smokeInfoStrings, (draft) => {
                      draft[1] = payload ? payload.newValue : '';
                    }).join(',')
                  );
                }}
              />
              <LabelValueGroup
                disableOnBlurClear
                label="烟龄"
                value={smokeInfoStrings[2]}
                onChange={(payload) => {
                  updateLocalSmokeStr(
                    produce(smokeInfoStrings, (draft) => {
                      draft[2] = payload ? payload.newValue : '';
                    }).join(',')
                  );
                }}
              />
            </>
          )}
        </div>
        <div>
          {generateCheckbox(
            smokeInfoStrings[0] === '从不吸烟',
            '从不吸烟',
            () => {
              updateLocalSmokeStr('从不吸烟,');
            }
          )}
          {smokeInfoStrings[0] === '从不吸烟' && (
            <>
              <SelectGroup
                label="二手环境暴露日均时间"
                value={smokeInfoStrings[2]}
                optionList={[
                  '基本没有',
                  '少于1小时',
                  '1~2小时',
                  '2~4小时',
                  '4小时以上',
                ]}
                onChange={(payload) => {
                  updateLocalSmokeStr(
                    produce(smokeInfoStrings, (draft) => {
                      draft[1] = payload ? payload.newValue : '';
                    }).join(',')
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
      {props.patientDetails.smoker !== localSmokerStr && (
        <div className={styles.updateUserInfoBtnWrapper}>
          <Button
            intent={Intent.PRIMARY}
            text="立即更新"
            onClick={() => {
              props.updatePatientInfo({
                smoker: localSmokerStr,
              });
            }}
            loading={props.isUpdatePatientLoading}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  SmokeExpTabContextStateProps,
  {},
  SmokeExpTabContentOwnProps,
  GlobalState
>(mapStateToProps)(SmokeExpTabContent);
