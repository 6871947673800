/**
 * 二级信息
 */
import React from 'react';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';

import { Symptom } from '../types';
import CheckableProperty from '../../../PatientDetailsSideBar/healthInfoTabs/components/CheckableProperty';
import styles from './index.module.scss';
import {
  formatTimestamp,
  TimestampFormatPrecision,
} from '../../../../../utils/time';

const SymptomDisplay: React.FC<{
  data: Symptom;
}> = (props) => {
  const { data } = props;

  return data.doctorQuestDTOList === null || data.userAnswerContent === null ? (
    <CheckableProperty
      key={data.id}
      title={data.content}
      checked={data.userAnswerContent !== null}
    />
  ) : (
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      minimal
      // isOpen={true}
      popoverClassName={styles.secondLevelPopover}
      position={Position.BOTTOM_LEFT}
    >
      <CheckableProperty
        key={data.id}
        title={data.content}
        checked={data.userAnswerContent !== null}
      />
      <div className={styles.secondLevelWrapper}>
        <CheckableProperty
          key={data.id}
          title={data.content}
          checked={data.userAnswerContent !== null}
          reverseColor
        />
        {generateSecondLevelInfo(data)}
      </div>
    </Popover>
  );
};

const generateSecondLevelInfo = (symptomData: Symptom): JSX.Element => {
  const { doctorQuestDTOList, style } = symptomData;

  if (doctorQuestDTOList === null) {
    return <></>;
  }

  const displayGenerator: (() => JSX.Element) | null = [
    null,

    // displayType == 1
    () => (
      <>
        {doctorQuestDTOList
          // .filter(
          //   (doctorQuestItem) =>
          //     ![null, ''].includes(doctorQuestItem.userAnswerContent)
          // )
          .map((doctorQuestItem) => (
            <div
              key={doctorQuestItem.id}
              className={styles.doctorQuestDTQType_1}
            >
              <span>{doctorQuestItem.content}</span>
              {doctorQuestItem.userAnswerContent !== null && (
                <span>
                  {doctorQuestItem.content === '起症时间'
                    ? formatTimestamp(doctorQuestItem.userAnswerContent, {
                        formatPrecision: TimestampFormatPrecision.MONTH,
                      })
                    : doctorQuestItem.userAnswerContent}
                </span>
              )}
            </div>
          ))}
      </>
    ),

    // displayType == 2
    () => {
      const activeSymptomDescriptionList = doctorQuestDTOList.map(
        (doctorQuestDTOItem) => doctorQuestDTOItem.userAnswerContent
      );

      return (
        <div className={styles.doctorQuestDTQType_2}>
          {['躯干前倾', '驼背', '侧歪'].map((symptomDescription) => (
            <span
              className={
                activeSymptomDescriptionList.includes(symptomDescription)
                  ? styles.doctorQuestDTQType_2__activeSymptom
                  : ''
              }
            >
              {symptomDescription}
            </span>
          ))}
        </div>
      );
    },

    // displayType == 3
    () => {
      return (
        <div className={styles.doctorQuestDTQType_3}>
          {doctorQuestDTOList
            .filter((doctorQuestItem) => doctorQuestItem.content !== null)
            .map((doctorQuestDTO) => (
              <div key={doctorQuestDTO.id}>
                {doctorQuestDTO.content ? (
                  <span>
                    {doctorQuestDTO.content === '频率' && (
                      <i className="pyr pyrfre_icon" />
                    )}
                    {doctorQuestDTO.content === '程度' && (
                      <i className="pyr pyrstr_icon" />
                    )}
                    {doctorQuestDTO.content === '与药物有关' && (
                      <i className="pyr pyrmedi_icon" />
                    )}
                  </span>
                ) : (
                  <></>
                )}
                <span>
                  {doctorQuestDTO.content === '与药物有关'
                    ? doctorQuestDTO.userAnswerContent === '是'
                      ? '可缓解'
                      : '不可缓解'
                    : doctorQuestDTO.userAnswerContent}
                </span>
              </div>
            ))}
        </div>
      );
    },

    // displayType == 4
    () => {
      return (
        <div className={styles.doctorQuestDTQType_5}>
          {doctorQuestDTOList.map((doctorQuestDTO) => (
            <p
              className={
                doctorQuestDTOList[0].userAnswerContent !== null &&
                doctorQuestDTOList[0].userAnswerContent ===
                  doctorQuestDTO.content
                  ? styles.doctorQuestDTQType_5__activeChoice
                  : ''
              }
            >
              {doctorQuestDTO.content}
            </p>
          ))}
        </div>
      );
    },

    // displayType == 5
    () => {
      return (
        <div className={styles.doctorQuestDTQType_5}>
          {doctorQuestDTOList.map((doctorQuestDTO) => (
            <p
              className={
                doctorQuestDTOList[0].userAnswerContent !== null &&
                doctorQuestDTOList[0].userAnswerContent ===
                  doctorQuestDTO.content
                  ? styles.doctorQuestDTQType_5__activeChoice
                  : ''
              }
            >
              {doctorQuestDTO.content}
            </p>
          ))}
        </div>
      );
    },
  ][style];

  return typeof displayGenerator === 'function' ? displayGenerator() : <></>;
};

export default SymptomDisplay;
