import React from 'react';
import { Icon } from '@blueprintjs/core';

import { ReportRow } from '../types';
import styles from './index.module.scss';

const REMReportTable: React.FC<{ reportData: ReportRow[] }> = (props) => {
  const { reportData } = props;

  return (
    <table className={`${styles.reportTable} ${styles.REMTable}`}>
      <tbody>
        {reportData.map((reportRow) => {
          const { relationAnswerList } = reportRow;
          return (
            <tr key={reportRow.id}>
              <td>{`${reportRow.sort}、${reportRow.content}`}</td>
              <td>
                <span>是</span>
                {relationAnswerList[0]?.userAnswer === '1' ? (
                  <Icon icon="small-tick" />
                ) : (
                  <></>
                )}
              </td>
              <td>
                <span>否</span>
                {relationAnswerList[0]?.userAnswer === '2' ? (
                  <Icon icon="small-tick" />
                ) : (
                  <></>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default REMReportTable;
