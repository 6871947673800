import { INPUT } from '@blueprintjs/core/lib/esm/common/classes';

export type EditingLableValueGroup = {
  key: string;
  value: string | number;
};

export enum LabelValueType {
  INPUT,
  DATE_INPUT,
  SELECT,
}

export type LabelValueItem = Readonly<{
  label: string;
  key: string;
  value: string;
  type?: LabelValueType;
  selectList?: string[];
}>;
