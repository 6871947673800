import queryString from 'query-string';

import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../commonTypes';
import { apiConfig } from '../constants/apiConfig';
import { QnaChatDetail, QnaRecord } from '../reducers/qna/types';
import { Dispatch, GlobalState, ThunkFunction } from '../store/configureStore';
import requests from '../utils/requests';
import { randomlyPickFromArray } from '../utils/random';

export const UPDATE_AT_MINE_QNA_LIST = 'UPDATE_AT_MINE_QNA_LIST';
export const UPDATE_AT_GROUP_QNA_LIST = 'UPDATE_AT_GROUP_QNA_LIST';
export const UPDATE_QNA_CHAT_DETAILS = 'UPDATE_QNA_CHAT_DETAILS';
export const RETRIEVE_QNA_CHAT_DETAILS = 'RETRIEVE_QNA_CHAT_DETAILS';
export const UPDATE_SELECTED_CHAT_ID_ACTION = 'UPDATE_SELECTED_CHAT_ID_ACTION';
export const QNA_REPLY_USER = 'QNA_REPLY_USER';
export const UPDATE_QUESTION_LIST = 'UPDATE_QUESTION_LIST';
export const UPDATE_NOT_READ_CHAT_COUNT = 'UPDATE_NOT_READ_CHAT_COUNT';

// 更新 “@我的” 问答列表
export type UpdateAtMineQnaListPayload = Readonly<{
  data?: QnaRecord[];
  isLoading?: boolean;
  pageNumber?: number;
  pageSize?: 20;
  hasBeenToTheEnd?: boolean;
}>;

export type UpdateAtMineQnaListAction = Readonly<{
  type: 'UPDATE_AT_MINE_QNA_LIST';
  payload: UpdateAtMineQnaListPayload;
}>;

/**
 * 更新 “@我的” 问答列表
 * @param payload
 */
export const updateAtMineQnaList = (
  payload: UpdateAtMineQnaListPayload
): UpdateAtMineQnaListAction => ({
  type: UPDATE_AT_MINE_QNA_LIST,
  payload,
});

// 更新 “@我的” 问答列表
export type UpdateAtGroupQnaListPayload = Readonly<{
  data?: QnaRecord[];
  isLoading?: boolean;
  pageNumber?: number;
  pageSize?: 20;
  hasBeenToTheEnd?: boolean;
}>;

export type UpdateAtGroupQnaListAction = Readonly<{
  type: 'UPDATE_AT_GROUP_QNA_LIST';
  payload: UpdateAtMineQnaListPayload;
}>;

/**
 * 更新 “@我的” 问答列表
 * @param payload
 */
export const updateAtGroupQnaList = (
  payload: UpdateAtGroupQnaListPayload
): UpdateAtGroupQnaListAction => ({
  type: UPDATE_AT_GROUP_QNA_LIST,
  payload,
});

// 获取  “@我的” 问答列表
export type RetrieveAtMineQnaListAction = Readonly<{
  type: 'RETRIEVE_AT_MINE_QNA_LIST';
}>;

export type QnaListResponse = Readonly<
  CommonHTTPResponse<{
    page: {
      pageNum: number;
      pageSize: number;
      size: number;
      startRow: number;
      endRow: number;
      total: number;
      pages: number;
      list: QnaRecord[];
      lastPage: number;
      firstPage: number;
    };
  }>
>;

// 获取 "@团队的" 问答列表
export type RetrieveAtGroupQnaListAction = Readonly<{
  type: 'RETRIEVE_AT_GROUP_QNA_LIST';
}>;

// 更新与选择患者的聊天详情
export type UpdateQnaChatDetailsAction = Readonly<{
  type: 'UPDATE_QNA_CHAT_DETAILS';
  payload: UpdateQnaChatDetailsPayload;
}>;

export type UpdateQnaChatDetailsPayload = Readonly<{
  data?: QnaChatDetail[];
  isLoading?: boolean;
  // pageNumber?: number;
  // pageSize?: 20;
  // hasBeenToTheEnd?: boolean;
}>;

// 更新选择问答
export type UpdateSelectedChatIdAction = Readonly<{
  type: 'UPDATE_SELECTED_CHAT_ID_ACTION';
  payload: {
    record: (QnaRecord & { mainChatId?: number }) | null;
  };
}>;

export const updateSelectedChatRecord = (payload: {
  record: (QnaRecord & { mainChatId?: number }) | null;
}): UpdateSelectedChatIdAction => ({
  type: UPDATE_SELECTED_CHAT_ID_ACTION,
  payload,
});

export const updateQnaChatDetails = (
  payload: UpdateQnaChatDetailsPayload
): UpdateQnaChatDetailsAction => ({
  type: UPDATE_QNA_CHAT_DETAILS,
  payload,
});

// 获取与选择患者的聊天详情
export type RetrieveQnaChatPayload = {
  patientId: number;
  doctorId: number;
  chatId: number;
};

export type RetrieveQnaChatDetails = Readonly<{
  type: 'RETRIEVE_QNA_CHAT_DETAILS';
}>;

/**
 * 获取 “@我的” 用户列表
 * @param ifGetFromFirstPage
 * @param callbackAfterListRetrieved 获取到列表后的回调
 * @param doRandomlySelectUnreadChatAfterListRetrieved 获取列表后随机选择
 */
export const retrieveAtMineQnaList = (
  ifGetFromFirstPage: boolean = false,
  callbackAfterListRetrieved?: (qnaListResponse: QnaListResponse) => void,
  doRandomlySelectUnreadChatAfterListRetrieved: boolean = false
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    dispatch(
      updateAtMineQnaList({
        isLoading: true,
        data: ifGetFromFirstPage ? [] : state.qna.atMineList.data,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : state.qna.atMineList.pageNumber;

    const res: AxiosResponse<QnaListResponse> = await requests.post(
      `${apiConfig.GET_QNA_LIST}?${queryString.stringify({
        page: newPage,
        pageSize: state.qna.atMineList.pageSize,
        myOrTeam: 0,
      })}`,
      {},
      {
        headers: {
          token: state.auth.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      const newQnaListData = ifGetFromFirstPage
        ? resData.page.list
        : [...state.qna.atMineList.data, ...resData.page.list];

      dispatch(
        updateAtMineQnaList({
          data: newQnaListData,
          pageNumber: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );

      if (typeof callbackAfterListRetrieved === 'function') {
        callbackAfterListRetrieved(resData);
        return;
      }

      if (
        ifGetFromFirstPage &&
        resData.page.list.length > 0 &&
        (!!doRandomlySelectUnreadChatAfterListRetrieved ||
          state.qna.selectedChatRecord === null)
      ) {
        // 选择随机元素或第一个元素
        const recordToSelect = !!doRandomlySelectUnreadChatAfterListRetrieved
          ? randomlyPickFromArray(
              resData.page.list.filter((item) => item.viewFlag === 0)
            )
          : resData.page.list[0];

        if (!recordToSelect) {
          dispatch(updateQuestionList({ data: [] }));

          return;
        }

        // 获取第一条问答记录详情
        dispatch(
          retrieveQuestionList({
            patientId: recordToSelect.patientId,
            mineOrTeam: 0,
          })
        );

        // 选择第一条问答记录
        dispatch(
          updateSelectedChatRecord({
            record: { ...recordToSelect, mainChatId: recordToSelect.id },
          })
        );

        // 更改第一条问答记录为已读
        setTimeout(() => {
          dispatch(
            updateAtMineQnaList({
              data: newQnaListData.map((item) =>
                item.id === recordToSelect.id
                  ? {
                      ...item,
                      viewFlag: 1,
                    }
                  : item
              ),
            })
          );
        }, 500);
      }
    } else {
      dispatch(
        updateAtMineQnaList({
          isLoading: false,
        })
      );

      if (typeof callbackAfterListRetrieved === 'function') {
        callbackAfterListRetrieved(resData);
        return;
      }
    }
  };
};

/**
 * 获取 “@团队的” 用户列表
 * @param ifGetFromFirstPage
 * @param doRandomlySelectUnreadChatAfterListRetrieved 获取列表后随机选择
 *
 */
export const retrieveAtGroupQnaList = (
  ifGetFromFirstPage: boolean = false,
  doRandomlySelectUnreadChatAfterListRetrieved: boolean = false
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    dispatch(
      updateAtGroupQnaList({
        isLoading: true,
        data: ifGetFromFirstPage ? [] : state.qna.atGroupList.data,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : state.qna.atGroupList.pageNumber;

    const res: AxiosResponse<QnaListResponse> = await requests.post(
      `${apiConfig.GET_QNA_LIST}?${queryString.stringify({
        page: newPage,
        pageSize: state.qna.atGroupList.pageSize,
        myOrTeam: 1,
      })}`,
      {},
      {
        headers: {
          token: state.auth.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      const newQnaList = ifGetFromFirstPage
        ? resData.page.list
        : [...state.qna.atGroupList.data, ...resData.page.list];

      dispatch(
        updateAtGroupQnaList({
          data: newQnaList,
          pageNumber: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );

      if (ifGetFromFirstPage && resData.page.list.length > 0) {
        // 选择随机元素或第一个元素
        const recordToSelect = !!doRandomlySelectUnreadChatAfterListRetrieved
          ? randomlyPickFromArray(
              resData.page.list.filter((item) => item.viewFlag === 0)
            )
          : resData.page.list[0];

        if (!recordToSelect) {
          dispatch(updateQuestionList({ data: [] }));
          return;
        }

        // 获取第一条问答记录详情
        dispatch(
          retrieveQuestionList({
            patientId: recordToSelect.patientId,
            mineOrTeam: 1,
          })
        );

        // 选择第一条问答记录
        dispatch(
          updateSelectedChatRecord({
            record: { ...recordToSelect, mainChatId: recordToSelect.id },
          })
        );

        // 更改第一条问答记录为已读
        setTimeout(() => {
          dispatch(
            updateAtGroupQnaList({
              data: newQnaList.map((item) =>
                item.id === recordToSelect.id
                  ? {
                      ...item,
                      viewFlag: 1,
                    }
                  : item
              ),
            })
          );
        }, 500);
      }
    } else {
      dispatch(
        updateAtGroupQnaList({
          isLoading: false,
        })
      );
    }
  };
};

type RetrieveQnaChatDetailsResponse = Readonly<
  CommonHTTPResponse<{
    data: QnaChatDetail[];
  }>
>;

export const retrieveQnaChatDetails = (
  payload: RetrieveQnaChatPayload
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    dispatch(
      updateQnaChatDetails({
        isLoading: true,
        data: [],
      })
    );

    const res: AxiosResponse<RetrieveQnaChatDetailsResponse> = await requests.post(
      `${apiConfig.GET_QNA_CHAT_DETAILS}?${queryString.stringify({
        patientId: payload.patientId,
        doctorId: payload.doctorId,
        chatId: payload.chatId,
        id: payload.chatId,
      })}`,
      {},
      {
        headers: {
          token: state.auth.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(refreshNotReadChatCount());

      dispatch(
        updateQnaChatDetails({
          data: resData.data,
          isLoading: false,
        })
      );
    } else {
      dispatch(
        updateQnaChatDetails({
          isLoading: false,
        })
      );
    }
  };
};

// 回复患者
export type ReplyUserAction = Readonly<{
  type: 'QNA_REPLY_USER';
  payload: ReplyUserPayload;
}>;

export type ReplyUserPayload = Readonly<{
  chatId: number;
  content: string;
  articleId?: number;
  repliedCallback?: (success: boolean, msg: string) => void;
  // pageNumber?: number;
  // pageSize?: 20;
  // hasBeenToTheEnd?: boolean;
}>;

export const replyUser = (payload: ReplyUserPayload): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const res: AxiosResponse<CommonHTTPResponse<any>> = await requests.post(
      apiConfig.DOCTOR_REPLY,
      {
        chatId: payload.chatId,
        content: payload.content,
        userType: 1,
        articleId: payload.articleId,
      },
      {
        headers: {
          token: state.auth.token,
        },
      }
    );

    if (typeof payload.repliedCallback === 'function') {
      payload.repliedCallback(res.data.success, res.data.message);
    }
  };
};

// 更新问题列表
export const updateQuestionList = (
  payload: UpdateQuestionListPayload
): UpdateQuestionListAction => ({
  type: UPDATE_QUESTION_LIST,
  payload,
});

export type UpdateQuestionListPayload = Readonly<{
  data?: QnaRecord[];
  isLoading?: boolean;
  patientId?: number;
}>;

export type UpdateQuestionListAction = Readonly<{
  type: 'UPDATE_QUESTION_LIST';
  payload: UpdateQuestionListPayload;
}>;

// 获取会话下的问题列表
export type retrieveQuestionList = Readonly<{
  type: 'RETRIEVE_QUESTION_LIST';
  payload: RetrieveQuestionListPayload;
}>;

export type RetrieveQuestionListPayload = Readonly<{
  patientId: number;
  mineOrTeam: 0 | 1;
}>;

type QuestionListResponse = CommonHTTPResponse<{
  object: QnaRecord[];
}>;

export const retrieveQuestionList = (
  payload: RetrieveQuestionListPayload
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    dispatch(updateQuestionList({ isLoading: true }));

    const res: AxiosResponse<QuestionListResponse> = await requests.post(
      `${apiConfig.GET_PATIENT_QUESTION_LIST}?${queryString.stringify({
        patientId: payload.patientId,
        myOrTeam: payload.mineOrTeam,
      })}`,
      {},
      {
        headers: {
          token: state.auth.token,
        },
      }
    );

    if (res.data.success) {
      dispatch(
        updateQuestionList({
          isLoading: false,
          data: res.data.object,
          patientId: payload.patientId,
        })
      );
    } else {
      updateQuestionList({
        isLoading: false,
      });
    }
  };
};

/**
 * 获取未读会话数量
 */
export type retrieveNotReadChatCountAction = Readonly<{
  type: 'RETRIEVE_NOT_READ_CHAT_COUNT';
}>;

export const refreshNotReadChatCount = (): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const res: AxiosResponse<
      CommonHTTPResponse<{
        data: number;
      }>
    > = await requests.post(
      apiConfig.GET_NOT_READ_BY_DOCTOR_ID,
      {},
      {
        headers: {
          token: state.auth.token,
        },
      }
    );

    if (res.data.success) {
      dispatch(updateNotReadChatCount({ count: res.data.data }));
    }
  };
};

/**
 * 更新未读会话数量
 */
export type UpdateNotReadChatCountAction = Readonly<{
  type: 'UPDATE_NOT_READ_CHAT_COUNT';
  payload: {
    count: number;
  };
}>;

export const updateNotReadChatCount = (payload: { count: number }) => ({
  type: UPDATE_NOT_READ_CHAT_COUNT,
  payload,
});
