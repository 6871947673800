import React, { useState } from 'react';
import { connect } from 'react-redux';

import { PatientDetails } from '../../PatientDetails';
import { AuthState } from '../../../../reducers/auth';
import { GlobalState } from '../../../../store/configureStore';
import { Button, Intent } from '@blueprintjs/core';
import CheckableProperty from './components/CheckableProperty';
import styles from './index.module.scss';

type FamilyExpTabContentOwnProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
  isUpdatePatientLoading: boolean;
}>;

type FamilyExpTabContextStateProps = Readonly<{
  authModel: AuthState;
}>;

const FamilyExpTabContent: React.FC<
  FamilyExpTabContentOwnProps & FamilyExpTabContextStateProps
> = (props) => {
  const splittedCloseRelatives: string[] =
    typeof props.patientDetails.closeRelatives === 'string'
      ? props.patientDetails.closeRelatives.split(',')
      : [];

  const [parentCloseRelativeMarried, setParentCloseRelativeMarried] = useState<
    boolean
  >(splittedCloseRelatives[0] === 'true');

  const [familyCloseRelativeMarried, setFamilyCloseRelativeMarried] = useState<
    boolean
  >(splittedCloseRelatives[1] === 'true');

  return (
    <div className={`${styles.diagnosisWrapper} ${styles.familyExp}`}>
      {/* <p>
        {generateCheckbox(parentCloseRelativeMarried)} <span>父母近亲结婚</span>
      </p> */}
      <CheckableProperty
        title="父母近亲结婚"
        checked={parentCloseRelativeMarried}
        onChange={() => {
          setParentCloseRelativeMarried(!parentCloseRelativeMarried);
        }}
      />
      <CheckableProperty
        title="家族中有近亲结婚"
        checked={familyCloseRelativeMarried}
        onChange={() => {
          setFamilyCloseRelativeMarried(!familyCloseRelativeMarried);
        }}
      />

      {!(
        `${parentCloseRelativeMarried},${familyCloseRelativeMarried}` ===
        props.patientDetails.closeRelatives
          .split(',')
          .map((item) => item.trim())
          .join(',')
      ) && (
        <div className={styles.updateUserInfoBtnWrapper}>
          <Button
            intent={Intent.PRIMARY}
            disabled={
              `${parentCloseRelativeMarried},${familyCloseRelativeMarried}` ===
              props.patientDetails.closeRelatives
            }
            text="立即更新"
            onClick={() => {
              props.updatePatientInfo({
                closeRelatives: `${parentCloseRelativeMarried},${familyCloseRelativeMarried}`,
              });
            }}
            loading={props.isUpdatePatientLoading}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  FamilyExpTabContextStateProps,
  {},
  FamilyExpTabContentOwnProps,
  GlobalState
>(mapStateToProps)(FamilyExpTabContent);
