// 患者列表抽屉左侧的分类功能部分
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@blueprintjs/core';
import classNames from 'classnames';

import styles from './index.module.scss';
import { UserInfoState } from '../../../reducers/userInfo';
import { AuthState } from '../../../reducers/auth';
import PatientGroupListView from './PatientGroupListView';
import PatientGroupAddButton from './PatientGroupAddButton';
import LabelListView from './LabelListView';

type FilterListOwnProps = Readonly<{
  userInfoModel: UserInfoState;
  authModel: AuthState;
  retrievePatientGroupList: () => void;
  retrieveLabelList: () => void;
  retrieveFollowingPatientList: (ifGetFromFirstPage?: boolean) => void;
  retrievePatientListByDoctor: (ifGetFromFirstPage?: boolean) => void;
  retrievePatientListByLabelGroupId: (
    ifGetFromFirstPage: boolean,
    groupId: number
  ) => void;
  retrievePatientListByLabelId: (
    ifGetFromFirstPage: boolean,
    labelId: number
  ) => void;
  retrievePatientListByGroupId: (
    ifGetFromFirstPage: boolean,
    groupId: number
  ) => void;
  retrieveDoctorGroupList: () => void;
  filterType: FilterType;
  updateFilterType: (filterType: FilterType) => void;
  updateEditingPatientGroupId: (newValue: number | null) => void;
}>;

export enum FilterType {
  recents,
  following,
  byDoctorGroup,
  myPatients,
  byPatientsGroup,
  byPatientsGroupEditing,
  byDoctorLabel,
}

const FilterList: React.FC<FilterListOwnProps> = (props) => {
  const {
    userInfoModel: { patientGroupList, patientLabelList },
    filterType,
    updateFilterType,
  } = props;

  // 分组列表编辑状态
  const [
    isEditingPatientGroupList,
    setPatientGroupListEditing,
  ] = useState<boolean>(false);

  // 标签列表编辑状态
  const [isEditingLabelList, setLabelListEditing] = useState<boolean>(false);

  const previousFilterTypeRef = useRef<FilterType>();

  // 用户退出分组列表编辑时，自动退出分组查看的编辑状态
  useEffect(() => {
    if (
      filterType !== FilterType.byPatientsGroupEditing &&
      previousFilterTypeRef.current === FilterType.byPatientsGroupEditing
    ) {
      setPatientGroupListEditing(false);
    }
    previousFilterTypeRef.current = filterType;
  }, [filterType]);

  return (
    <section className={styles.filterListWrapper}>
      <FilterTriggerBtn
        title="最近查看"
        type={FilterType.recents}
        selectedType={filterType}
        onClick={() => {
          updateFilterType(FilterType.recents);
        }}
      />

      <FilterTriggerBtn
        title="我关注的"
        type={FilterType.following}
        selectedType={filterType}
        onClick={() => {
          updateFilterType(FilterType.following);
          props.retrieveFollowingPatientList(true);
        }}
      />

      <FilterTriggerBtn
        title="团队病患"
        type={FilterType.byDoctorGroup}
        selectedType={filterType}
        onClick={() => {
          updateFilterType(FilterType.byDoctorGroup);
        }}
      />

      <FilterTriggerBtn
        title="我的病患"
        type={FilterType.myPatients}
        selectedType={filterType}
        onClick={() => {
          updateFilterType(FilterType.myPatients);
          props.retrievePatientListByDoctor(true);
        }}
      />

      <div className={styles.filterType}>
        <span>分组查看</span>
        <button
          className={classNames({
            [styles.filterType__optBtn]: true,
            [styles['filterType__optBtn--isActive']]: isEditingPatientGroupList,
          })}
          onClick={() => {
            if (
              !isEditingLabelList &&
              filterType === FilterType.byPatientsGroup
            ) {
              updateFilterType(FilterType.byPatientsGroupEditing);
            }
            // else if (
            //   isEditingLabelList &&
            //   filterType === FilterType.byPatientsGroupEditing
            // ) {
            //   updateFilterType(FilterType.byPatientsGroup);
            // }

            setPatientGroupListEditing(!isEditingPatientGroupList);
          }}
        >
          <i className="pyr pyrsetup" />
        </button>
      </div>

      {/* 患者分组列表 */}
      <div className={styles.secondLevelFilterList}>
        <PatientGroupListView
          patientGroupList={patientGroupList}
          onGroupItemSelect={(selectedGroupId: number) => {
            props.updateEditingPatientGroupId(selectedGroupId);

            if (isEditingPatientGroupList) {
              updateFilterType(FilterType.byPatientsGroupEditing);
            } else {
              updateFilterType(FilterType.byPatientsGroup);
              props.retrievePatientListByLabelGroupId(true, selectedGroupId);
            }
          }}
          isBeingEdited={isEditingPatientGroupList}
        />
        <PatientGroupAddButton
          authModel={props.authModel}
          retrievePatientGroupList={props.retrievePatientGroupList}
        />
      </div>

      <div className={styles.filterType}>
        <span>标签查看</span>

        <button
          className={classNames({
            [styles.filterType__optBtn]: true,
            [styles['filterType__optBtn--isActive']]: isEditingLabelList,
          })}
          onClick={() => {
            setLabelListEditing(!isEditingLabelList);
          }}
        >
          <i className="pyr pyrsetup" />
        </button>
      </div>

      {/* 患者标签列表 */}
      <div className={styles.secondLevelFilterList}>
        <LabelListView
          labelList={patientLabelList}
          onLabelItemSelect={(selectedLabelId: number) => {
            updateFilterType(FilterType.byDoctorLabel);
            props.retrievePatientListByLabelId(true, selectedLabelId);
          }}
          isBeingEdited={isEditingLabelList}
        />
      </div>
    </section>
  );
};

type FilterTriggerBtnProps = Readonly<{
  title: string;
  type: FilterType;
  selectedType: FilterType;
  onClick?: () => void;
}>;

const FilterTriggerBtn = (props: FilterTriggerBtnProps) => {
  const { onClick, title, type, selectedType } = props;

  const isActive = type === selectedType;
  console.log(type);
  console.log(selectedType);

  return (
    <Button
      className={classNames({
        [styles.filterType]: true,
        [styles['filterType--selected']]: isActive,
      })}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
      minimal
    >
      {title}
    </Button>
  );
};

export default FilterList;
