import React, { useState } from 'react';

import { PatientDetails } from '../../PatientDetails';
import LabelValueGroup from './components/LabelValueGroup';
import styles from './index.module.scss';
import { formatTimestamp } from '../../../../utils/time';
import { Button, Intent } from '@blueprintjs/core';
import { EditingLableValueGroup, LabelValueItem, LabelValueType } from './types';
import DateInputValueGroup from './components/DateInputValueGroup';
import SelectGroup from './components/SelectGroup';

type DiagnosisTabProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
}>;

const DiagnosisTabContent: React.FC<DiagnosisTabProps> = (props) => {
  const { patientDetails } = props;

  const labelValueList = [
    {
      label: '诊断结论',
      key: 'confirmedDisease',
      value:
        typeof patientDetails.confirmedDisease === 'string' &&
        patientDetails.confirmedDisease.trim().length > 0
          ? patientDetails.confirmedDisease
          : '无',
      type: LabelValueType.SELECT,
      selectList: [
        '尚未确诊',
        '帕金森',
        '帕金森病',
        '老年人帕金森病',
        '帕金森病性痴呆',
        '帕金森症',
        '帕金森氏病',
        '特发性帕金森病',
        '帕金森叠加综合症-路易体痴呆',
        '帕金森叠加综合症-进行性和核上性麻痹',
        '帕金森叠加综合症-多系统萎缩',
        '帕金森综合症-皮质性基底节变性',
        '脑炎后帕金森综合症',
      ],
    },
    {
      label: '诊断日期',
      key: 'diagnosisTime',
      value: patientDetails.diagnosisTime,
      type: LabelValueType.DATE_INPUT,
    },
    {
      label: '诊断医生',
      key: 'diagnosisDoctor',
      value: patientDetails.diagnosisDoctor,
    },

    {
      label: '起症部位',
      key: 'firstSymptomSite',
      value: patientDetails.firstSymptomSite,
      type: LabelValueType.SELECT,
      selectList: [
        '左上肢',
        '左下肢',
        '左侧上下肢',
        '右上肢',
        '右下肢',
        '右侧上下肢',
        '双侧肢体',
        '头面部',
      ],
    },
    {
      label: '诊断医生专科',
      key: 'doctorSpecialty',
      value: patientDetails.doctorSpecialty,
      type: LabelValueType.SELECT,
      selectList: ['内科医师', '神经内科医师', '运动障碍疾病专家'],
    },
    {
      label: '诊断医院',
      key: 'diagnosisHospital',
      value: patientDetails.diagnosisHospital,
    },
  ];

  const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false);

  const [
    editingValue,
    updateEditingValue,
  ] = useState<EditingLableValueGroup | null>(null);

  const labelItemViewMap: {
    [index: string]: (labelValueItem: LabelValueItem) => JSX.Element;
  } = {
    [LabelValueType.DATE_INPUT]: (labelValueItem: LabelValueItem) => (
      <DateInputValueGroup
        key={labelValueItem.label}
        labelKey={labelValueItem.key}
        label={labelValueItem.label}
        // @ts-ignore
        value={labelValueItem.value}
        onChange={(payload) => {
          if (payload === null) {
            updateEditingValue(null);
            return;
          }

          if (payload.labelKey === undefined) {
            return;
          }

          const { newValue, labelKey } = payload;

          updateEditingValue({
            key: labelKey,
            value: newValue,
          });
        }}
      />
    ),

    [LabelValueType.INPUT]: (labelValueItem: LabelValueItem) => (
      <LabelValueGroup
        key={labelValueItem.label}
        labelKey={labelValueItem.key}
        label={labelValueItem.label}
        value={labelValueItem.value}
        onChange={(payload) => {
          if (payload === null) {
            updateEditingValue(null);
            return;
          }

          if (payload.labelKey === undefined) {
            return;
          }

          const { newValue, labelKey } = payload;

          updateEditingValue({
            key: labelKey,
            value: newValue,
          });
        }}
      />
    ),

    [LabelValueType.SELECT]: (labelValueItem: LabelValueItem) => (
      <SelectGroup
        key={labelValueItem.label}
        labelKey={labelValueItem.key}
        label={labelValueItem.label}
        value={labelValueItem.value}
        onChange={(payload) => {
          if (payload === null) {
            updateEditingValue(null);
            return;
          }

          if (payload.labelKey === undefined) {
            return;
          }

          const { newValue, labelKey } = payload;

          updateEditingValue({
            key: labelKey,
            value: newValue,
          });
        }}
        optionList={labelValueItem.selectList ?? []}
      />
    ),
  };

  return (
    <section>
      <div className={styles.diagnosisWrapper}>
        {labelValueList.map((labelValueItem) =>
          labelItemViewMap[
            labelValueItem.type === undefined
              ? LabelValueType.INPUT
              : labelValueItem.type
          ](labelValueItem)
        )}
      </div>
      <div className={styles.updateUserInfoBtnWrapper}>
        {editingValue !== null && (
          <Button
            intent={Intent.PRIMARY}
            text="立即更新"
            onClick={() => {
              if (editingValue === null || editingValue.key === undefined) {
                return;
              }

              const uploadPayload: { [index: string]: string | number } = {};
              uploadPayload[editingValue.key] = editingValue.value;

              props.updatePatientInfo(uploadPayload);
              updateEditingValue(null);
            }}
            loading={isUpdateLoading}
          />
        )}
      </div>
    </section>
  );
};


export default DiagnosisTabContent;
