import React, { useEffect, useState } from 'react';

import QnaRightHeader from '../../assets/images/tempSvg/qnaRightHeader.svg';
import QnaRightBody from '../../assets/images/tempSvg/qnaRightBody.svg';
import QnaRightBtm from '../../assets/images/tempSvg/qnaRightBtm.svg';
import QnaLeftTop from '../../assets/images/tempSvg/qnaLeftTop.svg';
import QnaLeftBody from '../../assets/images/tempSvg/qnaLeftBody.svg';
import PyrSpinner from '../common/PyrSpinner';
import styles from './index.module.scss';

const QNAPage: React.FC<{}> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500 + 500 * Math.random());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.left__top}>
          <img src={QnaLeftTop} alt="" />
        </div>
        <div className={styles.left__body}>
          {isLoading ? <PyrSpinner /> : <img src={QnaLeftBody} alt="" />}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.right__header}>
          <img src={QnaRightHeader} alt="" />
        </div>

        <div className={styles.right__body}>
          {isLoading ? <PyrSpinner /> : <img src={QnaRightBody} alt="" />}
        </div>

        <div className={styles.right__footer}>
          <img src={QnaRightBtm} alt="" />
        </div>
      </div>
    </div>
  );
};

export default QNAPage;
