/**
 * 私有路由
 *
 * 用户在登录状态下才可以访问的路由
 *
 * 若在未登录状态下访问，则会被重定向到登录页面
 */

import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { connect } from 'react-redux';

import { AuthState } from '../reducers/auth';
import { GlobalState, Dispatch } from '../store/configureStore';
import routes from '../constants/routes.json';
import { loadUserTokenFromLocal } from '../utils/auth';
import { setAuthenticated } from '../actions/auth';

type PrivateRouteStateProps = Readonly<{
  // redux store 中的登录状态
  authState: AuthState;
}>;

type PrivateRouteDispatchProps = Readonly<{
  setAuthenticated: (token: string) => void;
}>;

const PrivateRoute: React.FC<PrivateRouteStateProps> = (props) => {
  // @ts-ignore
  const { children, authState, setAuthenticated, ...rest } = props;

  let isAuthenticated: boolean;

  /**
   * 若内存（store）中的用户状态为已登录
   * 则直接认为用户已经登录
   *
   * 否则查看 localStorage 的用户 token
   */
  if (authState.isAuthenticated) {
    isAuthenticated = true;
  } else {
    /**
     * 如果内存（store）中的用户状态为未登录
     * 则先获取 localStorage 中的 token
     *
     * 如果获取失败，说明用户没有持久存储登录状态，即用户未登录
     */
    const localTokenResult = loadUserTokenFromLocal();

    if (localTokenResult.success === true) {
      isAuthenticated = true;
      setAuthenticated(localTokenResult.token);
    } else {
      isAuthenticated = false;
    }
  }

  return isAuthenticated ? (
    // 用户已登录，渲染路由
    <Route {...rest} render={() => children} />
  ) : (
    // 用户未登录，重定向到登录页面
    <Redirect to={{ pathname: routes.SIGN_IN }} />
  );
};

const mapStateToProps = (state: GlobalState) => ({ authState: state.auth });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAuthenticated: (token: string) => {
    dispatch(setAuthenticated({ token }));
  },
});

// 接入 redux store 以获取登录状态
export default connect<
  PrivateRouteStateProps,
  PrivateRouteDispatchProps,
  RouteProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
