const USER_TOKEN_LOCAL_STORAGE_KEY = 'pyr_doctor_user_token';

type LoadUserTokenResult = Readonly<
  | {
      success: true;
      token: string;
    }
  | {
      success: false;
      token: null;
    }
>;

// 从 localStorage 中获取用户 token
export const loadUserTokenFromLocal = (): LoadUserTokenResult => {
  const res = localStorage.getItem(USER_TOKEN_LOCAL_STORAGE_KEY);

  return typeof res === 'string'
    ? { success: true, token: res }
    : { success: false, token: null };
};

// 将用户 token 存入 localStorage 中
export const updateUserTokenToLocal = (token: string) => {
  localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, token);
};

// 删除用户 token
export const removeUserTokenFromLocal = () => {
  localStorage.removeItem(USER_TOKEN_LOCAL_STORAGE_KEY);
};
