/**
 * 就诊记录
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { v4 as uuidV4 } from 'uuid';

import { AuthState } from '../../../../reducers/auth';
import { GlobalState } from '../../../../store/configureStore';
import { apiConfig } from '../../../../constants/apiConfig';
import { withRouter, RouteComponentProps } from 'react-router';
import { CommonHTTPResponse } from '../../../../commonTypes';
import CheckableProperty from '../../PatientDetailsSideBar/healthInfoTabs/components/CheckableProperty';
import requests from '../../../../utils/requests';
import PyrSpinner from '../../../common/PyrSpinner';
import { TreatLogSummary } from '../types';
import styles from './index.module.scss';
import RelationList from './RelationList';
import SymptomDisplay from './SymptomDisplay';
import { warningToast } from '../../../../utils/toaster';
import {
  defaultMedicineListState,
  MedicineListState,
  MedicineItemInResponse,
  ActionVideoListState,
  defaultActionVideoListState,
  ActionVideoItem,
  imgTypesNames,
} from './types';
import { Dialog } from '@blueprintjs/core';
import { formatTimestamp, formatYMDTimeStamp } from '../../../../utils/time';

// 症状
type Symptom = Readonly<{
  content: string;
  displayLevel: number;
  displayType: number;
  doctorQuestDTOList: any[];
  id: number;
  sort: number;
  userAnswerContent: string | null;
}>;

// 症状列表
type SymptomCollection = Symptom[];

type SymptomCollectionList = Readonly<
  {
    data: SymptomCollection;
    label: string;
  }[]
>;

const SymptomCollectionLabelKeyValueMap = {
  1: '核心症状',
  2: '运动症状',
  3: '生活能力',
  4: '精力',
  5: '情绪',
  6: '幻觉',
  7: '其他',
};

// 症状列表状态
type SymptomCollectionState = {
  isLoading: boolean;
  data: SymptomCollectionList;
};

// 默认症状列表状态
const defaultSymptomCollectionState: SymptomCollectionState = {
  isLoading: false,
  data: [],
};

type SymptomResponse = CommonHTTPResponse<{
  object: {
    1: SymptomCollection[];
    2: SymptomCollection[];
    3: SymptomCollection[];
    4: SymptomCollection[];
    5: SymptomCollection[];
    6: SymptomCollection[];
    7: SymptomCollection[];
  };
}>;

/**
 * props 类型定义
 */
type MedicalRecordStateProps = Readonly<{ authModel: AuthState }>;

type MedicalRecordOwnProps = Readonly<{
  treatLogId: number;
  isExpanded: boolean;
  treatLogSummary: TreatLogSummary;
}>;

const MedicalRecord: React.FC<
  MedicalRecordStateProps &
    MedicalRecordOwnProps &
    RouteComponentProps<{ id?: string }>
> = (props) => {
  const { authModel, treatLogId, match, isExpanded, treatLogSummary } = props;

  const [
    symptomCollectionState,
    updateSymptomCollectionState,
  ] = useState<SymptomCollectionState>(defaultSymptomCollectionState);

  // 动作视频
  const [
    actionVideoListState,
    updateActionVideoListState,
  ] = useState<ActionVideoListState>(defaultActionVideoListState);

  const [
    selectedActionVideo,
    updateSelectedActionVideo,
  ] = useState<ActionVideoItem | null>(null);

  // 用药列表
  const [
    medicineListState,
    updateMedicineListState,
  ] = useState<MedicineListState>(defaultMedicineListState);

  const retrieveRecordDetails = async () => {
    updateSymptomCollectionState({ isLoading: true, data: [] });

    const res: AxiosResponse<SymptomResponse> = await requests.get(
      `${apiConfig.GET_MEDICAL_RECORD}?${queryString.stringify({
        treatLogId,
        patientId: match.params.id,
      })}`,
      { headers: { token: authModel.token } }
    );
    // @ts-ignore
    updateSymptomCollectionState({
      isLoading: false,
      // @ts-ignore
      data: res.data.success
        ? Object.entries(res.data.object).map(([key, value]) => {
            return {
              data: value,
              // @ts-ignore
              label: SymptomCollectionLabelKeyValueMap[key],
            };
          })
        : [],
    });
  };

  /**
   * 获取动作视频列表
   */
  const retrieveActionVideoList = async () => {
    updateActionVideoListState({ isLoading: true, data: [] });

    const res: AxiosResponse<
      CommonHTTPResponse<{
        data: ActionVideoItem[];
      }>
    > = await requests.post(
      `${apiConfig.TREAT_LOG_ACTION_VIDEO_LIST}?${queryString.stringify({
        treatLogId,
      })}`,
      {},
      { headers: { token: authModel.token } }
    );

    if (res.data.success) {
      updateActionVideoListState({ isLoading: false, data: res.data.data });
    } else {
      warningToast(res.data.message);
      updateActionVideoListState({ isLoading: false, data: [] });
    }
  };

  /**
   * 获取用药列表
   */
  const retrieveMedicineList = async () => {
    updateMedicineListState({ isLoading: true, data: [] });

    const res: AxiosResponse<
      CommonHTTPResponse<{
        page: { list: MedicineItemInResponse[] };
      }>
    > = await requests.post(
      `${apiConfig.MEDICINE_PATIENT_LIST}?${queryString.stringify({
        treatLogId,
        page: 1,
        pageSize: 10,
      })}`,
      {},
      {
        headers: { token: authModel.token },
      }
    );

    if (res.data.success) {
      updateMedicineListState({
        isLoading: false,
        data: res.data.page.list.map((medicineItemInResponse) => {
          try {
            const detailGroupList = medicineItemInResponse.detail.split(',');

            return {
              ...medicineItemInResponse,
              detail: detailGroupList.map((detailGroupItem) => {
                const [time, amountDescription] = detailGroupItem.split('|');
                console.log('time');
                console.log(time);
                console.log('amountDescription');
                console.log(amountDescription);

                return { time, amountDescription };
              }),
            };
          } catch (error) {
            return {
              ...medicineItemInResponse,
              detail: [],
            };
          }
        }),
      });
    } else {
      warningToast(res.data.message);
      updateMedicineListState({ isLoading: false, data: [] });
    }
  };

  const retrieveData = () => {
    retrieveRecordDetails();
    retrieveMedicineList();
    retrieveActionVideoList();
    // retrieveTreatLogDetail();
  };

  useEffect(() => {
    if (isExpanded) {
      retrieveData();
    }
  }, []);

  useEffect(() => {
    if (isExpanded) {
      retrieveData();
    } else {
      updateSymptomCollectionState({ isLoading: false, data: [] });
    }
  }, [isExpanded]);

  return (
    <div
      className={classNames({
        [styles.recordDetails__wrapper]: true,
        [styles['recordDetails__wrapper-expanded']]: isExpanded,
      })}
      style={{ height: isExpanded ? undefined : 0 }}
    >
      <div className={styles.recordDetails__updateTime}>
        <span>更新</span>
        <span>{formatYMDTimeStamp(treatLogSummary.updateTime)}</span>
      </div>

      {/* 头部信息 */}
      <div className={styles.medicalRecordInfo}>
        <div className={styles.medicalRecordInfo__firstRow}>
          <div className={styles.medicalRecordInfo__firstRow__item}>
            <span>就诊医院</span>
            <span>{treatLogSummary.hospitalName}</span>
          </div>
          <div className={styles.medicalRecordInfo__firstRow__item}>
            <span>就诊医生</span>
            <span>{treatLogSummary.doctorName}</span>
          </div>
        </div>
        <div className={styles.medicalRecordInfo__secondRow}>
          <p>病况描述</p>
          <p>{treatLogSummary.describes}</p>
        </div>
      </div>

      {/* 症状描述 */}
      {symptomCollectionState.isLoading ? (
        <PyrSpinner />
      ) : (
        symptomCollectionState.data.map((symptomCollection, index) => (
          <div
            className={styles.RecordCollectionWrapper}
            key={symptomCollection.label}
          >
            <h3>{symptomCollection.label}</h3>
            <section
              className={styles.RecordDetailsList}
              style={{
                gridTemplateColumns:
                  index === 0 ? '264px 264px 264px' : undefined,
              }}
            >
              {symptomCollection.data
                .filter((symptomItem) => !!symptomItem.content)
                .map((symptomItem) => (
                  // @ts-ignore
                  <SymptomDisplay data={symptomItem} />
                ))}
            </section>
          </div>
        ))
      )}

      <div className={styles.RecordCollectionWrapper}>
        <h3>自评量表</h3>

        {/* 自评量表 */}
        {/* <h3>自评量表</h3> */}
        <RelationList
          treatLogId={props.treatLogId}
          authModel={authModel}
          isExpanded={isExpanded}
        />
      </div>

      <div className={styles.RecordCollectionWrapper}>
        <h3>用药列表</h3>

        {/* TODO: 用药列表样式抽出去 */}
        <div className={styles.medicineList}>
          {medicineListState.isLoading ? (
            <PyrSpinner />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>药品名称</th>
                  <th>规格</th>
                  <th>用药时间及剂量</th>
                </tr>
              </thead>
              <tbody>
                {medicineListState.data.map((medicineItem) => {
                  const { name, specifications, detail } = medicineItem;

                  return (
                    <tr key={medicineItem.id}>
                      <td>{name}</td>
                      <td className={styles.medicineList__lightTd}>
                        {specifications}
                      </td>
                      <td className={styles.medicineList__lightTd}>
                        {detail.map(({ time, amountDescription }) => {
                          return (
                            <span key={uuidV4()}>
                              {time} {amountDescription}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className={styles.RecordCollectionWrapper}>
        <h3>动作视频</h3>

        <div className={styles.actionVideoList}>
          {actionVideoListState.isLoading ? (
            <PyrSpinner />
          ) : (
            actionVideoListState.data.map((actionVideo) => (
              <div key={actionVideo.id}>
                {actionVideo.img === '' ? (
                  <div className={styles.actionVideoList__null}></div>
                ) : (
                  <button
                    onClick={() => {
                      updateSelectedActionVideo(actionVideo);
                    }}
                  >
                    <video>
                      <source src={`${actionVideo.img}`} type="video/mp4" />
                    </video>
                    <i className="pyr pyrplay" />
                  </button>
                )}

                <p className={styles.actionVideoList__videoName}>
                  {/* 붉은 노을 */}
                  {imgTypesNames[actionVideo.imgType] !== null
                    ? imgTypesNames[actionVideo.imgType]
                    : ''}
                </p>
              </div>
            ))
          )}
        </div>
      </div>

      <Dialog
        isOpen={selectedActionVideo !== null}
        onClose={() => {
          updateSelectedActionVideo(null);
        }}
        style={{ width: 800 }}
        isCloseButtonShown
        title={
          selectedActionVideo !== null
            ? imgTypesNames[selectedActionVideo.imgType] !== null
              ? `动作视频 - ${imgTypesNames[selectedActionVideo.imgType]}`
              : ''
            : ''
        }
        portalClassName={styles.actionVideoDialog}
      >
        <div className={styles.actionVideoDialog__body}>
          {selectedActionVideo !== null && (
            <video autoPlay controls>
              <source src={`${selectedActionVideo.img}`} type="video/mp4" />
            </video>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  MedicalRecordStateProps,
  {},
  MedicalRecordOwnProps,
  GlobalState
>(
  mapStateToProps,
  {}
)(withRouter(MedicalRecord));
