import {
  UPDATE_ARTICLE_LIST,
  UpdateArticleListAction,
} from '../actions/articles';

export type ArticlesState = Readonly<{
  list: ArticleItem[];
  isLoading: boolean;
  page: number;
  total: number;
  pageSize: number;
  hasBeenToTheEnd: boolean;
}>;

export type ArticleItem = Readonly<{
  id: number;
  title: string;
  hospitalId: number;
  hospitalname: string;
  createTime: number;
  status: number;
  createUserId: number;
  publish: number;
  content: string;
  summary: string;
  cover: string | null;
  doctorId: number;
  avatar: string;
}>;

const defaultArticlesState: ArticlesState = {
  list: [],
  isLoading: false,
  page: 0,
  total: 0,
  pageSize: 12,
  hasBeenToTheEnd: false,
};

export default function articles(
  state = defaultArticlesState,
  actions: UpdateArticleListAction
) {
  switch (actions.type) {
    case UPDATE_ARTICLE_LIST:
      return { ...state, ...actions.payload };
  }
  return state;
}
