import React, { useState } from 'react';
import SignIn from '../SignIn';

const GrandHome: React.FC<{}> = (props) => {
  const [signInOpened, setSignInOpened] = useState<boolean>(false);

  return (
    <div>
      <div className="body-div">
        {/* 头部 */}
        <div className="header-wrapper">
          <div className="header">
            <div className="header-main">
              <span className="header-logo">
                <img src="/assets/images/icon.svg" />
              </span>
              <span className="header-title">
                <i className="pyr pyrpayouran" style={{ fontSize: 36 }}></i>
              </span>
              <span className="header-desc">我的帕金森病私人管家</span>
            </div>
            <div className="header__operations">
              {' '}
              <a
                href="javascript: void(0)"
                onClick={() => {
                  setSignInOpened(true);
                }}
              >
                登录
              </a>
              <span style={{ marginLeft: 10 }}>|</span>
              <a href="javascript: void(0)">注册</a>
              <a href="/doctor/signIn">
                <img src="/assets/images/doc_icon.svg" />
                <span>我是医生</span>
              </a>
              <a href="javascript: void(0)" className="access-on-phone-btn">
                <img src="/assets/images/phone.svg" />
                <span>在手机上访问</span>
                <div className="qr_code_wrapper">
                  <img src="/assets/images/qr_code.jpeg" />
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* 搜索框 */}
        <div className="search-section">
          <input placeholder="输入症状、医院、或医生名" />
          <button>找一找</button>
        </div>

        <section className="body">
          {/* 就诊评估区域 */}
          <div className="body-constraint">
            <div className="doctor-group-logo">
              <img
                src="/assets/images/section1_img.svg"
                style={{ width: '100%' }}
              />
            </div>

            <button
              className="envelop"
              onClick={() => {
                setSignInOpened(true);
              }}
            >
              <img src="/assets/images/diag_card.svg" />
              <img
                src="/assets/images/diag_filebag.svg"
                className="envelop__filebag"
              />

              <div className="envelop__title" style={{ color: '#404040;' }}>
                就诊评估
              </div>

              <div className="envelop__filebag-text" style={{ color: '#fff' }}>
                诊前评一评，让就诊更高效
              </div>
            </button>
          </div>

          {/* 白色背景的区域 */}
          <div className="main-section">
            {/* 顶部波浪边框 */}
            <div className="wave-border"></div>
            <div style={{ background: '#fff' }}>
              <div className="body-constraint">
                <button
                  className="envelop"
                  onClick={() => {
                    setSignInOpened(true);
                  }}
                >
                  <img src="/assets/images/his_card.svg" alt="" />
                  <img
                    src=" assets/images/filebag2.svg"
                    className="envelop__filebag"
                    style={{ top: 146, left: -25 }}
                    alt=""
                  />

                  <div
                    className="envelop__title"
                    style={{ color: '#fff' }}
                    onClick={() => {
                      setSignInOpened(true);
                    }}
                  >
                    我的病程
                  </div>

                  <div
                    className=" envelop__filebag-text"
                    style={{ color: '#808080', top: 240, left: 70 }}
                  >
                    康复季录不错过，主治医师全掌握
                  </div>
                </button>

                <div>
                  <img src="/assets/images/section2_img.svg" alt="" />
                </div>
              </div>

              <div className="body-constraint" style={{ marginTop: 50 }}>
                {/* 问答部分 */}
                <div className="qna">
                  <div className="qna-item">
                    <div className="qna-question">
                      <div className="qna-question__icon">问</div>
                      <div className="qna-question__content">
                        我这个已经有六七年了，主要是晚上爱胡思乱想，不容易入睡，早上还睡不醒
                      </div>
                    </div>
                    <div className="qna-question__date">2018-05-12</div>
                  </div>
                  <div className="qna-item">
                    <div className="qna-question">
                      <div className="qna-question__icon">问</div>
                      <div className="qna-question__content">
                        好像有时在没有怎么运动的情况下，心率能达到90多到100多，体温总感觉很高
                      </div>
                    </div>
                    <div className="qna-question__date">2018-05-12</div>
                  </div>
                  <div className="qna-item">
                    <div className="qna-question">
                      <div className="qna-question__icon">问</div>
                      <div className="qna-question__content">
                        我想咨询下服用美多芭会引起血小板减少吗？
                      </div>
                    </div>
                    <div className="qna-question__date">2018-05-12</div>
                  </div>
                </div>

                {/* 我要问诊 */}
                <div className="inquiry-demand">
                  <img src="/assets/images/img_ask.svg" />
                  <button
                    className="grand-button"
                    onClick={() => {
                      setSignInOpened(true);
                    }}
                  >
                    我要提问
                  </button>
                </div>
              </div>
            </div>
            {/* 底部波浪边框 */}
            <div
              className="wave-border"
              style={{ transform: 'rotate(180deg) scaleX(2)' }}
            ></div>
          </div>
        </section>

        {/* 悠然锦囊区域 */}
        <section className="section-two" style={{ marginTop: 800 }}>
          <div>
            <img src="/assets/images/img_infomation.svg" alt="" />
          </div>
          <p>科学康复怎么做？听听医生如何说</p>
          <button className="grand-button">悠然锦囊</button>
        </section>

        {/* 顶部波浪边框 */}
      </div>
      <SignIn
        isOpened={signInOpened}
        onClose={() => {
          setSignInOpened(false);
        }}
      />
    </div>
  );
};

export default GrandHome;
