import React from 'react';

import styles from './index.module.scss';
import { PatientsState } from '../../../reducers/patients';
import PatientItemView from './PatientItemView';
import PyrSpinner from '../../common/PyrSpinner';
import EmptyPatientListImg from '../../../assets/images/empty_patient.svg';

type PatientListProps = Readonly<{
  patientsModel: PatientsState;
  selectedPatientId: number | null;
  onPatientSelected: () => void;
}>;

const PatientList: React.FC<PatientListProps> = (props) => {
  const { patientsModel, onPatientSelected, selectedPatientId } = props;

  return (
    <div className={styles.patientList}>
      {patientsModel.list.map((patientItem) => (
        <PatientItemView
          data={patientItem}
          key={patientItem.patientId}
          onPatientSelected={onPatientSelected}
          isSelected={patientItem.patientId === selectedPatientId}
        />
      ))}
      {patientsModel.isLoading && <PyrSpinner />}
      {patientsModel.hasBeenToTheEnd && (
        <p className={styles.fullyLoadedDesciption}>已加载全部内容</p>
      )}

      {patientsModel.list.length === 0 && !patientsModel.isLoading && (
        <img src={EmptyPatientListImg} alt="无患者" style={{ marginTop: 60 }} />
      )}
    </div>
  );
};

export default PatientList;
