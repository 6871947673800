import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import configureStoreDev from './configureStore.dev';
import configureStoreProd from './configureStore.prod';
import { FolderState } from '../reducers/folder';
import { AuthState } from '../reducers/auth';
import { ArticlesState } from '../reducers/articles';
import { UserInfoState } from '../reducers/userInfo';
import { PatientsState } from '../reducers/patients';
import { QnaState } from '../reducers/qna';
import { AdminDoctorManagementState } from '../reducers/adminDoctorManagement';

const selectedConfigureStore =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev;

export const { configureStore } = selectedConfigureStore;

export const { history } = selectedConfigureStore;

export type GlobalState = Readonly<{
  folder: FolderState;
  auth: AuthState;
  articles: ArticlesState;
  userInfo: UserInfoState;
  patients: PatientsState;
  qna: QnaState;
  adminDoctorManagement: AdminDoctorManagementState;
}>;

export type Dispatch = ThunkDispatch<GlobalState, unknown, Action<string>>;

export type ThunkFunction = (
  dispatch: Dispatch,
  getState: () => GlobalState
) => void;
