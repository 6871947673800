import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PatientItem } from '../../../reducers/patients';
import routes from '../../../constants/routes.json';
import MaleIconFile from '../../../assets/images/2x/male.png';
import FemaleIconFile from '../../../assets/images/2x/female.png';
import LabelIconFile from '../../../assets/images/2x/tag_icon.png';
import styles from './index.module.scss';

const PatientItemView: React.FC<{
  data: PatientItem;
  onPatientSelected: () => void;
  isSelected: boolean;
  /**
   * 勾选模式
   */
  checkMode?: {
    isChecked: boolean;
    onCheckboxClick: () => void;
  };
  /**
   * 分组消极状态
   *
   * 表示当前用户拥有分组，但并不在当前分组列表的分组中
   */
  isNegative?: boolean;
}> = (props) => {
  const {
    data: { patientId, userName, age, sex, follow, label },
    isSelected,
    onPatientSelected,
    checkMode,
    isNegative,
  } = props;

  const body = (
    <div
      className={classNames({
        [styles.patientItem]: true,
        [styles.patientItem__selected]: isSelected,
      })}
      key={patientId}
    >
      <span className={styles.patientItem__userName}>{userName}</span>
      <span className={styles.patientItem__gender}>
        {sex === 0 ? <FemaleIcon /> : <MaleIcon />}
      </span>
      <span className={styles.patientItem__age}>{age}</span>

      {/* 是否关注 */}
      {follow ? (
        <span>
          <i className={`pyr pyrlike1 ${styles.followedIcon}`} />
        </span>
      ) : (
        <></>
      )}

      {/* 是否含标签 */}
      {label === 1 ? (
        <span>
          <LabelIcon />
        </span>
      ) : (
        <></>
      )}
    </div>
  );

  return checkMode === undefined ? (
    <Link
      className={styles.patientLink}
      to={`${routes.PATIENTS}/${patientId}`}
      onClick={onPatientSelected}
    >
      {body}
    </Link>
  ) : (
    <div
      className={classNames({
        [styles.patientLink]: true,
        [styles['patientLink--isNegative']]: isNegative,
      })}
    >
      <button onClick={checkMode.onCheckboxClick}>
        {checkMode.isChecked ? (
          <i className="pyr pyrpain_selection" />
        ) : (
          <i className="pyr pyrunselected" />
        )}
      </button>
      {body}
    </div>
  );
};

const MaleIcon = () => (
  <img className={styles.genderIcon} src={MaleIconFile} alt="男性" />
);

const FemaleIcon = () => (
  <img className={styles.genderIcon} src={FemaleIconFile} alt="女性" />
);

const LabelIcon = () => (
  <img className={styles.genderIcon} src={LabelIconFile} alt="标签" />
);

export default PatientItemView;
