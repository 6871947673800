/**
 * 承载患者列表的医生团队列表
 */
import React, { useEffect, useState } from 'react';
import requests from '../../../utils/requests';
import { apiConfig } from '../../../constants/apiConfig';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { warningToast } from '../../../utils/toaster';

import styles from './index.module.scss';
import PatientListInDoctorGroup, {
  DoctorGroupOverview,
} from './PatientListInDoctorGroup';
import PyrSpinner from '../../common/PyrSpinner';

type PatientListByGroupOwnProps = Readonly<{
  selectedPatientId: number | null;
  onPatientSelected: () => void;
}>;

const PatientListByGroup: React.FC<PatientListByGroupOwnProps> = (props) => {
  /**
   * 内部状态
   */
  const [groupList, updateGroupList] = useState<DoctorGroupOverview[]>([]);
  const [isGroupListLoading, setGroupListLoading] = useState<boolean>(false);

  const retrieveDoctorGroupsList = async () => {
    const userTokenRes = loadUserTokenFromLocal();
    if (!userTokenRes.success) {
      return;
    }
    setGroupListLoading(true);

    const res: AxiosResponse<CommonHTTPResponse<{
      data: DoctorGroupOverview[];
    }>> = await requests.post(
      apiConfig.GET_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST,
      {},
      { headers: { token: userTokenRes.token } }
    );

    if (!res.data.success) {
      warningToast(res.data.message);
    } else {
      updateGroupList(res.data.data);
    }

    setGroupListLoading(false);
  };

  useEffect(() => {
    retrieveDoctorGroupsList();
  }, []);

  return (
    <section className={styles.patientList}>
      {isGroupListLoading ? (
        <PyrSpinner />
      ) : (
        groupList.map((groupItem) => {
          const { group } = groupItem;

          return (
            <PatientListInDoctorGroup
              key={group.id}
              groupItem={groupItem}
              onPatientSelected={props.onPatientSelected}
              selectedPatientId={props.selectedPatientId}
            />
          );
        })
      )}
    </section>
  );
};

export default PatientListByGroup;
