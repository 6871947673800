import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';

import { GlobalState, Dispatch } from '../../store/configureStore';
import { AuthState } from '../../reducers/auth';
import styles from './index.module.scss';
import {
  retrieveUserInfo,
  retrievePatientGroupList,
  retrieveLabelList,
} from '../../actions/userInfo';
import routes from '../../constants/routes.json';
import { UserInfoState } from '../../reducers/userInfo';
import { setUnauthenticated } from '../../actions/auth';
import { refreshNotReadChatCount } from '../../actions/qna';
import VisitView from './Visit';
import MyPatientsView from './MyPatients';
import CollectsView from './Collects';
import HanLingYun from '../../assets/images/tempSvg/han_ling_yun.png';

type HeaderStateProps = Readonly<{
  authState: AuthState;
  userInfo: UserInfoState;
}>;

type HeaderDispatchProps = Readonly<{
  retrieveUserInfo: () => void;
}>;

type HeaderStateOwnProps = Readonly<{
  // 不让头部组件获取用户详情
  doNotRetrieveUserInfo?: boolean;

  // 管理员
  isAdmin?: boolean;
}>;

const Header: React.FC<
  HeaderStateProps &
    HeaderDispatchProps &
    HeaderStateOwnProps &
    RouteComponentProps
> = (props) => {
  // const signOut = () => {
  //   setUnauthenticated();
  //   // history.push(routes.SIGN_IN);
  //   window.location.href = routes.SIGN_IN;
  // };

  useEffect(() => {
    // if (!props.doNotRetrieveUserInfo) {
    //   props.retrieveUserInfo();
    //   props.retrievePatientGroupList();
    //   props.retrieveLabelList();
    //   props.refreshNotReadChatCount();
    // }
  }, []);

  return (
    <div className={styles.wrapper}>
      {/* 搜索 */}
      <div className={styles.searchWrapper}>
        <label htmlFor="search">
          <SearchOutlined />
        </label>
        <input id="search" />
      </div>

      {/* 用户名 */}
      <div className={styles.usernameWrapper}>
        <div className={styles.usernameWrapper__left}>
          <div className={styles.usernameWrapper__avatarWrapper}>
            <img src={HanLingYun} alt="" />
          </div>
          <span>韩凌云</span>
        </div>
        <button className={styles.usernameWrapper__more}>
          <MoreOutlined size={30} />
        </button>
      </div>

      <VisitView />

      <MyPatientsView />

      <CollectsView />
    </div>
  );
};

Header.defaultProps = {
  doNotRetrieveUserInfo: false,
};

const mapStateToProps = (state: GlobalState) => ({
  authState: state.auth,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveUserInfo: () => {
    dispatch(retrieveUserInfo());
  },
});

export default connect<HeaderStateProps, HeaderDispatchProps, {}, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
