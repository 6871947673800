/**
 * 医生团队下的患者列表
 */
import React, { useState } from 'react';
import queryString from 'query-string';
import { Button, Icon } from '@blueprintjs/core';

import styles from './index.module.scss';
import { PatientItem } from '../../../reducers/patients';
import requests from '../../../utils/requests';
import { apiConfig } from '../../../constants/apiConfig';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { warningToast } from '../../../utils/toaster';
import PyrSpinner from '../../common/PyrSpinner';
import PatientItemView from './PatientItemView';

export type DoctorGroupOverview = Readonly<{
  group: DoctorGroupItem;
  total: number;
}>;

export type DoctorGroupItem = Readonly<{
  createUserId: number;
  hospitalId: number;
  hospitalName: string;
  id: number;
  leader: number;
  name: string;
  publicFlag: number;
  status: number;
}>;

type PatientList = Readonly<{
  data: PatientItem[];
  page: number;
  pageSize: 30;
  isLoading: boolean;
}>;

type PatientListUploadPayload = Readonly<{
  data?: PatientItem[];
  page?: number;
  pageSize?: 30;
  isLoading?: boolean;
}>;

const defaultPatientList: PatientList = {
  data: [],
  page: 0,
  pageSize: 30,
  isLoading: false,
};

const PatientListInDoctorGroup: React.FC<{
  groupItem: DoctorGroupOverview;
  selectedPatientId: number | null;
  onPatientSelected: () => void;
}> = (props) => {
  const {
    groupItem: { group, total },
    selectedPatientId,
    onPatientSelected,
  } = props;

  /**
   * 内部状态
   */
  // 团队下患者列表
  const [patientList, updatePatientList] = useState<PatientList>(
    defaultPatientList
  );

  // 团队患者列表是否展开
  const [isPatientListExpanded, setPatientListExpanded] = useState<boolean>(
    false
  );

  const updatePatientListByKey = (payload: PatientListUploadPayload) => {
    updatePatientList({ ...patientList, ...payload });
  };

  const retrievePatientList = async (page: number) => {
    const tokenRes = loadUserTokenFromLocal();
    if (!tokenRes.success) {
      return;
    }

    updatePatientListByKey({ isLoading: true });
    const patientListRes: AxiosResponse<CommonHTTPResponse<{
      data: PatientItem[];
    }>> = await requests.post(
      `${apiConfig.GET_PATIENT_LIST_BY_GROUP_ID}?${queryString.stringify({
        page: page,
        pageSize: patientList.pageSize,
        groupId: group.id,
      })}`,
      {},
      { headers: { token: tokenRes.token } }
    );

    if (!patientListRes.data.success) {
      warningToast(patientListRes.data.message);
      updatePatientListByKey({
        isLoading: false,
      });
    } else {
      updatePatientListByKey({
        page,
        isLoading: false,
        data: patientListRes.data.data,
      });
    }
  };

  const onGroupBtnClick = () => {
    if (isPatientListExpanded) {
      updatePatientList(defaultPatientList);
      setPatientListExpanded(false);
    } else {
      retrievePatientList(1);
      setPatientListExpanded(true);
    }
  };

  return (
    <>
      <Button
        className={styles.doctorGroupItem}
        key={group.id}
        minimal
        onClick={onGroupBtnClick}
      >
        <div>
          <span>{group.name}</span>
          <span className={styles.doctorGroupItem__patientCount}>{total}</span>
        </div>
        <div>
          <Icon icon={isPatientListExpanded ? 'caret-up' : 'caret-down'} />
        </div>
      </Button>
      <div>
        {patientList.isLoading ? (
          <PyrSpinner />
        ) : (
          patientList.data.map((patientItem) => (
            <PatientItemView
              key={patientItem.patientId}
              data={patientItem}
              onPatientSelected={onPatientSelected}
              isSelected={selectedPatientId === patientItem.patientId}
            />
          ))
        )}
      </div>
    </>
  );
};

export default PatientListInDoctorGroup;
