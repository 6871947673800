import { DateInput, IDateFormatProps } from '@blueprintjs/datetime';
import React, { useEffect, useState } from 'react';
import { LocaleUtils } from 'react-day-picker';

import styles from '../index.module.scss';

type DateInputValueGroupProps = Readonly<{
  label: string | JSX.Element;
  value: number;
  labelKey?: string;
  onChange?: (
    payload: {
      newValue: number;
      labelKey: string | undefined;
    } | null
  ) => void;
}>;

const MONTHS: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  '一月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
];

const WEEKDAYS_LONG = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const WEEKDAYS_SHORT = ['日', '一', '二', '三', '四', '五', '六'];

const cnLocaleUtils: LocaleUtils = {
  ...LocaleUtils,
  getMonths: () => MONTHS,
  formatWeekdayLong: (weekday) => WEEKDAYS_LONG[weekday],
  formatWeekdayShort: (weekday) => WEEKDAYS_SHORT[weekday],
};

const DateInputValueGroup: React.FC<DateInputValueGroupProps> = (props) => {
  const { label, labelKey, value, onChange } = props;

  const [activeValue, updateActiveValue] = useState<number>(value);

  useEffect(() => {
    updateActiveValue(value);
  }, [value]);

  const jsDateFormatter: IDateFormatProps = {
    formatDate: (date) => date.toLocaleDateString(),
    parseDate: (str) => new Date(str),
    placeholder: 'M/D/YYYY',
  };

  return (
    <div className={styles.labelValueGroup}>
      <div>{label}</div>

      <div>
        <DateInput
          {...jsDateFormatter}
          value={activeValue === null ? null : new Date(activeValue)}
          onChange={(selectedDate: Date, isUserChange: boolean) => {
            const newValue = selectedDate.getTime();
            updateActiveValue(newValue);

            if (typeof onChange === 'function') {
              onChange({ labelKey, newValue });
            }
          }}
          localeUtils={cnLocaleUtils}
        />
      </div>
    </div>
  );
};

export default DateInputValueGroup;
