import React, { useState, useEffect } from 'react';
import { Button } from '@blueprintjs/core';

import { ArticlesState } from '../../reducers/articles';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { GlobalState, Dispatch } from '../../store/configureStore';
import { retrieveArticleList } from '../../actions/articles';
import { retrievePatientList } from '../../actions/patients';
import PatientListDrawer from './PatientListDrawer';
import PatientDetails from './PatientDetails';
import { PatientsState } from '../../reducers/patients';
import routes from '../../constants/routes.json';
import styles from './index.module.scss';
import PatientSelectPrompt from '../../assets/images/patient_select_prompt.svg';

type PatientsPageStateProps = Readonly<{
  articlesModel: ArticlesState;
  patientsModel: PatientsState;
}>;

type PatientsPageDispatchProps = Readonly<{
  retrieveArticleList: () => void;
  retrievePatientList: () => void;
}>;

type PatientsPageOwnProps = Readonly<RouteComponentProps<{ id?: string }>>;

const PatientsPage: React.FC<
  PatientsPageStateProps & PatientsPageDispatchProps & PatientsPageOwnProps
> = (props) => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);

  const selectedPatientIdStr = props.match.params.id;
  const selectedPatientId =
    typeof selectedPatientIdStr === 'string'
      ? parseInt(selectedPatientIdStr)
      : null;

  useEffect(() => {
    retrievePatientList();
  }, []);

  useEffect(() => {
    if (props.match.params.id === undefined) {
      if (props.patientsModel.list.length >= 1) {
        props.history.push(
          `${routes.PATIENTS}/${props.patientsModel.list[0].patientId}`
        );
      }
    }
  }, [props.patientsModel.list]);

  return (
    <div>
      <Button
        className={styles.drawerTriggerBtn}
        style={{ position: 'absolute', left: 0 }}
        onClick={() => {
          setDrawerOpened(true);
        }}
        minimal
      >
        <i className="pyr pyrP_list_button" />

        {!props.patientsModel.isLoading && selectedPatientId === null && (
          <img
            className={styles.drawerTriggerBtn__patientSelectPrompt}
            src={PatientSelectPrompt}
            alt="患者添加引导"
          />
        )}
      </Button>
      {selectedPatientId === null && props.patientsModel.isLoading ? (
        <p
          style={{
            textAlign: 'center',
            color: '#999',
            marginTop: 100,
            fontSize: 18,
          }}
        >
          正在获取患者列表...
        </p>
      ) : (
        <> </>
      )}
      {/* {!props.patientsModel.isLoading && (
        )} */}
      <PatientDetails selectedPatientId={selectedPatientId} />
      <PatientListDrawer
        isOpen={isDrawerOpened}
        onClose={() => {
          setDrawerOpened(false);
        }}
        selectedPatientId={selectedPatientId}
      />
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  articlesModel: state.articles,
  patientsModel: state.patients,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveArticleList: () => {
    dispatch(retrieveArticleList(true));
  },

  retrievePatientList: () => {
    dispatch(retrievePatientList());
  },
});

export default connect<
  PatientsPageStateProps,
  PatientsPageDispatchProps,
  PatientsPageOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(PatientsPage);
