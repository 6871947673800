import React from 'react';
import { Toaster, Position, Intent } from '@blueprintjs/core';

export const warningToast = (message: string) => {
  Toaster.create({ position: Position.TOP }).show({
    message: message,
    intent: Intent.WARNING,
    // icon: <i className="i pyrnoti" />,
    icon: (
      <i
        className="pyr pyrnoti"
        style={{ margin: '9px 0 0 15px', color: '#ff0000' }}
      />
    ),
  });
};

export const successToast = (message: string) => {
  Toaster.create({ position: Position.TOP }).show({
    message: message,
    intent: Intent.SUCCESS,
    icon: 'tick',
  });
};
