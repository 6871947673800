import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import counter from './counter';
import folder from './folder';
import auth from './auth';
import userInfo from './userInfo';
import articles from './articles';
import patients from './patients';
import qna from './qna';
import adminDoctorManagement from './adminDoctorManagement';

export default function createRootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    counter,
    folder,
    auth,
    userInfo,
    articles,
    patients,
    qna,
    adminDoctorManagement,
  });
}
