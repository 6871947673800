/**
 * 危险因素
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Intent, Tab, Tabs } from '@blueprintjs/core';

import MedicineList from './MedicineList';
import { PatientDetails } from '../../../PatientDetails';
import WorkingExpList from './WorkingExpList';
import BrainHistory from './BrainHistory';
import { AuthState } from '../../../../../reducers/auth';
import { GlobalState } from '../../../../../store/configureStore';
import styles from './index.module.scss';

import upperStyles from '../index.module.scss';

type RiskFactorsTabContentStateProps = Readonly<{
  authModel: AuthState;
}>;

type RiskFactorsTabContentOwnProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
  isUpdatePatientLoading: boolean;
}>;

enum MedicineListType {
  /**
   * 正在服用
   */
  usingMedicineList,
  /**
   * 曾经服用
   */
  usedMedicineList,
}

const RiskFactorsTabContent: React.FC<
  RiskFactorsTabContentOwnProps & RiskFactorsTabContentStateProps
> = (props) => {
  const { patientDetails, updatePatientInfo, isUpdatePatientLoading } = props;

  // 惯用手
  const [habitHand, updateHabitHand] = useState<string>(
    patientDetails.habitHand
  );

  // 头脑历史
  const [brainHistoryListStrSet, updateBrainHistoryListStrSet] = useState<
    string
  >(
    typeof patientDetails.brainHistory === 'string'
      ? patientDetails.brainHistory
      : ''
  );

  // 正在服用的药物
  const [medicineListStrSet, updateMedicineListStrSet] = useState<string>(
    typeof patientDetails.useingMedicine === 'string'
      ? patientDetails.useingMedicine
      : ''
  );

  // 曾经服用的药物
  const [oneceMedicineListStrSet, updateOneceMedicineListStrSet] = useState<
    string
  >(
    typeof patientDetails.oneceMedicine === 'string'
      ? patientDetails.oneceMedicine
      : ''
  );

  // 工作经验
  const [workingExpStrSet, updateWorkingExpStrSet] = useState<string>(
    typeof patientDetails.workingExperience === 'string'
      ? patientDetails.workingExperience
      : ''
  );

  const [selectedMedicineListType, updateSelectedMedicineListType] = useState<
    MedicineListType
  >(MedicineListType.usingMedicineList);

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div>
          {/* 惯用手 */}
          <div className={styles.habitHand}>
            <span className={styles.habitHand__label}>惯用手</span>
            <button onClick={() => updateHabitHand('左手')}>
              {habitHand === '左手' ? (
                <i className="pyr pyrchoise" />
              ) : (
                <i className="pyr pyrcheck" />
              )}
              <span>左手</span>
            </button>
            <button onClick={() => updateHabitHand('右手')}>
              {habitHand === '右手' ? (
                <i className="pyr pyrchoise" />
              ) : (
                <i className="pyr pyrcheck" />
              )}

              <span>右手</span>
            </button>

            <button onClick={() => updateHabitHand('双手')}>
              {habitHand === '双手' ? (
                <i className="pyr pyrchoise" />
              ) : (
                <i className="pyr pyrcheck" />
              )}

              <span>双手</span>
            </button>
          </div>

          {/* 头脑历史 */}
          <BrainHistory
            BrainHistoryListStrSet={brainHistoryListStrSet}
            updateBrainHistoryListStrSet={updateBrainHistoryListStrSet}
          />
        </div>

        {/* 用药列表 */}

        <div>
          {selectedMedicineListType === MedicineListType.usingMedicineList && (
            <MedicineList
              medicineListStrSet={medicineListStrSet}
              updateMedicineListStrSet={updateMedicineListStrSet}
            />
          )}

          {selectedMedicineListType === MedicineListType.usedMedicineList && (
            <MedicineList
              medicineListStrSet={oneceMedicineListStrSet}
              updateMedicineListStrSet={updateOneceMedicineListStrSet}
            />
          )}

          <Tabs
            className={styles.medicineListTypeTabs}
            selectedTabId={selectedMedicineListType}
            onChange={(newTabValue: MedicineListType) => {
              updateSelectedMedicineListType(newTabValue);
            }}
          >
            <Tab
              key={MedicineListType.usingMedicineList}
              id={MedicineListType.usingMedicineList}
              title="正在服用"
            />
            <Tab
              key={MedicineListType.usedMedicineList}
              id={MedicineListType.usedMedicineList}
              title="曾经服用"
            />
          </Tabs>
        </div>

        {/* 工作经验列表 */}
        <WorkingExpList
          workingExpListStrSet={workingExpStrSet}
          updateWorkingExpListStrSet={updateWorkingExpStrSet}
        />
      </div>
      {!(
        medicineListStrSet === patientDetails.useingMedicine &&
        workingExpStrSet === patientDetails.workingExperience &&
        oneceMedicineListStrSet === patientDetails.oneceMedicine &&
        brainHistoryListStrSet === patientDetails.brainHistory &&
        habitHand === patientDetails.habitHand
      ) && (
        <div className={upperStyles.updateUserInfoBtnWrapper}>
          <Button
            intent={Intent.PRIMARY}
            text="立即更新"
            onClick={() => {
              updatePatientInfo({
                useingMedicine: medicineListStrSet,
                oneceMedicine: oneceMedicineListStrSet,
                workingExperience: workingExpStrSet,
                brainHistory: brainHistoryListStrSet,
                habitHand: habitHand,
              });
            }}
            loading={isUpdatePatientLoading}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  RiskFactorsTabContentStateProps,
  {},
  RiskFactorsTabContentOwnProps,
  GlobalState
>(mapStateToProps)(RiskFactorsTabContent);
