// 病程信息概述
export type TreatLogSummary = Readonly<{
  completeType: number;
  createTime: string;
  departmentName: string;
  describes: string;
  doctorId: number;
  doctorName: string;
  hospitalName: string;
  id: number;
  illnessType: number;
  name: string;
  patientId: number;
  patientName: string;
  seek: number;
  status: number;
  treatLogId: number;
  treatTime: string;
  treatTimes: number;
  treatType: TreatType;
  type: number;
  updateTime: string;
}>;

// 记录类型
export enum TreatType {
  // 全部
  all = 0,
  // 就诊记录
  medicalRecord = 1,
  // 病程记录
  courseRecord = 2,
}

export type TreatLogState = Readonly<{
  list: TreatLogSummary[];
  isLoading: boolean;
  page: number;
  total: number;
  pageSize: number;
  hasBeenToTheEnd: boolean;
}>;

export class ScrollEventEmitter {
  listeners: Function[] = [];

  addListener(listener: Function): void {
    this.listeners = [...this.listeners, listener];
  }

  clearListeners(): void {
    this.listeners = [];
  }

  trigger() {
    this.listeners.forEach((listenerFunction) => {
      listenerFunction();
    });
  }
}

/**
 * 记录列表展示方式
 */
export enum RecordListDisplayType {
  BY_LIST,
  BY_CARD,
}
