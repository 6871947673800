import React, { useState, useRef } from 'react';
import { Spinner, Button } from '@blueprintjs/core';
import classNames from 'classnames';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { PatientLabelList, UserInfoState } from '../../../reducers/userInfo';
import styles from './index.module.scss';
import requests from '../../../utils/requests';
import { apiConfig } from '../../../constants/apiConfig';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { successToast, warningToast } from '../../../utils/toaster';
import { GlobalState, Dispatch } from '../../../store/configureStore';
import { retrieveLabelList } from '../../../actions/userInfo';

type LabelListViewOwnProps = Readonly<{
  labelList: PatientLabelList;
  onLabelItemSelect: (groupId: number) => void;
  isBeingEdited: boolean;
}>;

type LabelListViewStateProps = Readonly<{
  userInfo: UserInfoState;
}>;

type LabelListViewDispatchProps = Readonly<{
  retrieveLabelList: () => void;
}>;

const LabelListView: React.FC<
  LabelListViewOwnProps & LabelListViewStateProps & LabelListViewDispatchProps
> = (props) => {
  const { labelList, onLabelItemSelect, userInfo, retrieveLabelList } = props;
  const [selectedLabelId, selectLabel] = useState<number>();

  const deletingLabelList = useRef<number[]>([]);

  const deleteLabel = async (id: number) => {
    if (deletingLabelList.current.includes(id)) {
      return;
    }

    const userTokenRes = loadUserTokenFromLocal();
    if (!userTokenRes.success) {
      return;
    }

    deletingLabelList.current = [...deletingLabelList.current, id];

    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.DELETE_LABEL}?${queryString.stringify({
        labelId: id,
        doctorId: userInfo.data?.id,
      })}`,
      {},
      { headers: { token: userTokenRes.token } }
    );

    if (res.data.success) {
      successToast('标签已删除');
      retrieveLabelList();
    } else {
      warningToast(res.data.message);
    }

    deletingLabelList.current = deletingLabelList.current.filter(
      (labelId) => labelId !== id
    );
  };

  return labelList.isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.patientGroupList}>
      {labelList.data.map((label) => {
        const { id, name } = label;

        return (
          <div
            key={id}
            className={classNames({
              [styles.patientGroupItem]: true,
              [styles['labelItem--selected']]: selectedLabelId === id,
            })}
          >
            {/* 删除按钮 */}
            {props.isBeingEdited ? (
              <button
                className={styles.patientGroupDeleteBtn}
                onClick={() => {
                  deleteLabel(id);
                }}
              >
                <i className="pyr pyrdelete" />
              </button>
            ) : (
              <></>
            )}

            {/* 分组选择按钮 */}
            <Button
              className={styles.patientGroupItem__selector}
              onClick={() => {
                selectLabel(id);
                onLabelItemSelect(id);
              }}
              text={name}
              minimal
            />
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveLabelList: () => {
    dispatch(retrieveLabelList());
  },
});

export default connect<
  LabelListViewStateProps,
  LabelListViewDispatchProps,
  LabelListViewOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(LabelListView);
