// 病史记录区域
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ButtonGroup, Button } from '@blueprintjs/core';
import queryString from 'query-string';

import styles from './index.module.scss';
import { apiConfig } from '../../../constants/apiConfig';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { AuthState } from '../../../reducers/auth';
import {
  RecordListDisplayType,
  ScrollEventEmitter,
  TreatLogState,
  TreatLogSummary,
  TreatType,
} from './types';
import RecordItem, { treatTypeDesc } from './RecordItem';
import requests from '../../../utils/requests';
import PyrSpinner from '../../common/PyrSpinner';
import RecordListBlank from '../../../assets/images/p_record_blank.svg';

type TreatLogResponse = CommonHTTPResponse<{
  page: {
    list: TreatLogSummary[];
    lastPage: 1 | 0;
    firstPage: 1 | 0;
    hasNextPage: boolean;
    total: number;
    size: number;
    pageNum: number;
    pageSize: number;
  };
}>;

export type UpdateTreatLogPayload = Readonly<{
  list?: TreatLogSummary[];
  isLoading?: boolean;
  page?: number;
  total?: number;
  pageSize?: number;
  hasBeenToTheEnd?: boolean;
}>;

const treatLogDefaultValue = {
  list: [],
  isLoading: false,
  page: 0,
  total: 0,
  pageSize: 12,
  hasBeenToTheEnd: false,
};

type MedicalHistoryOwnProps = Readonly<{
  patientId: number;
  authModel: AuthState;
}>;

const scrollEventEmitter = new ScrollEventEmitter();

const MedicalHistory: React.FC<MedicalHistoryOwnProps> = (props) => {
  const { patientId, authModel } = props;

  const [treatLogState, updateTreatLogState] = useState<TreatLogState>(
    treatLogDefaultValue
  );

  const [logTypeFilter, updateLogTypeFilter] = useState<TreatType>(
    TreatType.all
  );

  const updateTreatLogStateByKey = (payload: UpdateTreatLogPayload) => {
    updateTreatLogState({ ...treatLogState, ...payload });
  };

  const [lastVisitedRecordId, updateLastVisitedRecordId] = useState<
    number | null
  >(null);

  const [
    /**
     * 记录展示方式
     */
    recordListDisplayType,
    updateRecordListDisplayType,
  ] = useState<RecordListDisplayType>(RecordListDisplayType.BY_CARD);

  useEffect(() => {
    // 获取病史记录
    const retrieveTreatLogList = async (pageNumber: number) => {
      updateTreatLogStateByKey({ isLoading: true });
      const treatLogRes: AxiosResponse<TreatLogResponse> = await requests.post(
        `${apiConfig.GET_PATIENT_TREAT_LOG}?${queryString.stringify({
          patientId,
          page: pageNumber,
          pageSize: treatLogState.pageSize,
          treatType:
            logTypeFilter === TreatType.all ? undefined : logTypeFilter,
        })}`,
        {},
        { headers: { token: authModel.token } }
      );

      const resData = treatLogRes.data;

      if (resData.success) {
        const pageData = resData.page;

        updateTreatLogStateByKey({
          isLoading: false,
          page: pageNumber,
          total: pageData.total,
          list: pageData.list,
          hasBeenToTheEnd: pageData.lastPage === 1,
        });
      } else {
        updateTreatLogStateByKey({ isLoading: false });
      }
    };

    retrieveTreatLogList(1);
  }, [logTypeFilter, patientId]);

  const generateLogFilterBtn = (treatType: TreatType) => {
    return (
      <Button
        className={classNames({
          [styles['filterBtn--selected']]: logTypeFilter === treatType,
        })}
        small
        onClick={() => {
          updateLogTypeFilter(treatType);
          updateRecordListDisplayType(RecordListDisplayType.BY_LIST);
        }}
        text={treatTypeDesc[treatType]}
      />
    );
  };

  const onRecordListScroll = () => {
    scrollEventEmitter.trigger();
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <h2>
          病史记录
        </h2>
        <div className={styles.filterBtnList}>
          <ButtonGroup>
            {generateLogFilterBtn(TreatType.all)}
            {generateLogFilterBtn(TreatType.medicalRecord)}
            {generateLogFilterBtn(TreatType.courseRecord)}
          </ButtonGroup>
        </div>
      </div>
      <div className={styles.recordListWrapper} onScroll={onRecordListScroll}>
        {treatLogState.isLoading ? (
          <PyrSpinner />
        ) : treatLogState.list.length > 0 ? (
          treatLogState.list.map((treatLog, index) => (
            <RecordItem
              key={treatLog.id}
              index={index}
              treatLogData={treatLog}
              isLastVisited={treatLog.id === lastVisitedRecordId}
              updateLastVisitedRecordId={updateLastVisitedRecordId}
              scrollEventEmitter={scrollEventEmitter}
              recordListDisplayType={recordListDisplayType}
              updateRecordListDisplayType={updateRecordListDisplayType}
            />
          ))
        ) : (
          <div className={styles.recordListEmptyWrapper}>
            <img src={RecordListBlank} alt="空内容" />
          </div>
        )}
      </div>
    </section>
  );
};

export default MedicalHistory;
