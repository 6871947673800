import { Action } from "redux";
import produce from "immer";

import {
  UpdateFolderContentAction,
  UPDATE_FOLDER_URI,
  UpdateFolderURIAction,
} from "../actions/folder";

export type FolderState = Readonly<{
  folderURI: string;
  content: {
    isLoading: boolean;
    list: string[];
    selectedFileNames: string[];
  };
}>;

const defaultFolderState: FolderState = {
  folderURI: "/Users/fengzeyu/Desktop",
  content: {
    isLoading: false,
    list: [],
    selectedFileNames: [],
  },
};

export default function folder(
  state = defaultFolderState,
  action:
    | UpdateFolderContentAction
    | Action<"GET_FOLDER_CONTENT">
    | UpdateFolderURIAction
) {
  switch (action.type) {
    case UPDATE_FOLDER_URI:
      return produce(state, (draftState) => {
        draftState.folderURI = action.payload;
      });
    default:
      return state;
  }
}
