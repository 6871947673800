import { AxiosResponse } from 'axios';
import queryString from 'query-string';

import { AdminDoctorListItem } from '../reducers/adminDoctorManagement/types';
import { CommonHTTPResponse } from '../commonTypes';
import { Dispatch, GlobalState, ThunkFunction } from '../store/configureStore';
import { apiConfig } from '../constants/apiConfig';
import requests from '../utils/requests';

export const UPDATE_ADMIN_DOCTOR_LIST = 'UPDATE_ADMIN_DOCTOR_LIST';
export const RETRIEVE_ADMIN_DOCTOR_LIST = 'RETRIEVE_ADMIN_DOCTOR_LIST';

// 更新管理员医生列表
export type UpdateAdminDoctorListPayload = Readonly<{
  list?: AdminDoctorListItem[];
  isLoading?: boolean;
  pageNum?: number;
  pageSize?: number;
  totalCount?: number;
}>;

export type UpdateAdminDoctorListAction = Readonly<{
  type: 'UPDATE_ADMIN_DOCTOR_LIST';
  payload: UpdateAdminDoctorListPayload;
}>;

export const updateAdminDoctorList = (
  payload: UpdateAdminDoctorListPayload
): UpdateAdminDoctorListAction => ({
  type: UPDATE_ADMIN_DOCTOR_LIST,
  payload,
});

// 拉取更新管理员医生列表
export type RetrieveAdminDoctorListAction = Readonly<{
  type: 'RETRIEVE_ADMIN_DOCTOR_LIST';
}>;

type AdminDoctorListResponse = Readonly<
  CommonHTTPResponse<{
    page: {
      pageNum: number;
      pageSize: number;
      size: number;
      startRow: number;
      endRow: number;
      total: number;
      pages: number;
      list: AdminDoctorListItem[];
      prePage: number;
      nextPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      navigatePages: number;
      navigatepageNums: [];
      navigateFirstPage: number;
      navigateLastPage: number;
      firstPage: number;
      lastPage: number;
    };
  }>
>;

export type RetrieveAdminDoctorListPayload = {
  academicTitleId?: number;
  hospitalId?: number;
  doctorName?: string;
  email?: string;
};

export const retrieveAdminDoctorList = (
  pageNum: number,
  payload?: RetrieveAdminDoctorListPayload
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, adminDoctorManagement } = state;

    dispatch(updateAdminDoctorList({ isLoading: true }));

    const newPage = pageNum;

    const res: AxiosResponse<AdminDoctorListResponse> = await requests.post(
      `${apiConfig.ADMIN_GET_DOCTOR_LIST}`,
      {
        page: newPage,
        pageSize: adminDoctorManagement.pageSize,
        ...(payload === undefined
          ? {}
          : {
              academicTitle: payload.academicTitleId,
              hospitalId: payload.hospitalId,
              doctorName: payload.doctorName,
              email: payload.email,
            }),
      },
      {
        headers: {
          token: auth.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updateAdminDoctorList({
          list: resData.page.list,
          pageNum: newPage,
          isLoading: false,
          totalCount: resData.page.total,
        })
      );
    }
  };
};

export const updateAdminDoctor = (
  doctorToUpdate: AdminDoctorListItem
): ThunkFunction => async (dispatch: Dispatch, getState: () => GlobalState) => {
  const state = getState();

  const { auth, adminDoctorManagement } = state;

  dispatch(
    updateAdminDoctorList({
      list: adminDoctorManagement.list.map((doctor) =>
        doctor.id === doctorToUpdate.id ? doctorToUpdate : doctor
      ),
    })
  );

  requests.get(
    `${apiConfig.ADMIN_UPDATE_DOCTOR}?${queryString.stringify({
      doctorId: doctorToUpdate.id,
      top: doctorToUpdate.top,
      // manager: doctorToUpdate.manager,
    })}`,
    {
      headers: {
        token: auth.token,
      },
    }
  );
};
