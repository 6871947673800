import React, { useEffect, useState } from 'react';

import HealthArchivesLeft from '../../assets/images/tempSvg/healthArchivesLeft.svg';
import HealthArchivesRight from '../../assets/images/tempSvg/healthArchivesRight.svg';
import PyrSpinner from '../common/PyrSpinner';
import styles from './index.module.scss';

const HealthArchives: React.FC<{}> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500 + 500 * Math.random());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {isLoading ? <PyrSpinner /> : <img src={HealthArchivesLeft} alt="" />}
      </div>
      <div className={styles.right}>
        {isLoading ? <PyrSpinner /> : <img src={HealthArchivesRight} alt="" />}
      </div>
    </div>
  );
};

export default HealthArchives;
