import React from 'react';
import { Icon } from '@blueprintjs/core';
import { v4 as uuidV4 } from 'uuid';

import { ReportRow } from '../types';
import styles from './index.module.scss';

const NMSSReportTable: React.FC<{ reportData: ReportRow[] }> = (props) => {
  const { reportData } = props;

  return (
    <table className={styles.reportTable}>
      <thead>
        <tr>
          <th rowSpan={2}>项目</th>
          <th colSpan={2}></th>
          <th colSpan={3}>程度</th>
          <th colSpan={4}>频率</th>
        </tr>
        <tr>
          {reportData[0].relationAnswerList
            .reduce<string[]>(
              (previousRelationAnswer, relationAnswer) => [
                ...previousRelationAnswer,
                ...relationAnswer.content.split(','),
              ],
              []
            )
            .map((colString) => (
              <th id={uuidV4()}>{colString}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {reportData.map((reportRow) => {
          const { relationAnswerList } = reportRow;
          return (
            <tr key={reportRow.id}>
              <td>{reportRow.content}</td>

              {(() => {
                var list = relationAnswerList.reduce<boolean[]>(
                  (previousValue, relationAnswer) => {
                    const { content, userAnswer } = relationAnswer;

                    const colBooleanList = content
                      .split(',')
                      .map(
                        (col, colIndex) => parseInt(userAnswer) === colIndex + 1
                      );

                    return [...previousValue, ...colBooleanList];
                  },
                  []
                );

                return list.map((isChosen) => (
                  <td className={styles.NMSSBodyTd}>
                    {isChosen ? <i className="pyr pyrpain_selection" /> : <></>}
                  </td>
                ));
              })()}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default NMSSReportTable;
