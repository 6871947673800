/**
 * 分享页面
 *
 * 包含文章列表
 */
import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import routes from '../../constants/routes.json';
import { GlobalState, Dispatch } from '../../store/configureStore';
import { retrieveArticleList } from '../../actions/articles';
import { connect } from 'react-redux';
import { ArticlesState } from '../../reducers/articles';
import styles from './index.module.scss';
import ShareArticleItem from './components/ShareArticleItem';
import { UserInfoState } from '../../reducers/userInfo';
import PyrSpinner from '../common/PyrSpinner';
import { Button, Select } from 'antd';

type SharePageStateProps = Readonly<{
  articlesModel: ArticlesState;
  userInfo: UserInfoState;
}>;

type SharePageDispatchProps = Readonly<{
  retrieveArticleList: () => void;
}>;

type SharePageOwnProps = Readonly<RouteComponentProps>;

const SharePage: React.FC<
  SharePageStateProps & SharePageDispatchProps & SharePageOwnProps
> = (props) => {
  const { articlesModel, userInfo } = props;

  useEffect(() => {
    props.retrieveArticleList();
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.header__item}>
          <div className={styles.header__item__left}>
            <div>所属分类</div>
            <div className={styles.header__typesGroup}>
              <button className={styles.header__typeItem}>全部</button>
              <button className={styles.header__typeItem}>预防关注</button>
              <button className={styles.header__typeItem}>日常护理</button>
              <button className={styles.header__typeItem}>医疗资讯</button>
              <button className={styles.header__typeItem}>抗帕药物贴士</button>
              <button className={styles.header__typeItem}>运动康复</button>
              <button className={styles.header__typeItem}>名医说</button>
            </div>
          </div>
        </div>
        <div className={styles.header__divider}></div>
        <div className={styles.header__item}>
          <div className={styles.header__item__left}>
            <div>其他选项</div>
            <div>
              <span>内容：</span>
              <Select placeholder="搜索" showSearch></Select>
            </div>
            <div>
              <span>分享者：</span>
              <Select placeholder="选择"></Select>
            </div>
          </div>
        </div>
      </div>

      <section className={styles.articleListWrapper}>
        {articlesModel.isLoading ? (
          <PyrSpinner />
        ) : (
          articlesModel.list.map((article) => (
            <Link
              to={
                `${routes.ARTICLE}/${article.id}`
                // userInfo.data?.id === article.doctorId
                //   ? `${routes.WRITE_ARTICLE}/${article.id}`
                //   : `${routes.ARTICLE}/${article.id}`
                //   // `${routes.WRITE_ARTICLE}/${article.id}`
              }
              className={styles.articleItem}
              key={article.id}
            >
              <ShareArticleItem data={article} />
            </Link>
          ))
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  articlesModel: state.articles,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveArticleList: () => {
    dispatch(retrieveArticleList(true));
  },
});

export default connect<
  SharePageStateProps,
  SharePageDispatchProps,
  SharePageOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(SharePage);
