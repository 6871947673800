import React from 'react';
import { Spinner } from '@blueprintjs/core';

const PyrSpinner = () => {
  return (
    <div style={{ padding: '20px 0' }}>
      <Spinner size={20} />
    </div>
  );
};

export default PyrSpinner;
