import React, { useEffect, useState } from 'react';

import PayitangLeft from '../../assets/images/tempSvg/payitangLeft.svg';
import PayitangRight from '../../assets/images/tempSvg/payitangRight.svg';
import PyrSpinner from '../common/PyrSpinner';
import styles from './index.module.scss';

const Payitang: React.FC<{}> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500 + 500 * Math.random());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {isLoading ? <PyrSpinner /> : <img src={PayitangLeft} alt="" />}
      </div>
      <div className={styles.right}>
        {isLoading ? <PyrSpinner /> : <img src={PayitangRight} alt="" />}
      </div>
    </div>
  );
};

export default Payitang;
