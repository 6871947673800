import React, { useEffect, useRef, useState } from 'react';

import {
  RecordListDisplayType,
  ScrollEventEmitter,
  TreatLogSummary,
  TreatType,
} from './types';
import styles from './index.module.scss';
import CourseRecord from './recordDetails/CourseRecord';
import { Button } from '@blueprintjs/core';
import { formatTimestamp } from '../../../utils/time';
import MedicalRecord from './recordDetails/MedicalRecord';

type RecordItemOwnProps = Readonly<{
  treatLogData: TreatLogSummary;
  index: number;
  isLastVisited: boolean;
  updateLastVisitedRecordId: (id: number) => void;
  scrollEventEmitter: ScrollEventEmitter;
  recordListDisplayType: RecordListDisplayType;
  updateRecordListDisplayType: (displayType: RecordListDisplayType) => void;
}>;

export const treatTypeDesc = {
  [TreatType.all]: '全部',
  [TreatType.medicalRecord]: '就诊记录',
  [TreatType.courseRecord]: '病程记录',
};

const RecordItem: React.FC<RecordItemOwnProps> = (props) => {
  const {
    treatLogData: { id, treatType, treatTime, doctorName },
    index,
    updateLastVisitedRecordId,
    isLastVisited,
    scrollEventEmitter,
    recordListDisplayType,
    updateRecordListDisplayType,
  } = props;

  const ifAutomaticallyExpand =
    recordListDisplayType === RecordListDisplayType.BY_CARD;

  const [isExpanded, setIsExpanded] = useState<boolean>(
    ifAutomaticallyExpand && index === 0
  );

  const recordWrapper = useRef<HTMLDivElement>(null);

  const LastVisitedIndicator = (): JSX.Element => (
    <div className={styles.recordDescription__lastVisitedIndicator}>
      <i className="pyr pyrPolygon" />
    </div>
  );

  useEffect(() => {
    scrollEventEmitter.addListener(() => {
      const recordItemElement = recordWrapper.current;

      if (
        ifAutomaticallyExpand &&
        recordItemElement !== null &&
        !!recordItemElement.parentElement
      ) {
        const topDistance =
          recordItemElement.offsetTop -
          recordItemElement.parentElement.offsetTop -
          recordItemElement.parentElement.scrollTop;

        if (
          window.innerHeight - topDistance > 0 &&
          window.innerHeight - topDistance < 200
        ) {
          setIsExpanded(true);
        }
      }
    });

    if (ifAutomaticallyExpand && index === 0) {
      updateLastVisitedRecordId(id);
    }
  }, []);

  return (
    <div className={styles.recordWrapper} ref={recordWrapper}>
      <Button
        minimal
        className={styles.recordDescription}
        onClick={() => {
          if (
            recordListDisplayType !== RecordListDisplayType.BY_CARD &&
            isExpanded
          ) {
          } else {
            setIsExpanded(!isExpanded);
          }

          updateRecordListDisplayType(RecordListDisplayType.BY_CARD);
          updateLastVisitedRecordId(id);
        }}
      >
        {isLastVisited && <LastVisitedIndicator />}
        <span>{treatTypeDesc[treatType]}</span>
        <span style={{ fontWeight: 700 }}>·</span>
        <span>{formatTimestamp(treatTime)}</span>
        {doctorName !== null ? (
          <>
            <span>｜</span>
            <span>{doctorName} 医生</span>
          </>
        ) : (
          <></>
        )}
      </Button>
      {treatType === TreatType.courseRecord && (
        <CourseRecord
          treatLogId={id}
          treatLogSummary={props.treatLogData}
          isExpanded={
            isExpanded &&
            recordListDisplayType === RecordListDisplayType.BY_CARD
          }
        />
      )}
      {treatType === TreatType.medicalRecord && (
        <MedicalRecord
          treatLogSummary={props.treatLogData}
          treatLogId={id}
          isExpanded={
            isExpanded &&
            recordListDisplayType === RecordListDisplayType.BY_CARD
          }
        />
      )}
    </div>
  );
};

export default RecordItem;
