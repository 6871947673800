import React from 'react';

import styles from './index.module.scss';

const CollectsView: React.FC<{}> = (props) => {
  return (
    <div className={`${styles.listGroup} ${styles.listGroup__collects}`}>
      <p className={styles.listGroup__title}>收藏</p>
      <button>锦囊</button>
      <button>
        <span>我的提问</span>
        <div className={styles.listGroup__collects__unreadBtn}></div>
      </button>
      <button>我的围观</button>
    </div>
  );
};

export default CollectsView;
