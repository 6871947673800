import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import classNames from 'classnames';

import requests from '../../../../../utils/requests';
import { apiConfig } from '../../../../../constants/apiConfig';
import { loadUserTokenFromLocal } from '../../../../../utils/auth';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../../../commonTypes';
import { ReportRow, RelationReport, relationReportTitles } from './types';
import { warningToast } from '../../../../../utils/toaster';
import PyrSpinner from '../../../../common/PyrSpinner';
import styles from './index.module.scss';
import NMSSReportTable from './reportTables/NMSS';
import SchwabEnglandReportTable from './reportTables/SchwabEngland';
import QueenSquareReportTable from './reportTables/QueenSquare';
import AutonomicNerveReportTable from './reportTables/AutonomicNerve';
import MAESReportTable from './reportTables/MAES';
import REMReportTable from './reportTables/REM';
import PDSSReportTable from './reportTables/PDSS';
import { Button } from '@blueprintjs/core';
import { usePatientDetailsContext } from '../../../context';
import { formatTimestamp } from '../../../../../utils/time';

const relationReportTypes = {
  [RelationReport.SchwabEngland]: 1,
  [RelationReport.NMSS]: 2,
  [RelationReport.QueenSquare]: 3,
  [RelationReport.AutonomicNerve]: 4,
  [RelationReport.MAES]: 5,
  [RelationReport.PDSS]: 6,
  [RelationReport.REM]: 7,
};

const defaultReportData: ReportRow[] = [];

type ReportViewOwnProps = Readonly<{
  treatLogId: number;
  patientId: number;
  relationReport: RelationReport;
  updateSelectedReport: (report: RelationReport) => void;
  finishTime: number | null;
}>;

const ReportView: React.FC<ReportViewOwnProps> = (props) => {
  const {
    treatLogId,
    patientId,
    relationReport,
    updateSelectedReport,
    finishTime,
  } = props;

  const patientDetails = usePatientDetailsContext();

  const [reportData, updateReportData] = useState<ReportRow[]>(
    defaultReportData
  );

  const [isReportReqLoading, setReportReqLoading] = useState<boolean>(false);

  useEffect(() => {
    const retrieveReport = async () => {
      const tokenRes = loadUserTokenFromLocal();

      if (!tokenRes.success) {
        return;
      }

      setReportReqLoading(true);
      updateReportData(defaultReportData);

      const res: AxiosResponse<CommonHTTPResponse<{
        object: ReportRow[];
      }>> = await requests.get(
        `${apiConfig.GET_RELATION_LIST}/${
          relationReportTypes[relationReport]
        }/getRelationList?${qs.stringify({
          treatLogId,
          patientId,
        })}`,
        {
          headers: { token: tokenRes.token },
        }
      );

      if (!res.data.success) {
        warningToast(res.data.message);
      } else {
        updateReportData(res.data.object);
      }

      setReportReqLoading(false);
    };

    retrieveReport();
  }, [treatLogId, patientId, relationReport]);

  const generateReportSelectBtn = (
    report: RelationReport,
    title: string,
    subtitle: string
  ): JSX.Element => {
    return (
      <Button
        minimal
        onClick={() => {
          updateReportData(defaultReportData);
          updateSelectedReport(report);
        }}
        className={classNames({
          [styles.reportSelectBtn]: true,
          [styles['reportSelectBtn--selected']]: relationReport === report,
        })}
      >
        <p>{title}</p>
        <p>{subtitle}</p>
      </Button>
    );
  };

  return (
    <>
      <h1 className={styles.header}>
        {relationReportTitles[relationReport].title}
        {relationReportTitles[relationReport].subTitle.length > 0
          ? `（
        ${relationReportTitles[relationReport].subTitle}）`
          : ''}
      </h1>
      <div className={styles.reportBasicInfo}>
        <div>
          <span>患者：</span>
          <span>{patientDetails?.userName}</span>
        </div>
        <div>
          <span>年龄：</span>
          <span>{patientDetails?.age}</span>
        </div>
        <div>
          <span>完成日期：</span>
          <span>
            {/* TODO: 下面的引用参数不正确，需要更改引用参数 */}
            {typeof finishTime === 'number'
              ? formatTimestamp(`${finishTime}`)
              : '未完成'}
          </span>
        </div>
      </div>
      <div className={styles.reportWrapper}>
        <div className={styles.reportInnerWrapper}>
          {isReportReqLoading ? (
            <PyrSpinner />
          ) : reportData[0] ? (
            generateTable(relationReport, reportData)
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.reportTypeList}>
        {generateReportSelectBtn(
          RelationReport.SchwabEngland,
          '生活能力自评量表',
          'Schwab-England'
        )}
        {generateReportSelectBtn(
          RelationReport.NMSS,
          '帕金森非运动症状评价量表',
          'NMSS'
        )}
        {generateReportSelectBtn(
          RelationReport.QueenSquare,
          '视幻觉清单',
          'Queen Square'
        )}
        {generateReportSelectBtn(
          RelationReport.AutonomicNerve,
          '自主神经量表',
          ''
        )}
        {generateReportSelectBtn(RelationReport.MAES, '改良淡漠量表', 'MAES')}
        {generateReportSelectBtn(RelationReport.PDSS, '睡眠量表', 'PDSS')}
        {generateReportSelectBtn(RelationReport.REM, 'REM行为障碍筛查', '')}
      </div>
    </>
  );
};

function generateTable(
  relationReport: RelationReport,
  reportData: ReportRow[]
): JSX.Element {
  return {
    [RelationReport.SchwabEngland.toString()]: () => (
      <SchwabEnglandReportTable reportData={reportData} />
    ),
    [RelationReport.NMSS.toString()]: () => (
      <NMSSReportTable reportData={reportData} />
    ),
    [RelationReport.QueenSquare.toString()]: () => (
      <QueenSquareReportTable reportData={reportData} />
    ),
    [RelationReport.AutonomicNerve.toString()]: () => (
      <AutonomicNerveReportTable reportData={reportData} />
    ),
    [RelationReport.MAES.toString()]: () => (
      <MAESReportTable reportData={reportData} />
    ),
    [RelationReport.PDSS.toString()]: () => (
      <PDSSReportTable reportData={reportData} />
    ),
    [RelationReport.REM.toString()]: () => (
      <REMReportTable reportData={reportData} />
    ),
  }[relationReport]();
}

export default ReportView;
