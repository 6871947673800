import { Button, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';

import { PatientDetails } from '../../PatientDetails';
import DateInputValueGroup from './components/DateInputValueGroup';
import LabelValueGroup from './components/LabelValueGroup';
import SelectGroup from './components/SelectGroup';
import styles from './index.module.scss';
import nationalities from './data/nationalities';
import {
  EditingLableValueGroup,
  LabelValueItem,
  LabelValueType,
} from './types';

type BasicsTabProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
}>;

const BasicsTabContent: React.FC<BasicsTabProps> = (props) => {
  const { patientDetails } = props;

  const labelValueList = [
    {
      label: '籍贯',
      key: 'birthPlace',
      value: patientDetails.birthPlace,
    },
    {
      label: '民族',
      key: 'nation',
      value: patientDetails.nation,
      type: LabelValueType.SELECT,
      selectList: nationalities,
    },
    {
      label: '教育程度',
      key: 'education',
      value: patientDetails.education,
      type: LabelValueType.SELECT,
      selectList: [
        '文盲/幼儿园',
        '小学1年级',
        '小学2年级',
        '小学3年级',
        '小学4年级',
        '小学5年级',
        '小学6年级',
        '初中',
        '高中',
        '大专',
        '本科',
        '硕士',
        '博士',
        '不清楚',
      ],
    },

    {
      label: '长期居住地',
      key: 'birthPlace',
      value: patientDetails.birthPlace,
      type: LabelValueType.SELECT,
      selectList: ['农村', '城镇'],
    },
    {
      label: '长期地址',
      key: 'address',
      value: patientDetails.address,
    },
    {
      label: '身份证号',
      key: 'idCard',
      value: patientDetails.idCard,
    },
    {
      label: '联系电话',
      key: 'phone',
      value: patientDetails.phone.toString(),
    },
  ];

  const [
    editingValue,
    updateEditingValue,
  ] = useState<EditingLableValueGroup | null>(null);

  const labelItemViewMap: {
    [index: string]: (labelValueItem: LabelValueItem) => JSX.Element;
  } = {
    [LabelValueType.DATE_INPUT]: (labelValueItem: LabelValueItem) => (
      <DateInputValueGroup
        key={labelValueItem.label}
        labelKey={labelValueItem.key}
        label={labelValueItem.label}
        // @ts-ignore
        value={labelValueItem.value}
        onChange={(payload) => {
          if (payload === null) {
            updateEditingValue(null);
            return;
          }

          if (payload.labelKey === undefined) {
            return;
          }

          const { newValue, labelKey } = payload;

          updateEditingValue({
            key: labelKey,
            value: newValue,
          });
        }}
      />
    ),

    [LabelValueType.INPUT]: (labelValueItem: LabelValueItem) => (
      <LabelValueGroup
        key={labelValueItem.label}
        labelKey={labelValueItem.key}
        label={labelValueItem.label}
        value={labelValueItem.value}
        onChange={(payload) => {
          if (payload === null) {
            updateEditingValue(null);
            return;
          }

          if (payload.labelKey === undefined) {
            return;
          }

          const { newValue, labelKey } = payload;

          updateEditingValue({
            key: labelKey,
            value: newValue,
          });
        }}
      />
    ),

    [LabelValueType.SELECT]: (labelValueItem: LabelValueItem) => (
      <SelectGroup
        key={labelValueItem.label}
        labelKey={labelValueItem.key}
        label={labelValueItem.label}
        value={labelValueItem.value}
        onChange={(payload) => {
          if (payload === null) {
            updateEditingValue(null);
            return;
          }

          if (payload.labelKey === undefined) {
            return;
          }

          const { newValue, labelKey } = payload;

          updateEditingValue({
            key: labelKey,
            value: newValue,
          });
        }}
        optionList={labelValueItem.selectList ?? []}
      />
    ),
  };

  return (
    <section>
      <div className={styles.basicsInfoWrapper}>
        {labelValueList.map(
          (labelValueItem) =>
            labelItemViewMap[
              labelValueItem.type === undefined
                ? LabelValueType.INPUT
                : labelValueItem.type
            ](labelValueItem)
          // <LabelValueGroup
          //   key={labelValueItem.label}
          //   labelKey={labelValueItem.key}
          //   label={labelValueItem.label}
          //   value={labelValueItem.value}
          //   onChange={(payload) => {
          //     if (payload === null) {
          //       updateEditingValue(null);
          //       return;
          //     }

          //     if (payload.labelKey === undefined) {
          //       return;
          //     }

          //     const { newValue, labelKey } = payload;

          //     updateEditingValue({
          //       key: labelKey,
          //       value: newValue,
          //     });
          //   }}
          // />
        )}
      </div>
      <div className={styles.updateUserInfoBtnWrapper}>
        {editingValue !== null && (
          <Button
            intent={Intent.PRIMARY}
            text="立即更新"
            onClick={() => {
              if (editingValue === null || editingValue.key === undefined) {
                return;
              }

              const uploadPayload: { [index: string]: string | number } = {};
              uploadPayload[editingValue.key] = editingValue.value;

              props.updatePatientInfo(uploadPayload);
            }}
          />
        )}
      </div>
    </section>
  );
};

export default BasicsTabContent;
