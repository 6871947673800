import React from 'react';
import classNames from 'classnames';

import { ReportRow } from '../types';
import styles from './index.module.scss';
import {
  formatTimestamp,
  TimestampFormatPrecision,
} from '../../../../../../utils/time';
import { format } from 'path';

const QueenSquareReportTable: React.FC<{ reportData: ReportRow[] }> = (
  props
) => {
  const { reportData } = props;

  // /**
  //  * 生成选择题条目
  //  * @param content
  //  * @param userAnswer
  //  */
  // const generateCheckableUserAnswer = (userAnswer: string) => {
  //   return (
  //     <div className={styles.queenSquareRow__checkableAnswerGroup}>
  //       {userAnswer === '1' ? (
  //         <Icon icon="tick-circle" />
  //       ) : (
  //         <Icon icon="circle" />
  //       )}
  //     </div>
  //   );
  // };

  /**
   * “是否”选择题
   * @param reportView
   * @param index
   * @param firstindex
   * @param isAnswerShownInNewRow 答案是否另起一行显示
   * @param isEachAnswerShownInDifferentRows 答案是否各占一行
   */
  const generateYesNoRows = (
    reportView: ReportRow,
    index: number,
    firstindex: number,
    areAnswersShownInNewRow: boolean = false,
    isEachAnswerShownInDifferentRows: boolean = false
  ) => {
    const answerList = (
      <div className={styles.queenSquareRow__rowAnswerGroupWrapper}>
        {reportView.relationAnswerList[0].content
          .split(',')
          .map((choice: string, index: number) => (
            <div
              className={classNames({
                [styles.queenSquareRow__rowAnswerGroup]: true,
                [styles['queenSquareRow__rowAnswerGroup--selected']]:
                  reportView.relationAnswerList[0].userAnswer ===
                  (index + 1).toString(),
              })}
              key={choice}
            >
              <span>{choice}</span>
              {/* {generateCheckableUserAnswer(relationAnswer.userAnswer)} */}
            </div>
          ))}
      </div>
    );

    return (
      <div
        className={
          areAnswersShownInNewRow
            ? styles.queenSquareColumn
            : styles.queenSquareRow
        }
        key={reportView.id}
      >
        <span>{index + firstindex}、</span>
        <span>{reportView.content}</span>

        {areAnswersShownInNewRow ? (
          isEachAnswerShownInDifferentRows ? (
            answerList
          ) : (
            <div className={styles.queenSquareRow}>{answerList}</div>
          )
        ) : (
          answerList
        )}
        {/* <Icon icon="tick-circle" /> */}
      </div>
    );
  };

  /**
   * 生成第 6 题
   */
  const generateRowSix = () => {
    const firstRow = reportData[5];
    const secondRow = reportData[6];

    return (
      <>
        <div className={styles.queenSquareRow}>
          {/* 第六题 第一行 */}
          <span>6、</span>
          {/* 描述 */}
          <span>{firstRow.content}</span>

          {/* 作答 */}
          <div className={styles.queenSquareRow__rowAnswerGroupWrapper}>
            <div
              className={classNames({
                [styles.queenSquareRow__rowAnswerGroup]: true,
                [styles['queenSquareRow__rowAnswerGroup--selected']]:
                  firstRow.relationAnswerList[0].userAnswer === '1',
              })}
              key={firstRow.relationAnswerList[0].id}
            >
              <span>
                {firstRow.relationAnswerList[0].content.split(',')[0]}
              </span>
            </div>

            <div
              className={classNames({
                [styles.queenSquareRow__rowAnswerGroup]: true,
                [styles['queenSquareRow__rowAnswerGroup--selected']]:
                  firstRow.relationAnswerList[0].userAnswer === '2',
              })}
              key={firstRow.relationAnswerList[0].id}
            >
              <span>
                {firstRow.relationAnswerList[0].content.split(',')[1]}
              </span>
            </div>
          </div>
          {/* <Icon icon="tick-circle" /> */}
        </div>

        {/* 第六题 第二行 */}
        <div className={styles.queenSquareRow} style={{ marginLeft: 23 }}>
          {/* 描述 */}
          <span>{secondRow.content}</span>

          {/* <Icon icon="tick-circle" /> */}
        </div>

        {/* 作答 */}
        <div className={styles.queenSquareRow__rowAnswerGroupWrapper}>
          <div
            className={classNames({
              [styles.queenSquareRow__rowAnswerGroup]: true,
              [styles['queenSquareRow__rowAnswerGroup--selected']]:
                secondRow.relationAnswerList[0].userAnswer === '1',
            })}
            key={secondRow.relationAnswerList[0].id}
          >
            <span>{secondRow.relationAnswerList[0].content.split(',')[0]}</span>
          </div>

          <div
            className={classNames({
              [styles.queenSquareRow__rowAnswerGroup]: true,
              [styles['queenSquareRow__rowAnswerGroup--selected']]:
                secondRow.relationAnswerList[0].userAnswer === '2',
            })}
            // key={secondRow.relationAnswerList[1].id}
          >
            <span>{secondRow.relationAnswerList[0].content.split(',')[1]}</span>
          </div>
        </div>
      </>
    );
  };

  /**
   * 生成第 12 题
   */
  const generateRow12 = () => {
    const q = reportData[12];
    const answer1 = reportData[13];
    const answer2 = reportData[14];
    const answer3 = reportData[15];

    return (
      <div className={styles.queenSquareColumn}>
        <span>12、</span>
        {/* 描述 */}
        <span>{q.content}</span>

        {/* 作答 */}
        <div className={styles.queenSquareRow__rowAnswerGroup}>
          <span>{answer1.content}</span>
          <p className={styles.queenSquareRow__textAnswer}>
            {answer1.relationAnswerList[0].userAnswer &&
              formatTimestamp(answer1.relationAnswerList[0].userAnswer, {
                formatPrecision: TimestampFormatPrecision.YEAR,
              })}
          </p>
        </div>

        <div className={styles.queenSquareRow__rowAnswerGroup}>
          <span>{answer2.content}</span>

          {answer2.relationAnswerList[0].userAnswer && (
            <p className={styles.queenSquareRow__textAnswer}>
              {formatTimestamp(answer2.relationAnswerList[0].userAnswer)}
            </p>
          )}
        </div>

        {/* <Icon icon="tick-circle" /> */}
      </div>
    );
  };

  return (
    <section className={styles.queenSquare}>
      {/* 1 - 5 “是否” 题 */}
      {reportData
        .slice(0, 5)
        .map((reportView, index) => generateYesNoRows(reportView, index, 1))}

      {/* 第 6 题 */}
      {generateRowSix()}

      {/* 7 - 10 “是否” 题
      注意第六题占了两条数据，所以第七题之后的题目的 index 从7开始算
      而不是从 6开始算 */}
      {reportData
        .slice(7, 11)
        .map((reportView, index) => generateYesNoRows(reportView, index, 7))}

      <p className={styles.queenSquare__description}>
        如果上述问题中任何一个答“是”，请回答问题11-13，
      </p>

      {/* 第 11 题 */}
      {generateYesNoRows(reportData[11], 0, 11, true)}

      {/* 第 12 题 */}
      {generateRow12()}

      {/* 第 13 题 */}
      {generateYesNoRows(reportData[16], 0, 13, true, true)}
    </section>
  );
};

export default QueenSquareReportTable;
