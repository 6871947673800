import queryString from 'query-string';

import { Dispatch, GlobalState, ThunkFunction } from '../store/configureStore';
import { apiConfig } from '../constants/apiConfig';
import { CommonHTTPResponse } from '../commonTypes';
import { AxiosResponse } from 'axios';
import { PatientItem } from '../reducers/patients';
import requests from '../utils/requests';

export const RETRIEVE_PATIENT_LIST = 'RETRIEVE_PATIENT_LIST';
export const UPDATE_PATIENT_LIST = 'UPDATE_PATIENT_LIST';

// 更新文章 model
export type UpdatePatientListPayload = Readonly<{
  list?: PatientItem[];
  isLoading?: boolean;
  page?: number;
  total?: number;
  pageSize?: number;
  hasBeenToTheEnd?: boolean;
}>;

export type UpdatePatientListAction = Readonly<{
  type: 'UPDATE_PATIENT_LIST';
  payload: UpdatePatientListPayload;
}>;

export const updatePatientList = (
  payload: UpdatePatientListPayload
): UpdatePatientListAction => ({
  type: UPDATE_PATIENT_LIST,
  payload,
});

// 获取患者列表
export type RetrievePatientListAction = Readonly<{
  type: 'RETRIEVE_PATIENT_LIST';
}>;

export type PatientListResponse = Readonly<
  CommonHTTPResponse<{
    page: {
      list: PatientItem[];
      lastPage: 1 | 0;
      firstPage: 1 | 0;
      hasNextPage: boolean;
      total: number;
      size: number;
      pageNum: number;
      pageSize: number;
    };
  }>
>;

/**
 * 获取患者列表的方法
 * @param ifGetFromFirstPage 是否从第一页开始获取数据
 */
export const retrievePatientList = (
  ifGetFromFirstPage: boolean = false,
  pageSize?: number
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, patients } = state;
    if (auth.token === null) return;
    dispatch(
      updatePatientList({
        isLoading: true,
        list: ifGetFromFirstPage ? [] : patients.list,
        hasBeenToTheEnd: false,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : patients.page + 1;

    const res: AxiosResponse<PatientListResponse> = await requests.post(
      `${apiConfig.GET_PATIENT_LIST}?${queryString.stringify({
        page: newPage,
        pageSize: typeof pageSize === 'number' ? pageSize : patients.pageSize,
      })}`,
      {},
      {
        headers: { token: auth.token },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updatePatientList({
          list: ifGetFromFirstPage
            ? resData.page.list
            : [...patients.list, ...resData.page.list],
          page: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );
    } else {
      updatePatientList({
        isLoading: false,
      });
    }
  };
};

/**
 * 根据标签labelId获取该医生下的患者列表
 * @param ifGetFromFirstPage 是否从第一页开始获取数据
 */
export const retrievePatientListByLabelId = (
  ifGetFromFirstPage: boolean = false,
  labelId: number
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, patients } = state;
    if (auth.token === null) return;
    dispatch(
      updatePatientList({
        isLoading: true,
        list: ifGetFromFirstPage ? [] : patients.list,
        hasBeenToTheEnd: false,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : patients.page + 1;

    const res: AxiosResponse<PatientListResponse> = await requests.post(
      `${apiConfig.GET_PATIENT_LIST_BY_LABEL_ID}?${queryString.stringify({
        page: newPage,
        pageSize: patients.pageSize,
        labelId,
      })}`,
      {},
      {
        headers: { token: auth.token },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updatePatientList({
          list: ifGetFromFirstPage
            ? resData.page.list
            : [...patients.list, ...resData.page.list],
          page: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );
    } else {
      updatePatientList({
        isLoading: false,
      });
    }
  };
};

/**
 * 根据团队id获取团队下的患者列表信息
 * （不包括当前医生患者）
 * @param ifGetFromFirstPage 是否从第一页开始获取数据
 */
export const retrievePatientListByGroupId = (
  ifGetFromFirstPage: boolean = false,
  groupId: number
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, patients } = state;
    if (auth.token === null) return;
    dispatch(
      updatePatientList({
        isLoading: true,
        list: ifGetFromFirstPage ? [] : patients.list,
        hasBeenToTheEnd: false,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : patients.page + 1;

    const res: AxiosResponse<
      CommonHTTPResponse<{
        data: PatientItem[];
      }>
    > = await requests.post(
      `${apiConfig.GET_PATIENT_LIST_BY_GROUP_ID}?${queryString.stringify({
        page: newPage,
        pageSize: patients.pageSize,
        groupId,
      })}`,
      {},
      {
        headers: { token: auth.token },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updatePatientList({
          list: ifGetFromFirstPage
            ? resData.data
            : [...patients.list, ...resData.data],
          page: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.data.length < patients.pageSize,
        })
      );
    } else {
      updatePatientList({
        isLoading: false,
      });
    }
  };
};

/**
 * 获取患者分组中的患者列表
 * @param ifGetFromFirstPage 是否从第一页开始获取数据
 */
export const retrievePatientListByLabelGroupId = (
  ifGetFromFirstPage: boolean = false,
  groupId: number
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, patients } = state;
    if (auth.token === null) return;
    dispatch(
      updatePatientList({
        isLoading: true,
        list: ifGetFromFirstPage ? [] : patients.list,
        hasBeenToTheEnd: false,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : patients.page + 1;

    const res: AxiosResponse<PatientListResponse> = await requests.post(
      `${apiConfig.GET_PATIENT_LIST_BY_GID}?${queryString.stringify({
        page: newPage,
        pageSize: patients.pageSize,
        gId: groupId,
      })}`,
      {},
      {
        headers: { token: auth.token },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updatePatientList({
          list: ifGetFromFirstPage
            ? resData.page.list
            : [...patients.list, ...resData.page.list],
          page: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );
    } else {
      updatePatientList({
        isLoading: false,
      });
    }
  };
};

/**
 * 获取医生下的患者列表信息
 * @param ifGetFromFirstPage 是否从第一页开始获取数据
 */
export const retrievePatientListByDoctor = (
  ifGetFromFirstPage: boolean = false
): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const state = getState();

    const { auth, patients } = state;
    if (auth.token === null) return;
    dispatch(
      updatePatientList({
        isLoading: true,
        list: ifGetFromFirstPage ? [] : patients.list,
        hasBeenToTheEnd: false,
      })
    );

    const newPage = ifGetFromFirstPage ? 1 : patients.page + 1;

    const res: AxiosResponse<PatientListResponse> = await requests.post(
      `${apiConfig.GET_PATIENT_LIST_BY_DOCTOR}?${queryString.stringify({
        page: newPage,
        pageSize: patients.pageSize,
      })}`,
      {},
      {
        headers: { token: auth.token },
      }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updatePatientList({
          list: ifGetFromFirstPage
            ? resData.page.list
            : [...patients.list, ...resData.page.list],
          page: newPage,
          isLoading: false,
          hasBeenToTheEnd: resData.page.lastPage === 1,
        })
      );
    } else {
      updatePatientList({
        isLoading: false,
      });
    }
  };
};
