import { Dirent } from 'fs';

export const GET_FOLDER_CONTENT = 'GET_FOLDER_CONTENT';
export const UPDATE_FOLDER_URI = 'UPDATE_FOLDER_URI';
export const UPDATE_FOLDER_CONTENT = 'UPDATE_FOLDER_CONTENT';

export const getFolderContent = () => ({
  type: GET_FOLDER_CONTENT,
});

export type UpdateFolderURIAction = Readonly<{
  type: 'UPDATE_FOLDER_URI';
  payload: string;
}>;

export const updateFolderURI = (val: string): UpdateFolderURIAction => ({
  type: UPDATE_FOLDER_URI,
  payload: val,
});

export type UpdateFolderContentPayload = Readonly<{
  isLoading?: boolean;
  list?: Dirent[];
  selectedFileNames?: string[];
}>;

export type UpdateFolderContentAction = Readonly<{
  type: 'UPDATE_FOLDER_CONTENT';
  payload: UpdateFolderContentPayload;
}>;

export const updateFolderContent = (
  payload: UpdateFolderContentPayload
): UpdateFolderContentAction => ({
  type: UPDATE_FOLDER_CONTENT,
  payload,
});
