// 健康信息浮窗内容
import React, { useState } from 'react';
import { Tabs, Tab } from '@blueprintjs/core';

import { PatientDetails } from '../PatientDetails';
import styles from './index.module.scss';
import DiagnosisTabContent from './healthInfoTabs/Diagnosis';
import BasicsTabContent from './healthInfoTabs/Basics';
import HealthExpTabContent from './healthInfoTabs/HealthExp';
import SocialExpTabContent from './healthInfoTabs/SocialExp';
import RiskFactors from './healthInfoTabs/RiskFactors';
import FamilyExp from './healthInfoTabs/FamilyExp';
import SmokeExp from './healthInfoTabs/SmokeExp';
import { apiConfig } from '../../../constants/apiConfig';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import requests from '../../../utils/requests';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { warningToast, successToast } from '../../../utils/toaster';

type HealthInfoPopoverContentProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientDetails: (payload: PatientDetails) => void;
  onClose: () => void;
}>;

enum HealthInfoTab {
  diagnosis = '诊断',
  basics = '基本',
  healthExp = '健康史',
  socialExp = '社交史',
  smokeExp = '吸烟史',
  riskFactors = '危险因素',
  familyExp = '家族史',
}

const HealthInfoPopoverContent: React.FC<HealthInfoPopoverContentProps> = (
  props
) => {
  const { patientDetails, updatePatientDetails } = props;

  const [isUpdatePatientLoading, setUpdatePatientLoading] = useState<boolean>(
    false
  );

  const [selectedTab, selectTab] = useState<HealthInfoTab>(
    HealthInfoTab.diagnosis
  );

  const updatePatientInfo = async (payload: Object) => {
    const userTokenRes = loadUserTokenFromLocal();
    if (!userTokenRes.success) {
      return;
    }
    setUpdatePatientLoading(true);

    const newData = {
      ...patientDetails,
      ...payload,
      patientId: patientDetails.id,
    };

    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.UPDATE_PATIENT_INFO}`,
      newData,
      { headers: { token: userTokenRes.token } }
    );

    if (!res.data.success) {
      warningToast(res.data.message);
    } else {
      successToast('完成用户信息更新');
      updatePatientDetails(newData);
    }

    setUpdatePatientLoading(false);
  };

  return (
    <section className={styles.healthInfoWrapper}>
      <div className={styles.healthInfoHeader}>
        <Tabs
          className={styles.healthInfoTabs}
          selectedTabId={selectedTab}
          onChange={(newTabValue: HealthInfoTab) => {
            selectTab(newTabValue);
          }}
        >
          {Object.entries(HealthInfoTab).map(([key, value]) => (
            <Tab key={key} id={value} title={value} />
          ))}
        </Tabs>
        <button className={styles.healthInfoFoldBtn} onClick={props.onClose}>
          <i className="pyr pyrfold" />
          <span>收起</span>
        </button>
      </div>
      <div>
        {selectedTab === HealthInfoTab.diagnosis && (
          <DiagnosisTabContent
            patientDetails={props.patientDetails}
            updatePatientInfo={updatePatientInfo}
          />
        )}
        {selectedTab === HealthInfoTab.basics && (
          <BasicsTabContent
            patientDetails={props.patientDetails}
            updatePatientInfo={updatePatientInfo}
          />
        )}
        {selectedTab === HealthInfoTab.healthExp && (
          <HealthExpTabContent
            patientDetails={props.patientDetails}
            isUpdatePatientLoading={isUpdatePatientLoading}
            updatePatientInfo={updatePatientInfo}
          />
        )}
        {selectedTab === HealthInfoTab.socialExp && (
          <SocialExpTabContent
            patientDetails={props.patientDetails}
            isUpdatePatientLoading={isUpdatePatientLoading}
            updatePatientInfo={updatePatientInfo}
          />
        )}
        {selectedTab === HealthInfoTab.smokeExp && (
          <SmokeExp
            patientDetails={props.patientDetails}
            updatePatientInfo={updatePatientInfo}
            isUpdatePatientLoading={isUpdatePatientLoading}
          />
        )}
        {selectedTab === HealthInfoTab.riskFactors && (
          <RiskFactors
            patientDetails={props.patientDetails}
            isUpdatePatientLoading={isUpdatePatientLoading}
            updatePatientInfo={updatePatientInfo}
          />
        )}
        {selectedTab === HealthInfoTab.familyExp && (
          <FamilyExp
            patientDetails={props.patientDetails}
            updatePatientInfo={updatePatientInfo}
            isUpdatePatientLoading={isUpdatePatientLoading}
          />
        )}
      </div>
    </section>
  );
};

export default HealthInfoPopoverContent;
