import {
  Button,
  InputGroup,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import React, { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

import styles from '../index.module.scss';

const OthersEditPopover: React.FC<{
  customizedList: string[];
  updateCustomizedList: (newList: string[]) => void;
}> = (props) => {
  const { customizedList, updateCustomizedList } = props;

  // 添加自定义药物输入框
  const [isAddMedicineInputActive, setAddMedicineInputActive] = useState<
    boolean
  >(false);

  // 自定义药物编辑名称
  const [
    editingCustomizedMedicineName,
    updateEditingCustomizedMedicineName,
  ] = useState<string>('');

  return (
    <Popover
      className={styles.otherBtnPopover}
      // isOpen={true}
      position={Position.RIGHT}
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <button className={styles.othersBtn}>其它</button>

      <div className={styles.othersPopContent}>
        {customizedList.map((item) => (
          <div key={uuidV4()}>
            <span>{item}</span>
            <button
              onClick={() => {
                const newCustomizedMedicineList = customizedList.filter(
                  (medicine) => medicine !== item
                );
                setAddMedicineInputActive(false);

                updateEditingCustomizedMedicineName('');

                updateCustomizedList(newCustomizedMedicineList);
              }}
            >
              <i className="pyr pyrtag_delete_button" />
            </button>
          </div>
        ))}
        {isAddMedicineInputActive ? (
          <form
            className={styles.othersEditingGroup}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();

              const newCustomizedMedicineList = [
                ...customizedList,
                editingCustomizedMedicineName,
              ];

              updateEditingCustomizedMedicineName('');
              setAddMedicineInputActive(false);

              updateCustomizedList(newCustomizedMedicineList);
            }}
          >
            <InputGroup
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateEditingCustomizedMedicineName(event.target.value);
              }}
              rightElement={<Button minimal text="添加" type="submit" />}
            />
          </form>
        ) : (
          <button
            onClick={() => {
              setAddMedicineInputActive(true);
            }}
          >
            <i className="pyr pyrtag_add_button" />
          </button>
        )}
      </div>
    </Popover>
  );
};

export default OthersEditPopover;
