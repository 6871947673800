// 症状
export type Symptom = Readonly<{
  content: string;
  displayLevel: number;
  displayType: number;
  doctorQuestDTOList: Readonly<
    | {
        content: string;
        displayLevel: 1;
        displayType: number;
        doctorQuestDTOList: null;
        id: number;
        sort: number;
        style: number;
        userAnswerContent: string;
      }[]
    | null
  >;
  id: number;
  sort: number;
  userAnswerContent: string | null;
  style: number;
}>;

/**
 * 用药列表 类型定义
 */
export type MedicineListState = Readonly<{
  isLoading: boolean;
  data: MedicineItem[];
}>;

type MedicineItem = Readonly<{
  createTime: 1599753562000;
  detail: {
    time: string;
    amountDescription: string;
  }[];
  id: number;
  name: string;
  note: string;
  sort: number;
  specifications: string;
  treatLogId: 124;
}>;

/**
 * 默认用药列表状态
 */
export const defaultMedicineListState: MedicineListState = {
  isLoading: false,
  data: [],
};

export type MedicineItemInResponse = Readonly<{
  createTime: 1599753562000;
  detail: string;
  id: number;
  name: string;
  note: string;
  sort: number;
  specifications: string;
  treatLogId: 124;
}>;

/**
 * 动作视频列表 类型定义
 */
export type ActionVideoListState = Readonly<{
  isLoading: boolean;
  data: ActionVideoItem[];
}>;

export type ActionVideoItem = Readonly<{
  academicTitle: string | null;
  avatar: string | null;
  createTime: number;
  detail: any;
  doctorName: string | null;
  hospitalName: string | null;
  id: number;
  img: string;
  imgType: number;
  remark: string;
  resultType: number;
  treatLogid: number;
  treatLogResultId: number | null;
}>;

/**
 * 默认动作视频列表状态
 */
export const defaultActionVideoListState: ActionVideoListState = {
  isLoading: false,
  data: [],
};

export const imgTypesNames: (string | null)[] = [null, '拍打动作', '手运动', '行走'];
