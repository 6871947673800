import React, { useEffect, useState } from 'react';

import FakeSvgPlaceholder from '../../assets/images/tempSvg/message.svg';
import PyrSpinner from '../common/PyrSpinner';
import styles from './index.module.scss';

const Messages: React.FC<{}> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500 + 500 * Math.random());
  }, []);

  return (
    <div className={styles.wrapper}>
      {isLoading ? <PyrSpinner /> : <img src={FakeSvgPlaceholder} alt="" />}
    </div>
  );
};

export default Messages;
