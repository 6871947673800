import {
  UserInfoData,
  PatientGroup,
  PatientLabel,
  DoctorGroup,
  DoctorGroupForPatientList,
} from '../reducers/userInfo';
import { Dispatch, GlobalState, ThunkFunction } from '../store/configureStore';
import { apiConfig } from '../constants/apiConfig';
import { CommonHTTPResponse } from '../commonTypes';
import { AxiosResponse } from 'axios';
import requests from '../utils/requests';

// 更新用户（医生）信息
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export type UpdateUserInfoPayload = Readonly<{
  isLoading?: boolean;
  data?: UserInfoData;
}>;

export type UpdateUserInfoAction = Readonly<{
  type: 'UPDATE_USER_INFO';
  payload: UpdateUserInfoPayload;
}>;

export const updateUserInfo = (
  payload: UpdateUserInfoPayload
): UpdateUserInfoAction => ({
  type: UPDATE_USER_INFO,
  payload,
});

type UserInfoRequestRes = Readonly<
  CommonHTTPResponse<{
    data: UserInfoData;
  }>
>;

// 更新医生名下患者分组
export const UPDATE_DOCTOR_PATIENT_GROUP_LIST =
  'UPDATE_DOCTOR_PATIENT_GROUP_LIST';

export type UpdateDoctorPatientGroupListPayload = Readonly<{
  isLoading?: boolean;
  data?: PatientGroup[];
}>;

export type UpdateDoctorPatientGroupListAction = Readonly<{
  type: 'UPDATE_DOCTOR_PATIENT_GROUP_LIST';
  payload: UpdateDoctorPatientGroupListPayload;
}>;

export const updateDoctorPatientGroupList = (
  payload: UpdateDoctorPatientGroupListPayload
): UpdateDoctorPatientGroupListAction => ({
  type: UPDATE_DOCTOR_PATIENT_GROUP_LIST,
  payload,
});

// 更新医生加入的团队列表
export const UPDATE_DOCTOR_GROUP_LIST = 'UPDATE_DOCTOR_GROUP_LIST';

export type UpdateDoctorGroupListPayload = Readonly<{
  isLoading?: boolean;
  data?: any[];
}>;

export type UpdateDoctorGroupListAction = Readonly<{
  type: 'UPDATE_DOCTOR_GROUP_LIST';
  payload: UpdateDoctorGroupListPayload;
}>;

export const updateDoctorGroupList = (
  payload: UpdateDoctorGroupListPayload
): UpdateDoctorGroupListAction => ({
  type: UPDATE_DOCTOR_GROUP_LIST,
  payload,
});

// 更新医生加入的（患者列表向）团队列表
export const UPDATE_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST =
  'UPDATE_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST';

export type UpdateDoctorGroupListForPatientListPayload = Readonly<{
  isLoading?: boolean;
  data?: any[];
}>;

export type UpdateDoctorGroupListForPatientListAction = Readonly<{
  type: 'UPDATE_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST';
  payload: UpdateDoctorGroupListForPatientListPayload;
}>;

export const updateDoctorGroupListForPatientList = (
  payload: UpdateDoctorGroupListForPatientListPayload
): UpdateDoctorGroupListForPatientListAction => ({
  type: UPDATE_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST,
  payload,
});

// 更新医生名下患者分组
export const UPDATE_LABEL_LIST = 'UPDATE_LABEL_LIST';

export type UpdateLabelListPayload = Readonly<{
  isLoading?: boolean;
  data?: PatientGroup[];
}>;

export type UpdateLabelListAction = Readonly<{
  type: 'UPDATE_LABEL_LIST';
  payload: UpdateDoctorPatientGroupListPayload;
}>;

export const updateLabelList = (
  payload: UpdateLabelListPayload
): UpdateLabelListAction => ({
  type: UPDATE_LABEL_LIST,
  payload,
});

// 获取用户（医生）基本信息
export const retrieveUserInfo = (): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const { auth } = getState();

    dispatch(updateUserInfo({ isLoading: true }));

    const res: AxiosResponse<UserInfoRequestRes> = await requests.post(
      apiConfig.GET_DOCTOR_DETAILS,
      {},
      { headers: { token: auth.token } }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(updateUserInfo({ isLoading: false, data: resData.data }));
    }
  };
};

// 获取医生名下的患者分组
export const retrievePatientGroupList = (): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const { auth } = getState();

    dispatch(updateDoctorPatientGroupList({ isLoading: true }));

    const res: AxiosResponse<CommonHTTPResponse<{
      data: PatientGroup[];
    }>> = await requests.post(
      apiConfig.GET_DOCTOR_PATIENT_GROUP_LIST,
      {},
      { headers: { token: auth.token } }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updateDoctorPatientGroupList({ isLoading: false, data: resData.data })
      );
    }
  };
};

// 获取医生的标签分组
export const retrieveLabelList = (): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const { auth } = getState();

    dispatch(updateLabelList({ isLoading: true }));

    const res: AxiosResponse<CommonHTTPResponse<{
      data: PatientLabel[];
    }>> = await requests.post(
      apiConfig.GET_LABLE_LIST,
      {},
      { headers: { token: auth.token } }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(updateLabelList({ isLoading: false, data: resData.data }));
    }
  };
};

// 获取医生团队列表
export const retrieveDoctorGroupList = (): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const { auth } = getState();

    dispatch(updateDoctorGroupList({ isLoading: true }));

    const res: AxiosResponse<CommonHTTPResponse<{
      data: DoctorGroup[];
    }>> = await requests.post(
      apiConfig.GET_DOCTOR_GROUP_LIST,
      {},
      { headers: { token: auth.token } }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(updateDoctorGroupList({ isLoading: false, data: resData.data }));
    }
  };
};

// 获取医生（患者列表向）团队列表
export const retrieveDoctorGroupListForPatientList = (): ThunkFunction => {
  return async (dispatch: Dispatch, getState: () => GlobalState) => {
    const { auth } = getState();

    dispatch(updateDoctorGroupList({ isLoading: true }));

    const res: AxiosResponse<CommonHTTPResponse<{
      data: DoctorGroupForPatientList[];
    }>> = await requests.post(
      apiConfig.GET_DOCTOR_GROUP_LIST,
      {},
      { headers: { token: auth.token } }
    );

    const resData = res.data;

    if (resData.success) {
      dispatch(
        updateDoctorGroupListForPatientList({
          isLoading: false,
          data: resData.data,
        })
      );
    }
  };
};
