import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import commonStyles from '../../assets/commonStyles.module.scss';
import styles from './index.module.scss';
import SharePage from '../Share';
import PrivateRouter from '../../Routes/PrivateRouter';
import Header from '../Header';
import PatientsPage from '../PatientsPage';
import routes from '../../constants/routes.json';
import OverviewPage from '../OverviewPage';
import QNAPage from '../QNAPage';
import { GlobalState } from '../../store/configureStore';
import { QnaState } from '../../reducers/qna';
import logo from '../../assets/images/logo.svg';
import Messages from '../Messages';
import HealthArchives from '../HealthArchives';
import Payitang from '../Payitang';

enum NavbarTabId {
  message = 'message',
  share = 'share',
  qna = 'qna',
  payitang = 'payitang',
  healthArchives = 'healthArchives',
}

// 导航栏下 tabs 的属性，key 为 tabId
const navbarTabs = {
  [NavbarTabId.message]: {
    title: '消息',
    route: routes.MESSAGES,
  },
  [NavbarTabId.share]: {
    title: '锦囊',
    route: routes.SHARE,
  },
  [NavbarTabId.qna]: {
    title: '问答',
    route: routes.QNA,
  },
  [NavbarTabId.payitang]: {
    title: '帕医堂',
    route: routes.PA_YI_TANG,
  },
  [NavbarTabId.healthArchives]: {
    title: '健康档案',
    route: routes.HEALTH_ARCHIVES,
  },
};

type HomeStateProps = Readonly<{
  qnaState: QnaState;
}>;

/**
 * Home组件
 *
 * 包含了 Header，导航栏和导航栏中四个栏目对应的组件路由的配置
 * 四个栏目的内容将会通过路由匹配被渲染在 Home 组件中
 */
const Home: React.FC<RouteComponentProps & HomeStateProps> = (props) => {
  const { history, location } = props;

  // 根据路由获取初始状态的导航栏 tabId
  const getInitialNavbarTabIdFromRoute = (): string => {
    const navbarEntryFromRoute = Object.entries(navbarTabs).find((entry) => {
      const tabValue = entry[1];

      return tabValue.route.split('/')[1] === location.pathname.split('/')[1];
    });

    // 导航栏的 tabId 对应的路由中没有包含 '/'，'/' 被认为等价于路由 '/preview'
    return navbarEntryFromRoute ? navbarEntryFromRoute[0] : NavbarTabId.message;
  };

  const [navbarSelectedTabId, updateNavbarSelectedTabId] = useState<string>(
    getInitialNavbarTabIdFromRoute()
  );

  useEffect(() => {
    const newTabId = location.pathname.split('/')[2];
    if (newTabId !== navbarSelectedTabId) {
      updateNavbarSelectedTabId(newTabId);
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <Header />
      <section className={styles.right}>
        <Link className={styles.right__logo} to={routes.USER}>
          <img src={logo} alt="图标" />
          <i className="pyr pyrpayouran" />
        </Link>

        {/* tabs 导航栏 */}
        <div className={styles.tabsNavbar}>
          <div className={styles.tabs}>
            <Tabs
              selectedTabId={navbarSelectedTabId}
              onChange={(newTabId: NavbarTabId) => {
                updateNavbarSelectedTabId(newTabId);

                history.push(navbarTabs[newTabId].route);
              }}
            >
              {Object.entries(navbarTabs).map(([key, value]) => (
                <Tab
                  id={key}
                  key={key}
                  title={
                    <div className={styles.tabItem}>
                      <span>{value.title}</span>
                      {key === NavbarTabId.qna &&
                        props.qnaState.unreadMsgCount > 0 && (
                          <div className={styles.qnaUnreadIndicator}></div>
                        )}
                    </div>
                  }
                />
              ))}
            </Tabs>
          </div>
        </div>

        {/* 正文，包含根据路由匹配出的所选导航栏栏目的内容 */}
        <div className={styles.body}>
          <PrivateRouter path={routes.MESSAGES} component={Messages} />
          <PrivateRouter
            path={routes.HEALTH_ARCHIVES}
            component={HealthArchives}
          />
           <PrivateRouter
            path={routes.PA_YI_TANG}
            component={Payitang}
          />
          <PrivateRouter path={routes.SHARE} component={SharePage} />
          <PrivateRouter path={routes.QNA} component={QNAPage} />
          <PrivateRouter
            path={`${routes.PATIENTS}/:id?`}
            component={PatientsPage}
          />
          <PrivateRouter path={routes.USER} component={Messages} exact />
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  qnaState: state.qna,
});

export default connect<HomeStateProps, {}, {}, GlobalState>(mapStateToProps)(
  Home
);
