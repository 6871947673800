import { timeStamp } from 'console';

export enum TimestampFormatPrecision {
  DAY,
  MONTH,
  YEAR,
  MINUTE,
}

export type FormatTimeStampOptions = {
  formatPrecision?: TimestampFormatPrecision;
  hideMinutesDisplayWhenNotToday?: boolean;
};

export const formatTimestamp = (
  timestamp: string,
  options?: FormatTimeStampOptions
) => {
  const dateNowObject = new Date();
  const dateObject = new Date(parseInt(timestamp));
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();

  // 默认精度为"天"
  const formatPrecision =
    options && options.formatPrecision
      ? options.formatPrecision
      : TimestampFormatPrecision.DAY;

  if (
    formatPrecision === TimestampFormatPrecision.MINUTE &&
    dateObject.toDateString() === dateNowObject.toDateString()
  ) {
    // 按分钟精度显示且为同一天，则不展示日期
    return getDayDescription(dateObject);
  }

  const yearGap = dateNowObject.getFullYear() - year;

  let yearDescription = getYearDescription(yearGap, year);

  return (
    `${yearDescription}` +
    (formatPrecision === TimestampFormatPrecision.YEAR
      ? ''
      : `${month}月` +
        ([
          TimestampFormatPrecision.DAY,
          TimestampFormatPrecision.MINUTE,
        ].includes(formatPrecision)
          ? `${date}日`
          : '')) +
    (formatPrecision === TimestampFormatPrecision.MINUTE &&
    !options?.hideMinutesDisplayWhenNotToday
      ? ` ${dateObject
          .getHours()
          .toString()
          .padStart(2, '0')}:${dateObject
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : '')
  );
};

export const formatYMDTimeStamp = (timeStamp: string) => {
  const dateObj = new Date(parseInt(timeStamp));

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const date = dateObj.getDate();

  return `${year}-${month + 1}-${date}`;
};

const getDayDescription = (dateObject: Date) => {
  return `${dateObject
    .getHours()
    .toString()
    .padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}`;
};

const getYearDescription = (yearGap: number, year: number) => {
  let yearDescription = `${year}年`;
  if (yearGap < 1) {
    yearDescription = '';
  } else if (yearGap === 1) {
    yearDescription = '去年';
  } else if (yearGap === 2) {
    yearDescription = '前年';
  }

  return yearDescription;
};
