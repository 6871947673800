import React from 'react';
import { Icon } from '@blueprintjs/core';

import { ReportRow } from '../types';
import styles from './index.module.scss';

const PDSSReportTable: React.FC<{ reportData: ReportRow[] }> = (props) => {
  const { reportData } = props;

  return (
    <table className={`${styles.reportTable} ${styles.PDSSTable}`}>
      <thead>
        <th colSpan={5}>问题</th>
        <th colSpan={5}>分数</th>
      </thead>
      <tbody>
        {reportData.map((reportRow, index) => {
          const { relationAnswerList } = reportRow;
          const userAnswer = parseInt(relationAnswerList[0].userAnswer);
          console.log(userAnswer);

          return (
            <>
              <tr key={`${reportRow.id}-row-1`}>
                <td colSpan={5}>{`${index + 1}、${reportRow.content}`}</td>
                <td colSpan={5}>
                  {index === 0 ? '很差---非常好' : '经常---从不'}
                </td>
              </tr>
              <tr key={`${reportRow.id}-row-2`}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
                  console.log(index);
                  console.log(userAnswer === index);
                  return (
                    <td key={index} className={styles.PDSSTdItem}>
                      <span>{`${index}`}</span>
                      {userAnswer === index ? (
                        <Icon icon="small-tick" />
                      ) : (
                        <></>
                      )}
                    </td>
                  );
                })}
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default PDSSReportTable;
