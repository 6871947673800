/**
 * 危险因素的头脑历史列表
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';

import { GlobalState } from '../../../../../store/configureStore';
import { AuthState } from '../../../../../reducers/auth';
import { apiConfig } from '../../../../../constants/apiConfig';
import { CommonHTTPResponse } from '../../../../../commonTypes';
import CheckableProperty from '../components/CheckableProperty';

import styles from './index.module.scss';
import produce from 'immer';
import requests from '../../../../../utils/requests';
import PyrSpinner from '../../../../common/PyrSpinner';

/**
 * 公共头脑历史列表资源请求状态
 */
type BrainHistoryList = Readonly<{
  data: BrainHistoryItem[];
  isLoading: boolean;
}>;

type BrainHistoryItem = Readonly<{
  id: number;
  name: string;
}>;

type BrainHistoryResponse = CommonHTTPResponse<{
  data: BrainHistoryItem[];
}>;

const defaultBrainHistoryListState: BrainHistoryList = {
  data: [
    { id: 1, name: '重大脑外伤史' },
    { id: 2, name: '意识丧失' },
    { id: 3, name: '脑部感染（脑炎）' },
    { id: 4, name: '重金属中毒史' },
    { id: 5, name: '农药/除草剂暴露史' },
    { id: 6, name: '素食主义者' },
  ],
  isLoading: false,
};

/**
 * 组件 props 类型定义
 */
type BrainHistoryListOwnProps = Readonly<{
  BrainHistoryListStrSet: string;
  updateBrainHistoryListStrSet: (value: string) => void;
}>;

type BrainHistoryListStateProps = Readonly<{
  authModel: AuthState;
}>;

const BrainHistoryListView: React.FC<
  BrainHistoryListOwnProps & BrainHistoryListStateProps
> = (props) => {
  const {
    BrainHistoryListStrSet,
    // authModel,
    updateBrainHistoryListStrSet,
  } = props;

  // 公共头脑历史列表
  const [BrainHistoryList, updateBrainHistoryList] = useState<BrainHistoryList>(
    defaultBrainHistoryListState
  );

  // // 获取公共头脑历史列表
  // const retrieveBrainHistoryList = async () => {
  //   updateBrainHistoryList({ isLoading: true, data: [] });

  //   const res: AxiosResponse<BrainHistoryResponse> = await requests.post(
  //     apiConfig.GET_WORKING_EXP_LIST,
  //     {},
  //     { headers: { token: authModel.token } }
  //   );

  //   updateBrainHistoryList({
  //     data: res.data.success ? res.data.data : [],
  //     isLoading: false,
  //   });
  // };

  const defaultCommonUsingBrainHistoryList: string[] = BrainHistoryListStrSet.split(
    '|'
  )[0].split(',');

  const defaultCustomizedUsingBrainHistoryList: string[] =
    typeof BrainHistoryListStrSet.split('|')[1] === 'string'
      ? BrainHistoryListStrSet.split('|')[1].split(',')
      : [];

  // 公共头脑历史
  const [
    commonUsingBrainHistoryList,
    updateCommonUsingBrainHistoryList,
  ] = useState<string[]>(defaultCommonUsingBrainHistoryList);

  // 自定义头脑历史
  const [
    customizedUsingBrainHistoryList,
    // updateCustomizedUsingBrainHistoryList,
  ] = useState<string[]>(defaultCustomizedUsingBrainHistoryList);

  const generatecustomizedUsingBrainHistoryList = (
    commonUsingBrainHistoryList: string[],
    customizedUsingBrainHistoryList: string[]
  ) => {
    return `${commonUsingBrainHistoryList.join(
      ','
    )}|${customizedUsingBrainHistoryList.join(',')}`;
  };

  /**
   *  更改公共工作经验列表的选择状态
   * @param value 工作经验名称
   * @param selected 选择与否
   */
  const updateCommonUsingBrainHistoryListByValue = (
    value: string,
    selected: boolean
  ) => {
    let newCommonUsingBrainHistoryList: string[] = [];

    if (selected) {
      newCommonUsingBrainHistoryList = [...commonUsingBrainHistoryList, value];
    } else {
      const index = commonUsingBrainHistoryList.indexOf(value);

      newCommonUsingBrainHistoryList = produce(
        commonUsingBrainHistoryList,
        (draft) => {
          draft.splice(index, 1);
        }
      );
    }

    updateCommonUsingBrainHistoryList(newCommonUsingBrainHistoryList);

    updateBrainHistoryListStrSet(
      generatecustomizedUsingBrainHistoryList(
        newCommonUsingBrainHistoryList,
        customizedUsingBrainHistoryList
      )
    );
  };

  // useEffect(() => {
  //   retrieveBrainHistoryList();
  // }, []);

  return (
    <section className={styles.BrainHistorySection}>
      {BrainHistoryList.isLoading ? (
        <PyrSpinner />
      ) : (
        BrainHistoryList.data.map((workingExpItem) => (
          <CheckableProperty
            key={workingExpItem.id}
            title={workingExpItem.name}
            checked={commonUsingBrainHistoryList.includes(workingExpItem.name)}
            onChange={(selected) => {
              updateCommonUsingBrainHistoryListByValue(
                workingExpItem.name,
                selected
              );
            }}
          />
        ))
      )}
    </section>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  BrainHistoryListStateProps,
  {},
  BrainHistoryListOwnProps,
  GlobalState
>(mapStateToProps)(BrainHistoryListView);
