import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import styles from './index.module.scss';
import {
  Icon,
  Button,
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';

import { GlobalState, Dispatch } from '../../../store/configureStore';
import { PatientLabel, UserInfoState } from '../../../reducers/userInfo';
import PatientLabelAddButton from './PatientLabelAddButton';
import HealthInfoPopoverContent from './HealthInfoPopoverContent';
import { formatTimestamp } from '../../../utils/time';
import PyrSpinner from '../../common/PyrSpinner';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import requests from '../../../utils/requests';
import { apiConfig } from '../../../constants/apiConfig';
import { successToast, warningToast } from '../../../utils/toaster';
import { PatientDetails } from '../PatientDetails';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { retrieveLabelList } from '../../../actions/userInfo';
import { AuthState } from '../../../reducers/auth';
import routes from '../../../constants/routes.json';
import {
  retrieveAtMineQnaList,
  QnaListResponse,
  RetrieveQuestionListPayload,
  retrieveQuestionList,
  updateSelectedChatRecord,
} from '../../../actions/qna';
import { QnaState } from '../../../reducers/qna';
import { RouteComponentProps, withRouter } from 'react-router';
import { QnaRecord } from '../../../reducers/qna/types';
import RecordItem from '../MedicalHistory/RecordItem';

type PatientDetailsSideBarStateProps = Readonly<{
  authState: AuthState;
  userInfoState: UserInfoState;
  qnaState: QnaState;
}>;

type PatientDetailsSideBarDispatchProps = Readonly<{
  retrieveDoctorLabelList: () => void;

  retrieveAtMineQnaList: (
    ifGetFromFirstPage: boolean,
    callbackAfterListRetrieved: (qnaListResponse: QnaListResponse) => void
  ) => void;

  retrieveQuestionList: (payload: RetrieveQuestionListPayload) => void;

  updateSelectedChatRecord: (payload: {
    record: (QnaRecord & { mainChatId?: number }) | null;
  }) => void;
}>;

type PatientDetailsSideBarOwnProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientDetails: (payload: PatientDetails) => void;
  patientLabelList: PatientLabel[];
  isPatientLabelListLoading: boolean;
  retrieveLabelList: () => void;
}>;

const PatientDetailsSideBar: React.FC<
  PatientDetailsSideBarStateProps &
    PatientDetailsSideBarOwnProps &
    PatientDetailsSideBarDispatchProps &
    RouteComponentProps
> = (props) => {
  const {
    patientDetails: {
      id,
      avatar,
      userName,
      age,
      idCard,
      follow,
      diagnosisTime,
      sex,
      confirmedDisease,
    },
    patientLabelList,
    isPatientLabelListLoading,
    retrieveLabelList,
    retrieveDoctorLabelList,
    updatePatientDetails,
  } = props;

  const [isLabelEditing, setLabelEditing] = useState<boolean>(false);

  const deletingLabelList = useRef<number[]>([]);

  const deleteLabel = async (labelId: number) => {
    if (deletingLabelList.current.includes(labelId)) {
      return;
    }

    const userTokenRes = loadUserTokenFromLocal();
    if (!userTokenRes.success) {
      return;
    }

    deletingLabelList.current = [...deletingLabelList.current, labelId];

    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.DELETE_LABEL}?${queryString.stringify({
        labelId: labelId,
        doctorId: id,
      })}`,
      {},
      { headers: { token: userTokenRes.token } }
    );

    if (res.data.success) {
      successToast('标签已删除');
      retrieveLabelList();
      retrieveDoctorLabelList();
    } else {
      warningToast(res.data.message);
    }

    deletingLabelList.current = deletingLabelList.current.filter(
      (deletingLabelId) => deletingLabelId !== labelId
    );
  };

  const followPatient = async () => {
    if (!props.userInfoState || !props.userInfoState.data) {
      return;
    }
    // 先修改本地数据
    updatePatientDetails({ ...props.patientDetails, follow: 1 });

    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.FOLLOW_PATIENT}?${queryString.stringify({
        doctorId: props.userInfoState.data.id,
        patientId: id,
      })}`,
      {},
      { headers: { token: props.authState.token } }
    );

    if (!res.data.success) {
      warningToast(res.data.message);
      updatePatientDetails({ ...props.patientDetails, follow: 0 });
    }
  };

  const unfollowPatient = async () => {
    if (!props.userInfoState || !props.userInfoState.data) {
      return;
    }
    updatePatientDetails({ ...props.patientDetails, follow: 0 });

    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.UNFOLLOW_PATIENT}?${queryString.stringify({
        patientId: id,
      })}`,
      {},
      { headers: { token: props.authState.token } }
    );

    if (!res.data.success) {
      warningToast(res.data.message);
      updatePatientDetails({ ...props.patientDetails, follow: 1 });
    }
  };

  /**
   * 与病人对话
   */
  const talkWithPatient = async () => {
    if (!props.userInfoState || !props.userInfoState.data) {
      return;
    }

    // getChatAvailability
    const res: AxiosResponse<
      CommonHTTPResponse<{
        data: {
          chat: boolean;
          close: boolean;
        };
      }>
    > = await requests.post(
      `${apiConfig.GET_CHAT_AVAILABILITY}?patientId=${props.patientDetails.patientId}`,
      {},
      {
        headers: { token: props.authState.token },
      }
    );

    if (!res.data.success) {
      warningToast(res.data.message);

      return;
    }

    const resData = res.data.data;

    if (resData.chat) {
      // 会话可以进行

      if (resData.close) {
        // 会话已被关闭，先打开会话
        await requests.get(
          `${apiConfig.OPEN_CHAT}?patientId=${props.patientDetails.patientId}`,
          {
            headers: { token: props.authState.token },
          }
        );
      }

      // 获取问答列表，如果问答列表中包含当前患者，则跳转到问答页面并进入
      // 相应的问答详情
      props.retrieveAtMineQnaList(true, (qnaListResponse: QnaListResponse) => {
        if (!qnaListResponse.success) {
          warningToast(qnaListResponse.message);
        } else {
          // 当前患者在问答会话列表中的记录
          const patientRecordInAtMineChatList = qnaListResponse.page.list.find(
            (record) => record.patientId === id
          );

          if (patientRecordInAtMineChatList !== undefined) {
            props.retrieveQuestionList({
              patientId: patientRecordInAtMineChatList.patientId,
              mineOrTeam: 0,
            });

            props.updateSelectedChatRecord({
              record: {
                ...patientRecordInAtMineChatList,
                mainChatId: patientRecordInAtMineChatList.id,
              },
            });

            // 问答会话列表中包含当前患者
            props.history.push(routes.QNA);
          }
        }
      });
    } else {
      // 问答会话列表中不包含当前患者，不进行下一步跳转
      warningToast('该患者尚未向您提问，暂不能对其发送信息。');
    }
  };

  const [isPopoverOpened, setPopoverOpened] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      {/* 基础信息 */}
      <div className={styles.basicInfoSection}>
        {/* 头像 / 性别组 */}
        <div className={styles.avatarGender}>
          <div className={styles.avatar}>
            <img src={avatar} alt="头像" />
          </div>

          {/* 性别 */}
          <div className={styles.genderIcon}>
            {sex === 1 ? (
              <i className="pyr pyrnan" style={{ color: '#4bb7ea' }} />
            ) : (
              <i className="pyr pyrnv" style={{ color: '#ff766e' }} />
            )}
          </div>
        </div>

        {/* 用户名 */}
        <div className={styles.userName}>{userName}</div>

        {/* 关注按钮 */}
        <div className={styles.followBtn}>
          {follow === 1 ? (
            <Button minimal onClick={unfollowPatient}>
              <i className="pyr pyrlike1" />
              <span>已关注</span>
            </Button>
          ) : (
            <Button minimal text="关注" onClick={followPatient} />
          )}
        </div>
        <div className={styles.propGroup1}>
          <div>
            <span>年龄</span>
            <span>{age}</span>
          </div>
          <div>
            <span>出生日期</span>
            <span>{getBirthDateFromIdCard(idCard)}</span>
          </div>
        </div>
        <div>
          <Button
            intent={Intent.PRIMARY}
            text="与Ta对话"
            onClick={talkWithPatient}
          />
        </div>
      </div>

      <Popover
        isOpen={isPopoverOpened}
        popoverClassName={styles.popoverClass}
        position={Position.BOTTOM_LEFT}
        interactionKind={PopoverInteractionKind.HOVER}
        modifiers={{
          preventOverflow: { enabled: false },
          flip: { enabled: false },
          arrow: { enabled: false },
        }}
      >
        {/* 诊断信息 */}
        <button
          className={styles.diagnosisSection}
          onClick={() => {
            setPopoverOpened(true);
          }}
        >
          <div className={styles.diagnosisBtn}>
            <span>诊断</span>
            <Icon icon="chevron-down" />
          </div>

          {/* 诊断结论 */}
          <div className={styles.propGroup2}>
            <div>
              <div>诊断结论</div>
              <div>
                {typeof confirmedDisease === 'string' &&
                confirmedDisease.trim().length > 0
                  ? confirmedDisease
                  : '无'}
              </div>
            </div>
            <div>
              <div>诊断日期</div>
              <div>
                {diagnosisTime === null ? '' : formatTimestamp(diagnosisTime)}
              </div>
            </div>
          </div>
        </button>

        <HealthInfoPopoverContent
          patientDetails={props.patientDetails}
          updatePatientDetails={updatePatientDetails}
          onClose={() => {
            setPopoverOpened(false);
          }}
        />
      </Popover>

      {/* 标签列表 */}
      <div className={styles.labelListSection}>
        <div className={styles.labelTitle}>
          <span>标签</span>

          <div className={styles.labelTitle__opt}>
            <PatientLabelAddButton
              retrieveLabelList={retrieveLabelList}
              patientId={id}
            />

            <Button
              onClick={() => {
                setLabelEditing(!isLabelEditing);
              }}
              className={styles.labelEditor}
              minimal
              // active={isLabelEditing}
            >
              <i
                className="pyr pyrtag_delete_button"
                style={{ color: '#3DA8F5' }}
              />
            </Button>
          </div>
        </div>
        <div className={styles.labelList}>
          {isPatientLabelListLoading ? (
            <PyrSpinner />
          ) : (
            patientLabelList.map((labelItem) => (
              <div key={labelItem.id} className={styles.labelItem}>
                {isLabelEditing && (
                  <Button
                    icon="cross"
                    minimal
                    onClick={() => {
                      deleteLabel(labelItem.id);
                    }}
                  />
                )}
                <span key={labelItem.id}>{labelItem.name}</span>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param idCardNumber 根据身份证号获取生日
 */
const getBirthDateFromIdCard = (idCardNumber: string): string => {
  const year = idCardNumber.slice(6, 10);
  const month = idCardNumber.slice(10, 12);
  const day = idCardNumber.slice(12, 14);

  return `${parseInt(year)}/${parseInt(month)}/${parseInt(day)}`;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveDoctorLabelList: () => {
    dispatch(retrieveLabelList());
  },

  retrieveAtMineQnaList: (
    ifGetFromFirstPage: boolean = false,
    callbackAfterListRetrieved: (qnaListResponse: QnaListResponse) => void
  ) => {
    dispatch(
      retrieveAtMineQnaList(ifGetFromFirstPage, callbackAfterListRetrieved)
    );
  },

  retrieveQuestionList: (payload: RetrieveQuestionListPayload) => {
    dispatch(retrieveQuestionList(payload));
  },

  updateSelectedChatRecord: (payload: {
    record: (QnaRecord & { mainChatId?: number }) | null;
  }) => {
    dispatch(updateSelectedChatRecord(payload));
  },
});

const mapStateToProps = (state: GlobalState) => ({
  authState: state.auth,
  userInfoState: state.userInfo,
  qnaState: state.qna,
});

export default connect<
  PatientDetailsSideBarStateProps,
  PatientDetailsSideBarDispatchProps,
  PatientDetailsSideBarOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PatientDetailsSideBar));
