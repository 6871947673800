// 医生名下患者分组的添加区域
import React, { useState } from 'react';
import {
  Button,
  InputGroup,
  Position,
  Toaster,
  Intent,
} from '@blueprintjs/core';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { apiConfig } from '../../../constants/apiConfig';
import { AuthState } from '../../../reducers/auth';
import styles from './index.module.scss';
import { request } from 'https';
import requests from '../../../utils/requests';

type PatientGroupAddButtonOwnProps = Readonly<{
  authModel: AuthState;
  retrievePatientGroupList: () => void;
}>;

const PatientGroupAddButton: React.FC<PatientGroupAddButtonOwnProps> = (
  props
) => {
  const [newGroupNameInput, updateNewGroupNameInput] = useState<string>('');

  // 是否正在发送添加患者分组的请求
  const [isActionLoading, setActionLoading] = useState<boolean>(false);

  // 是否展开操作区域
  const [isActive, setIsActive] = useState<boolean>(false);

  // 添加医生名下患者分组
  const addPatientGroup = async () => {
    console.log(newGroupNameInput);
    const trimmedNewGroupName = newGroupNameInput.trim();
    if (trimmedNewGroupName.length <= 0) {
      Toaster.create({ position: Position.TOP }).show({
        message: '分组名称不能为空',
        intent: Intent.WARNING,
        icon: 'warning-sign',
      });

      return;
    }

    setActionLoading(true);
    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.ADD_DOCTOR_PATIENT_GROUP}?${queryString.stringify({
        type: 2,
        name: trimmedNewGroupName,
      })}`,
      {},
      { headers: { token: props.authModel.token } }
    );

    if (res.data.success) {
      props.retrievePatientGroupList();
      setIsActive(false);
    } else {
      Toaster.create({ position: Position.TOP }).show({
        message: res.data.message,
        intent: Intent.WARNING,
        icon: 'warning-sign',
      });
    }
    setActionLoading(false);
  };

  return (
    <div className={styles.patientGroupAddButtonWrapper}>
      <button
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <i className="pyr pyradd" />
      </button>
      {isActive ? (
        <form
          className={styles.patientGroupAddButtonOpt}
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            addPatientGroup();
          }}
        >
          <InputGroup
            value={newGroupNameInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateNewGroupNameInput(event.target.value);
            }}
            rightElement={
              <Button
                type="submit"
                text="添加"
                minimal
                loading={isActionLoading}
              />
            }
          />
        </form>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PatientGroupAddButton;
