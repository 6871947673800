import React, { useState, useEffect } from 'react';
import produce from 'immer';
import classNames from 'classnames';

import { PatientDetails } from '../../PatientDetails';
import styles from './index.module.scss';
import { loadUserTokenFromLocal } from '../../../../utils/auth';
import requests from '../../../../utils/requests';
import { apiConfig } from '../../../../constants/apiConfig';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../../commonTypes';
import PyrSpinner from '../../../common/PyrSpinner';
import CheckableProperty from './components/CheckableProperty';
import { Button, Intent } from '@blueprintjs/core';
import OthersEditPopover from './components/OthersEditPopover';

type HealthExpTabProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
  isUpdatePatientLoading: boolean;
}>;

type CommonHealthHistoryList = Readonly<{
  data: CommonHealthHistoryItem[];
  isLoading: boolean;
}>;

type CommonHealthHistoryItem = Readonly<{
  id: number;
  name: string;
}>;

const defaultCommonHealthHistoryList: CommonHealthHistoryItem[] = [
  { id: 1, name: '抑郁症' },
  { id: 2, name: '焦虑症' },
  { id: 3, name: '睡眠呼吸暂停综合症' },
  { id: 4, name: '睡眠中出现的周期性肢体活动障碍' },
  { id: 5, name: '快速眼动期睡眠行为障碍' },
  { id: 6, name: '轻度认知功能障碍' },
  { id: 7, name: '高血压' },
  { id: 8, name: '高胆固醇血症' },
  { id: 9, name: '糖尿病' },
  { id: 10, name: '骨关节炎' },
  { id: 11, name: '类风湿性关节炎' },
  { id: 12, name: '冠心病' },
  { id: 13, name: '充血性心力衰竭' },
  { id: 14, name: '甲状腺心功能减退' },
  { id: 15, name: '哮喘/慢性阻塞性肺疾病' },
  { id: 16, name: '不宁腿综合征' },
  { id: 17, name: '卒中' },
  { id: 18, name: '帕金森综合症' },
  { id: 19, name: '发作性睡病' },
  { id: 20, name: '癫痫' },
];

const HealthExpTabContent: React.FC<HealthExpTabProps> = (props) => {
  const { patientDetails, updatePatientInfo, isUpdatePatientLoading } = props;

  const [
    commonHealthHistoryList,
    // updateCommonHealthHistoryList
  ] = useState<
    CommonHealthHistoryList
    // >({ data: [], isLoading: true });
  >({ data: defaultCommonHealthHistoryList, isLoading: false });

  // // 获取健康史列表
  // const retrieveHealthHistory = async () => {
  //   const userTokenRes = loadUserTokenFromLocal();
  //   if (!userTokenRes.success) {
  //     return;
  //   }

  //   updateCommonHealthHistoryList({ isLoading: true, data: [] });

  //   const res: AxiosResponse<CommonHTTPResponse<{
  //     data: CommonHealthHistoryItem[];
  //   }>> = await requests.post(
  //     apiConfig.GET_HEALTH_HISTORY,
  //     {},
  //     { headers: { token: userTokenRes.token } }
  //   );

  //   updateCommonHealthHistoryList({
  //     data: res.data.success ? res.data.data : [],
  //     isLoading: false,
  //   });
  // };

  // useEffect(() => {
  //   retrieveHealthHistory();
  // }, []);

  const defaultHealthHistoryList: string[] =
    typeof patientDetails.onceDiagnosedDisease === 'string'
      ? patientDetails.onceDiagnosedDisease.split('|')[0].split(',')
      : [];

  const defaultCustomizedHealthHistoryList: string[] =
    typeof patientDetails.onceDiagnosedDisease === 'string'
      ? typeof patientDetails.onceDiagnosedDisease.split('|')[1] === 'string'
        ? patientDetails.onceDiagnosedDisease
            .split('|')[1]
            .split(',')
            .filter((item) => item.length > 0)
        : []
      : [];

  // 健康史列表
  const [healthHistoryList, updateHealthHistoryList] = useState<string[]>(
    defaultHealthHistoryList
  );

  // 自定义健康史列表
  const [
    customizedHealthHistoryList,
    updateCustomizedHealthHistoryList,
  ] = useState<string[]>(defaultCustomizedHealthHistoryList);

  const [healthHistoryListSet, updateHealthHistoryListSet] = useState<string>(
    patientDetails.onceDiagnosedDisease
  );

  /**
   *  更改健康史列表的选择状态
   * @param value 条目名称
   * @param selected 选择与否
   */
  const updateHealthHistoryListByValue = (value: string, selected: boolean) => {
    let newSelectedHealthHistoryList: string[] = [];

    if (selected) {
      newSelectedHealthHistoryList = [...healthHistoryList, value];
    } else {
      const index = healthHistoryList.indexOf(value);

      newSelectedHealthHistoryList = produce(healthHistoryList, (draft) => {
        draft.splice(index, 1);
      });
    }
    updateHealthHistoryList(newSelectedHealthHistoryList);
    updateHealthHistoryListSet(
      `${newSelectedHealthHistoryList.join(
        ','
      )}|${customizedHealthHistoryList.join(',')}`
    );
  };

  return (
    <div className={styles.healthExpWrapper}>
      <div
        className={classNames({
          [styles.healthExpWrapper]: true,
          [styles.diagnosisWrapper]: true,
        })}
      >
        {commonHealthHistoryList.isLoading ? (
          <PyrSpinner />
        ) : (
          commonHealthHistoryList.data.map((healthHistoryItem) => (
            <CheckableProperty
              key={healthHistoryItem.id}
              title={healthHistoryItem.name}
              checked={healthHistoryList.includes(healthHistoryItem.name)}
              onChange={(selected) => {
                updateHealthHistoryListByValue(
                  healthHistoryItem.name,
                  selected
                );
              }}
            />
          ))
        )}
      </div>

      <OthersEditPopover
        customizedList={customizedHealthHistoryList}
        updateCustomizedList={(newList) => {
          updateCustomizedHealthHistoryList(newList);

          updateHealthHistoryListSet(
            `${healthHistoryList.join(',')}|${newList.join(',')}`
          );
        }}
      />

      {healthHistoryListSet !== patientDetails.onceDiagnosedDisease && (
        <div className={styles.updateUserInfoBtnWrapper}>
          <Button
            intent={Intent.PRIMARY}
            text="立即更新"
            onClick={() => {
              updatePatientInfo({ onceDiagnosedDisease: healthHistoryListSet });
            }}
            loading={isUpdatePatientLoading}
          />
        </div>
      )}
    </div>
  );
};

export default HealthExpTabContent;
