// 将用户登录状态设置为已登录，并设置用户token
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
// 将用户登录状态设置为未登录，并清除用户token
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';

type SetAuthenticatedPayload = Readonly<{
  token: string;
}>;

export type SetAuthenticatedAction = Readonly<{
  type: 'SET_AUTHENTICATED';
  payload: SetAuthenticatedPayload;
}>;

export type SetUnauthenticatedAction = Readonly<{
  type: 'SET_UNAUTHENTICATED';
}>;

export const setAuthenticated = (
  payload: SetAuthenticatedPayload
): SetAuthenticatedAction => ({
  type: SET_AUTHENTICATED,
  payload,
});

export const setUnauthenticated = (): SetUnauthenticatedAction => ({
  type: SET_UNAUTHENTICATED,
});
