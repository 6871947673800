export type ReportRow = Readonly<{
  childSort: number;
  content: string;
  id: number;
  sort: number;
  relationAnswerList: RelationAnswer[];
}>;

export type RelationAnswer = Readonly<{
  content: string;
  id: number;
  qid: number;
  qrelationId: number;
  qtype: null;
  sort: number;
  userAnswer: string;
}>;

/**
 *  量表类型
 */
export enum RelationReport {
  SchwabEngland,
  NMSS,
  QueenSquare,
  AutonomicNerve,
  MAES,
  PDSS,
  REM,
}

export const relationReportTitles = {
  [RelationReport.SchwabEngland]: {
    title: '生活能力自评量表',
    subTitle: 'Schwab-England',
  },
  [RelationReport.NMSS]: {
    title: '非运动症状评估',
    subTitle: 'NMSS',
  },
  [RelationReport.QueenSquare]: {
    title: '视幻觉清单',
    subTitle: 'Queen Square',
  },
  [RelationReport.AutonomicNerve]: {
    title: '自主神经量表',
    subTitle: '',
  },
  [RelationReport.MAES]: {
    title: '改良淡漠量表',
    subTitle: 'MAES',
  },
  [RelationReport.PDSS]: {
    title: '睡眠量表',
    subTitle: 'PDSS',
  },
  [RelationReport.REM]: {
    title: 'REM行为障碍筛查',
    subTitle: '',
  },
};
