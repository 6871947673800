import React from 'react';

import { ReportRow } from '../types';
import styles from './index.module.scss';
import { Icon } from '@blueprintjs/core';

const MAESReportTable: React.FC<{ reportData: ReportRow[] }> = (props) => {
  const { reportData } = props;

  const generateRowList = () => {
    return reportData.map((reportRow, index) => {
      const { userAnswer } = reportRow.relationAnswerList[0];

      return (
        <tr key={reportRow.id}>
          <td>{`${index + 1}、${reportRow.content}`}</td>
          <td>{userAnswer === '1' ? <Icon icon="small-tick" /> : <></>}</td>
          <td>{userAnswer === '2' ? <Icon icon="small-tick" /> : <></>}</td>
          <td>{userAnswer === '3' ? <Icon icon="small-tick" /> : <></>}</td>
          <td>{userAnswer === '4' ? <Icon icon="small-tick" /> : <></>}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <table className={`${styles.reportTable} ${styles.MAESTable}`}>
        <thead>
          <tr>
            <th>问题</th>
            <th>无</th>
            <th>轻微</th>
            <th>一些</th>
            <th>非常</th>
          </tr>
        </thead>
        <tbody>{generateRowList()}</tbody>
      </table>

      <p>问题1-8：无=3分；轻微=2分；一些=1分；非常=0分；</p>
      <p>问题9-14：无=0分；轻微=1分；一些=2分；非常=3分</p>
    </>
  );
};

export default MAESReportTable;
