import React from 'react';

import styles from './index.module.scss';

const VisitView: React.FC<{}> = (props) => {
  return (
    <div className={`${styles.listGroup} ${styles.listGroup__visit}`}>
      <p className={styles.listGroup__title}>访问</p>
      <button>悠然管家</button>
      <button>帕医堂</button>
      <button>
        <span>消息</span>
        <span className={styles.listGroup__visit__unread}>3</span>
      </button>
    </div>
  );
};

export default VisitView;
