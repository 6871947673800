import React from 'react';
import { ArticleItem } from '../../../../reducers/articles';
import styles from './index.module.scss';

type ShareArticleItemProps = Readonly<{
  data: ArticleItem;
}>;

const ShareArticleItem: React.FC<ShareArticleItemProps> = (props) => {
  const { title, summary, cover } = props.data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.shareListItem__cover}>
        {typeof cover === 'string' ? (
          <img src={cover} alt="封面" />
        ) : (
          <i className="pyr pyrimg" />
        )}
      </div>
      <div className={styles.body}>
        <p className={styles.title}>{title}</p>
        <p className={styles.summary}>{summary}</p>
      </div>
    </div>
  );
};

export default ShareArticleItem;
