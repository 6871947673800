import React, { useState } from 'react';
import { Spinner, Button } from '@blueprintjs/core';
import classNames from 'classnames';

import { PatientGroupList } from '../../../reducers/userInfo';
import { GlobalState, Dispatch } from '../../../store/configureStore';
import styles from './index.module.scss';
import { retrievePatientGroupList } from '../../../actions/userInfo';
import { connect } from 'react-redux';
import requests from '../../../utils/requests';
import { apiConfig } from '../../../constants/apiConfig';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { successToast, warningToast } from '../../../utils/toaster';

type PatientGroupListViewOwnProps = Readonly<{
  patientGroupList: PatientGroupList;
  onGroupItemSelect: (groupId: number) => void;
  isBeingEdited: boolean;
}>;

type PatientGrouplistViewDispatchProps = Readonly<{
  retrievePatientGroupList: () => void;
}>;

const PatientGroupListView: React.FC<
  PatientGroupListViewOwnProps & PatientGrouplistViewDispatchProps
> = (props) => {
  const {
    patientGroupList,
    onGroupItemSelect,
    retrievePatientGroupList,
  } = props;
  const [selectedPatientGroupId, selectPatientGroup] = useState<number>();

  const [isDeleteGroupReqLoading, setDeleteGroupReqLoading] = useState<boolean>(
    false
  );

  const deletePatientGroup = async (groupId: number) => {
    if (isDeleteGroupReqLoading) {
      return;
    }

    const tokenResult = await loadUserTokenFromLocal();

    if (!tokenResult.success) {
      return;
    }

    setDeleteGroupReqLoading(true);

    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.get(
      `${apiConfig.DELETE_PATIENT_GROUP_LABEL}?gId=${groupId}`,
      {
        headers: {
          token: tokenResult.token,
        },
      }
    );

    if (res.data.success) {
      successToast('已删除分组');
    } else {
      warningToast(res.data.message);
    }

    setDeleteGroupReqLoading(false);

    retrievePatientGroupList();
  };

  return patientGroupList.isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.patientGroupList}>
      {patientGroupList.data.map((patientGroup) => {
        const { id, name } = patientGroup;

        return (
          <div
            key={id}
            className={classNames({
              [styles.patientGroupItem]: true,
              [styles['patientGroupItem--selected']]:
                selectedPatientGroupId === id,
            })}
          >
            {/* 删除按钮 */}
            {props.isBeingEdited ? (
              <button
                className={styles.patientGroupDeleteBtn}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  deletePatientGroup(id);
                }}
              >
                <i className="pyr pyrdelete" />
              </button>
            ) : (
              <></>
            )}

            {/* 分组选择按钮 */}
            <Button
              className={styles.patientGroupItem__selector}
              onClick={() => {
                selectPatientGroup(id);
                onGroupItemSelect(id);
              }}
              text={name}
              minimal
            />
          </div>
        );
      })}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrievePatientGroupList: () => {
    dispatch(retrievePatientGroupList());
  },
});

export default connect<
  {},
  PatientGrouplistViewDispatchProps,
  PatientGroupListViewOwnProps,
  GlobalState
>(
  null,
  mapDispatchToProps
)(PatientGroupListView);
