import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Toaster, Intent } from '@blueprintjs/core';
import { Button, Modal } from 'antd';
import { AxiosResponse } from 'axios';

import { GlobalState } from '../../store/configureStore';
import { AuthState } from '../../reducers/auth';
import logo from '../../assets/images/logo.svg';
import slogan from '../../assets/images/slogan.svg';
import styles from './index.module.scss';
import { CommonHTTPResponse } from '../../commonTypes';
import { RouteComponentProps } from 'react-router';
import { setAuthenticated } from '../../actions/auth';
import routes from '../../constants/routes.json';
import { Dispatch } from '../../reducers/types';
import requests from '../../utils/requests';
import { apiConfig } from '../../constants/apiConfig';
import PatientQRCode from '../../assets/images/qr_code_with_transparency.png';
import RoleChangeImg from '../../assets/images/role_change.svg';

// 登录请求的返回值
type SignInResponse = Readonly<
  CommonHTTPResponse<{
    // 用户 token
    object: string;
  }>
>;

type SignInStateProps = Readonly<{
  authState: AuthState;
}>;

type SignInDispatchProps = Readonly<{
  setAuthenticated: (token: string) => void;
}>;

type SignInOwnProps = Readonly<
  { isOpened: boolean; onClose: () => void } & RouteComponentProps
>;

const toaster = Toaster.create();
const showFormValidationToast = (msg: string) => {
  toaster.show({ message: msg, intent: Intent.WARNING, timeout: 1500 });
};

// 登录页面组件
const SignIn: React.FC<
  SignInStateProps & SignInDispatchProps & SignInOwnProps
> = (props) => {
  const form = useRef<HTMLFormElement>(null);

  const [isRequestPending, setRequestPending] = useState<boolean>(false);

  // TODO: 真实登录
  const sendSignInReq = async () => {
    // setRequestPending(true);

    // setTimeout(() => {
    //   props.history.push(routes.USER);

    //   setRequestPending(false);
    // }, 632);

    if (!form.current) return;

    try {
      const { username, password } = form.current;

      const usernameValue = username.value.trim();
      const passwordValue = password.value.trim();

      if (usernameValue.length === 0) {
        throw Error('请填写用户名');
      }

      if (passwordValue.length === 0) {
        throw Error('请填写密码');
      }

      setRequestPending(true);
      const res: AxiosResponse<SignInResponse> = await requests.post(
        apiConfig.SIGN_IN,
        {
          // account: usernameValue,
          // password: passwordValue,
        }
      );

      const resData = res.data;

      setRequestPending(false);
      if (resData.success) {
        // 设置全局登录状态
        props.setAuthenticated(resData.object);

        window.open(routes.USER)
      } else {
        throw Error(resData.message);
      }
    } catch (exception) {
      showFormValidationToast(exception.message);
    }
  };

  return (
    <Modal
      visible={props.isOpened}
      onCancel={props.onClose}
      footer={false}
      title={false}
      wrapClassName={styles.grandWrapper}
      className={styles.modalWrapper}
      // width={836}
      width={400}
      mask={false}
    >
      <header>
        <a href="/">
          <img src={logo} alt="图标" />
          <i className="pyr pyrpayouran" />
          <img className={styles.slogan} src={slogan} alt="" />
        </a>
      </header>
      <div className={styles.wrapper}>
        {/* <div className={styles.left}>
          <div className={styles.left__tabs}>
            <button className={styles['left__tab--active']}>登录</button>
            <div className={styles.left__tabsDevider}>
              <div></div>
            </div>
            <button>注册</button>
          </div>

          <form
            ref={form}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              sendSignInReq();
            }}
          >
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel} htmlFor="Username">
                <i className="pyr pyrname_mail" />
              </label>
              <input
                placeholder="手机、或用户名"
                name="username"
                id="Username"
                // leftIcon={<i className="pyr pyredit" />}
              />
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel} htmlFor="Password">
                <i className="pyr pyrlock" />
              </label>
              <input
                placeholder="密码"
                type="password"
                name="password"
                id="Password"
                // leftIcon="key"
              />
            </div>
            <Button
              htmlType="submit"
              className={styles.submitBtn}
              loading={isRequestPending}
            >
              登 录
            </Button>
          </form>

          <div className={styles.divider}>
            <div>或</div>
          </div>
        </div> */}

        <div className={styles.right}>
          {/* <p className={styles.right__header}>扫一扫</p> */}

          <div className={styles.right__wechatSign}>
            {/* <span>使用微信访问</span> */}
            <i className="pyr pyrwechat" />
          </div>

          <div className={styles.right__qrCodeWrapper}>
            <img src={PatientQRCode} alt="QRCode" />
          </div>
          <p style={{ color:"#999", marginTop: 20 }}>微信扫码登录帕悠然小程序</p>
        </div>

        <a className={styles.roleChangeBtn} href="/doctor">
          <img src={RoleChangeImg} alt="角色切换" />
        </a>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: GlobalState) => ({ authState: state.auth });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAuthenticated: (token: string) => {
    dispatch(setAuthenticated({ token }));
  },
});

export default connect<SignInStateProps, SignInDispatchProps, {}, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignIn));
