import React from 'react';

import { ReportRow } from '../types';
import styles from './index.module.scss';

const SchwabEnglandReportTable: React.FC<{ reportData: ReportRow[] }> = (
  props
) => {
  const { reportData } = props;

  return (
    <table className={`${styles.reportTable} ${styles.schwabEngland}`}>
      <tbody>
        <tr>
          <th>活动度</th>
          <th>表现</th>
        </tr>
        {reportData.map((reportRow) => {
          const isSelected =
            reportRow.relationAnswerList[0].userAnswer ===
            reportRow.id.toString();

          return (
            <tr
              className={isSelected ? styles.schwabEngland__activeRow : ''}
              key={reportRow.id}
            >
              <td className={styles.schwabEngland__actionPercent}>
                <span>
                  {isSelected ? <i className="pyr pyrpain_selection" /> : <></>}
                </span>
                <span>{reportRow.content}</span>
              </td>
              <td>{reportRow.relationAnswerList[0].content}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SchwabEnglandReportTable;
