/**
 * 危险因素的工作经验列表
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';

import { GlobalState } from '../../../../../store/configureStore';
import { AuthState } from '../../../../../reducers/auth';
import { apiConfig } from '../../../../../constants/apiConfig';
import { CommonHTTPResponse } from '../../../../../commonTypes';
import CheckableProperty from '../components/CheckableProperty';

import styles from './index.module.scss';
import produce from 'immer';
import requests from '../../../../../utils/requests';
import PyrSpinner from '../../../../common/PyrSpinner';

/**
 * 公共工作经验列表资源请求状态
 */
type CommonWorkingExpList = Readonly<{
  data: CommonWorkingExpItem[];
  isLoading: boolean;
}>;

type CommonWorkingExpItem = Readonly<{
  id: number;
  name: string;
}>;

type CommonWorkingExpResponse = CommonHTTPResponse<{
  data: CommonWorkingExpItem[];
}>;

const defaultCommonWorkingExpListState: CommonWorkingExpList = {
  data: [],
  isLoading: false,
};

/**
 * 组件 props 类型定义
 */
type WorkingExpListOwnProps = Readonly<{
  workingExpListStrSet: string;
  updateWorkingExpListStrSet: (value: string) => void;
}>;

type WorkingExpListStateProps = Readonly<{
  authModel: AuthState;
}>;

const WorkingExpList: React.FC<
  WorkingExpListOwnProps & WorkingExpListStateProps
> = (props) => {
  const { workingExpListStrSet, authModel, updateWorkingExpListStrSet } = props;

  // 公共工作经验列表
  const [commonWorkingExpList, updateCommonWorkingExpList] = useState<
    CommonWorkingExpList
  >(defaultCommonWorkingExpListState);

  // 获取公共工作经验列表
  const retrieveCommonWorkingExpList = async () => {
    updateCommonWorkingExpList({ isLoading: true, data: [] });

    const res: AxiosResponse<CommonWorkingExpResponse> = await requests.post(
      apiConfig.GET_WORKING_EXP_LIST,
      {},
      { headers: { token: authModel.token } }
    );

    updateCommonWorkingExpList({
      data: res.data.success ? res.data.data : [],
      isLoading: false,
    });
  };

  const defaultCommonUsingWorkingExpList: string[] = workingExpListStrSet
    .split('|')[0]
    .split(',');

  const defaultCustomizedUsingWorkingExpList: string[] =
    typeof workingExpListStrSet.split('|')[1] === 'string'
      ? workingExpListStrSet.split('|')[1].split(',')
      : [];

  // 公共用药列表
  const [commonUsingWorkingExpList, updateCommonUsingWorkingExpList] = useState<
    string[]
  >(defaultCommonUsingWorkingExpList);

  // 自定义用药列表
  const [
    customizedUsingWorkingExpList,
    // updateCustomizedUsingWorkingExpList,
  ] = useState<string[]>(defaultCustomizedUsingWorkingExpList);

  const generatecustomizedUsingWorkingExpList = (
    commonUsingWorkingExpList: string[],
    customizedUsingWorkingExpList: string[]
  ) => {
    return `${commonUsingWorkingExpList.join(
      ','
    )}|${customizedUsingWorkingExpList.join(',')}`;
  };

  /**
   *  更改公共工作经验列表的选择状态
   * @param value 工作经验名称
   * @param selected 选择与否
   */
  const updateCommonUsingWorkingExpListByValue = (
    value: string,
    selected: boolean
  ) => {
    let newCommonUsingWorkingExpList: string[] = [];

    if (selected) {
      newCommonUsingWorkingExpList = [...commonUsingWorkingExpList, value];
    } else {
      const index = commonUsingWorkingExpList.indexOf(value);

      newCommonUsingWorkingExpList = produce(
        commonUsingWorkingExpList,
        (draft) => {
          draft.splice(index, 1);
        }
      );
    }

    updateCommonUsingWorkingExpList(newCommonUsingWorkingExpList);

    updateWorkingExpListStrSet(
      generatecustomizedUsingWorkingExpList(
        newCommonUsingWorkingExpList,
        customizedUsingWorkingExpList
      )
    );
  };

  // /**
  //  *  更改自定义工作经验列表的选择状态
  //  * @param value 工作经验名称
  //  * @param selected 选择与否
  //  */
  // const updateCustomizedUsingWorkingExpListByValue = (
  //   value: string,
  //   selected: boolean
  // ) => {
  //   if (selected) {
  //     updateCustomizedUsingWorkingExpList([
  //       ...customizedUsingWorkingExpList,
  //       value,
  //     ]);
  //   } else {
  //     const index = customizedUsingWorkingExpList.indexOf(value);

  //     const newCustomizedUsingWorkingExpList = produce(
  //       customizedUsingWorkingExpList,
  //       (draft) => {
  //         draft.splice(index, 1);
  //       }
  //     );

  //     updateCustomizedUsingWorkingExpList(newCustomizedUsingWorkingExpList);

  //     updateWorkingExpListStrSet(
  //       generatecustomizedUsingWorkingExpList(
  //         newCustomizedUsingWorkingExpList,
  //         commonUsingWorkingExpList
  //       )
  //     );
  //   }
  // };

  useEffect(() => {
    retrieveCommonWorkingExpList();
  }, []);

  return (
    <section className={styles.workingExpSection}>
      {commonWorkingExpList.isLoading ? (
        <PyrSpinner />
      ) : (
        commonWorkingExpList.data.map((workingExpItem) => (
          <CheckableProperty
            key={workingExpItem.id}
            title={workingExpItem.name}
            checked={commonUsingWorkingExpList.includes(workingExpItem.name)}
            onChange={(selected) => {
              updateCommonUsingWorkingExpListByValue(
                workingExpItem.name,
                selected
              );
            }}
          />
        ))
      )}
    </section>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
});

export default connect<
  WorkingExpListStateProps,
  {},
  WorkingExpListOwnProps,
  GlobalState
>(mapStateToProps)(WorkingExpList);
