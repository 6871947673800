import {
  UpdateUserInfoAction,
  UPDATE_USER_INFO,
  UPDATE_DOCTOR_PATIENT_GROUP_LIST,
  UpdateDoctorPatientGroupListAction,
  UPDATE_LABEL_LIST,
  UpdateLabelListAction,
  UPDATE_DOCTOR_GROUP_LIST,
  UpdateDoctorGroupListAction,
  UPDATE_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST,
  UpdateDoctorGroupListForPatientListAction,
} from '../actions/userInfo';

export type UserInfoState = Readonly<{
  isLoading: boolean;
  data: null | UserInfoData;
  patientGroupList: PatientGroupList;
  patientLabelList: {
    data: any[];
    isLoading: boolean;
  };
  doctorGroupListForPatientList: {
    data: DoctorGroupForPatientList[];
    isLoading: boolean;
  };
  doctorGroupList: DoctorGroupList;
}>;

export type UserInfoData = Readonly<{
  academicTitle: number;
  avatar: string;
  departmentId: number;
  departmentName: string;
  describes: string;
  hospitalId: number;
  hospitalName: string;
  id: number;
  muserId: number;
  muserName: string;
  doctorName: string;
  name: string;
  skill: string;
  teamCap: number;
}>;

export type PatientGroupList = Readonly<{
  data: PatientGroup[];
  isLoading: boolean;
}>;

export type PatientGroup = Readonly<{
  createTime: number;
  customize: 0 | 1;
  doctorId: number;
  gid: number;
  id: number;
  labelId: number;
  name: string;
  patientId: number;
  type: number;
}>;

export type PatientLabelList = Readonly<{
  data: PatientLabel[];
  isLoading: boolean;
}>;

export type PatientLabel = Readonly<{
  createTime: number;
  customize: 0 | 1;
  doctorId: number;
  gid: number;
  id: number;
  labelId: number;
  name: string;
  patientId: number;
  type: number;
}>;

// 医生团队列表
export type DoctorGroupList = Readonly<{
  data: DoctorGroup[];
  isLoading: boolean;
}>;

// 医生团队
export type DoctorGroupForPatientList = Readonly<{
  total: number;
  group: DoctorGroup;
}>;

export type DoctorGroup = Readonly<{
  createUserId: number;
  hospitalId: number;
  hospitalName: string;
  id: number;
  leader: 0 | 1;
  name: string;
  publicFlag: number;
  status: number;
}>;

const defaultUserInfoState: UserInfoState = {
  isLoading: false,
  data: null,
  // 医生名下患者分组列表
  patientGroupList: {
    data: [],
    isLoading: false,
  },
  // 医生名下患者标签列表
  patientLabelList: {
    data: [],
    isLoading: false,
  },
  // 医生团队列表（供患者列表使用）
  doctorGroupListForPatientList: {
    data: [],
    isLoading: false,
  },
  doctorGroupList: {
    data: [],
    isLoading: false,
  },
};

export default function userInfo(
  state = defaultUserInfoState,
  actions:
    | UpdateUserInfoAction
    | UpdateDoctorPatientGroupListAction
    | UpdateLabelListAction
    | UpdateDoctorGroupListAction
    | UpdateDoctorGroupListForPatientListAction
) {
  switch (actions.type) {
    case UPDATE_USER_INFO:
      return { ...state, ...actions.payload };
    case UPDATE_DOCTOR_PATIENT_GROUP_LIST:
      return {
        ...state,
        patientGroupList: { ...state.patientGroupList, ...actions.payload },
      };
    case UPDATE_LABEL_LIST:
      return {
        ...state,
        patientLabelList: { ...state.patientLabelList, ...actions.payload },
      };
    case UPDATE_DOCTOR_GROUP_LIST:
      return {
        ...state,
        doctorGroupList: { ...state.doctorGroupList, ...actions.payload },
      };
    case UPDATE_DOCTOR_GROUP_LIST_FOR_PATIENT_LIST:
      return {
        ...state,
        doctorGroupListForPatientList: {
          ...state.doctorGroupListForPatientList,
          ...actions.payload,
        },
      };
  }
  return state;
}
