// 医生名下患者标签的添加区域
import React, { useState } from 'react';
import {
  Button,
  InputGroup,
  Position,
  Toaster,
  Intent,
} from '@blueprintjs/core';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import { CommonHTTPResponse } from '../../../commonTypes';
import { UserInfoState } from '../../../reducers/userInfo';
import { apiConfig } from '../../../constants/apiConfig';
import { AuthState } from '../../../reducers/auth';
import { GlobalState, Dispatch } from '../../../store/configureStore';
import { connect } from 'react-redux';
import { retrieveLabelList } from '../../../actions/userInfo';
import requests from '../../../utils/requests';
import { warningToast } from '../../../utils/toaster';

type PatientLabelPageStateProps = Readonly<{
  authModel: AuthState;
  userInfo: UserInfoState;
}>;

type PatientLabelAddButtonDispatchProps = Readonly<{
  retrieveDoctorLabelList: () => void;
}>;

type PatientLabelAddButtonOwnProps = Readonly<{
  retrieveLabelList: () => void;
  patientId: number;
}>;

const PatientLabelAddButton: React.FC<
  PatientLabelPageStateProps &
    PatientLabelAddButtonOwnProps &
    PatientLabelAddButtonDispatchProps
> = (props) => {
  const [newLabelNameInput, updateNewLabelNameInput] = useState<string>('');

  // 是否正在发送添加患者标签的请求
  const [isActionLoading, setActionLoading] = useState<boolean>(false);

  // 是否展开操作区域
  const [isActive, setIsActive] = useState<boolean>(false);

  // 添加医生名下患者标签
  const addPatientLabel = async () => {
    const trimmedNewLabelName = newLabelNameInput.trim();
    if (trimmedNewLabelName.length <= 0) {
      Toaster.create({ position: Position.TOP }).show({
        message: '标签名不能为空',
        intent: Intent.WARNING,
        icon: 'warning-sign',
        timeout: 30000000,
      });

      return;
    }

    setActionLoading(true);
    const res: AxiosResponse<CommonHTTPResponse<{}>> = await requests.post(
      `${apiConfig.ADD_DOCTOR_PATIENT_GROUP}?${queryString.stringify({
        type: 1,
        name: trimmedNewLabelName,
        patientId: props.patientId,
      })}`,
      {},
      { headers: { token: props.authModel.token } }
    );

    if (res.data.success) {
      props.retrieveLabelList();
      props.retrieveDoctorLabelList();
      setIsActive(false);
    } else {
      Toaster.create({ position: Position.TOP }).show({
        message: res.data.message,
        intent: Intent.WARNING,
        icon: 'warning-sign',
      });
    }
    setActionLoading(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Button
        minimal
        // active={isActive}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <i className="pyr pyrtag_add_button" style={{ color: '#3DA8F5' }} />
      </Button>
      {isActive ? (
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            addPatientLabel();
          }}
        >
          <InputGroup
            value={newLabelNameInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateNewLabelNameInput(event.target.value);
            }}
            rightElement={
              <Button
                type="submit"
                text="添加"
                minimal
                loading={isActionLoading}
              />
            }
          />
        </form>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveDoctorLabelList: () => {
    dispatch(retrieveLabelList());
  },
});

export default connect<
  PatientLabelPageStateProps,
  PatientLabelAddButtonDispatchProps,
  PatientLabelAddButtonOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(PatientLabelAddButton);
