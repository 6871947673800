import Axios, { AxiosResponse } from 'axios';

const requests = Axios.create({ timeout: 100000 });

requests.interceptors.response.use((response: AxiosResponse<any>) => {
  console.log('interceptor Response');
  console.log(response);

  if (response.data.errorCode === 1) {
    window.location.href = '/signIn';
  }

  return response;
});

export default requests;
