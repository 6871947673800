import React, { useEffect, useState, createContext } from 'react';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import { Spinner } from '@blueprintjs/core';
import queryString from 'query-string';

import { apiConfig } from '../../constants/apiConfig';
import { GlobalState } from '../../store/configureStore';
import { AuthState } from '../../reducers/auth';
import { CommonHTTPResponse } from '../../commonTypes';
import PatientDetailsSideBar from './PatientDetailsSideBar';
import { UserInfoState, PatientLabel } from '../../reducers/userInfo';
import MedicalHistory from './MedicalHistory';
import styles from './index.module.scss';
import requests from '../../utils/requests';
import PyrSpinner from '../common/PyrSpinner';
import { PatientDetailsContext } from './context';
import PatientNotSelectedImg from '../../assets/images/patient_not_selected.svg';

type PatientDetailsViewStateProps = Readonly<{
  authModel: AuthState;
  userInfoModel: UserInfoState;
}>;

type PatientDetailsViewOwnProps = Readonly<{
  selectedPatientId: number | null;
}>;

const PatientDetailsView: React.FC<
  PatientDetailsViewStateProps & PatientDetailsViewOwnProps
> = (props) => {
  const { userInfoModel, selectedPatientId, authModel } = props;

  const [patientDetails, updatePatientDetails] = useState<PatientDetails>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 获取患者详情
  const retrievePatientDetails = async (
    selectedPatientId: number,
    doctorId: number
  ) => {
    setIsLoading(true);

    const res: AxiosResponse<
      CommonHTTPResponse<{
        data: PatientDetails;
      }>
    > = await requests.post(
      `${apiConfig.GET_PATIENT_DETAILS}?${queryString.stringify({
        patientId: selectedPatientId,
        doctorId: doctorId,
      })}`,
      {},
      {
        headers: {
          token: authModel.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      updatePatientDetails(resData.data);
    }

    setIsLoading(false);
  };

  // 患者标签列表加载状态
  const [isLabelListLoading, setLabelListLoading] = useState<boolean>(false);

  // 患者标签列表
  const [patientLabelList, updatePatientLabelList] = useState<PatientLabel[]>(
    []
  );

  // 获取患者标签列表
  const retrievePatientLabelList = async () => {
    if (isLabelListLoading) {
      return;
    }

    setLabelListLoading(true);

    const res: AxiosResponse<
      CommonHTTPResponse<{
        data: PatientLabel[];
      }>
    > = await requests.post(
      `${apiConfig.GET_PATIENT_LABEL_LIST}?${queryString.stringify({
        patientId: selectedPatientId,
      })}`,
      {},
      {
        headers: {
          token: authModel.token,
        },
      }
    );

    const resData = res.data;

    if (resData.success) {
      updatePatientLabelList(resData.data);
    }

    setLabelListLoading(false);
  };

  useEffect(() => {
    if (typeof selectedPatientId === 'number' && userInfoModel.data !== null) {
      retrievePatientDetails(selectedPatientId, userInfoModel.data.id);
      retrievePatientLabelList();
    }
  }, [selectedPatientId, userInfoModel.data]);

  return (
    <PatientDetailsContext.Provider value={patientDetails}>
      <div>
        <div className={styles.patientDetailsWrapper}>
          <div className={styles.sideBarWrapper}>
            {isLoading || userInfoModel.isLoading ? (
              <PyrSpinner />
            ) : patientDetails ? (
              <PatientDetailsSideBar
                patientDetails={patientDetails}
                patientLabelList={patientLabelList}
                isPatientLabelListLoading={isLabelListLoading}
                retrieveLabelList={retrievePatientLabelList}
                updatePatientDetails={updatePatientDetails}
              />
            ) : (
              <></>
            )}
          </div>

          {selectedPatientId !== null && (
            <MedicalHistory
              patientId={selectedPatientId}
              authModel={authModel}
            />
          )}

          {selectedPatientId === null && !isLoading && (
            <img src={PatientNotSelectedImg} alt="未选择患者" />
          )}
        </div>
      </div>
    </PatientDetailsContext.Provider>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  authModel: state.auth,
  userInfoModel: state.userInfo,
});

export default connect<
  PatientDetailsViewStateProps,
  {},
  PatientDetailsViewOwnProps,
  GlobalState
>(mapStateToProps)(PatientDetailsView);

export type PatientDetails = Readonly<{
  address: string;
  addressFlag: number;
  age: number;
  avatar: string;
  birthPlace: string;
  brainHistory: string;
  cardUpdateTime: string;
  closeRelatives: string;
  coffeeIntake: string;
  confirmedDisease: string;
  courseNum: number;
  createTime: string;
  currentSymptomSite: string;
  defaultSelect: number;
  diagnosisDoctor: string;
  diagnosisHospital: string;
  diagnosisTime: string;
  doctorSpecialty: string;
  education: string;
  firstSymptomSite: string;
  habitHand: string;
  healthPercent: string;
  heavyMetal: string;
  id: number;
  idCard: string;
  muserId: number;
  myDoctorNum: number;
  nation: string;
  onceDiagnosedDisease: string;
  oneceMedicine: string;
  patientId: number;
  phone: number;
  sex: number;
  smoker: string;
  status: number;
  teaIntake: string;
  useingMedicine: string;
  userName: string;
  wineIntake: string;
  workingExperience: string;
  follow: number;
}>;
