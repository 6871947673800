import { InputGroup } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';

import styles from '../index.module.scss';

type LabelValueGroupProps = Readonly<{
  label: string | JSX.Element;
  value: string;
  labelKey?: string;
  onChange?: (
    payload: {
      newValue: string;
      labelKey: string | undefined;
    } | null
  ) => void;
  disableOnBlurClear?: boolean;
}>;

const LabelValueGroup: React.FC<LabelValueGroupProps> = (props) => {
  const { label, labelKey, value, onChange } = props;

  const [activeValue, updateActiveValue] = useState<string>(value);

  useEffect(() => {
    updateActiveValue(value);
  }, [value]);

  return (
    <div className={styles.labelValueGroup}>
      <div>{label}</div>

      <InputGroup
        value={activeValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          updateActiveValue(newValue);

          if (typeof onChange === 'function') {
            onChange({ newValue, labelKey });
          }
        }}
        maxLength={70}
        onBlur={() => {
          updateActiveValue(value);

          if (typeof onChange === 'function' && !props.disableOnBlurClear) {
            // 为“立即更新”按钮争取时间
            // 这里状态管理太难实现了，只能想到这种不优雅的办法了
            setTimeout(() => {
              onChange(null);
            }, 100);
          }
        }}
      />
    </div>
  );
};

export default LabelValueGroup;
