import { Button, Intent } from '@blueprintjs/core';
import React, { useEffect, useRef, useState } from 'react';

import { PatientDetails } from '../../PatientDetails';
import LabelValueGroup from './components/LabelValueGroup';
import SelectGroup from './components/SelectGroup';
import styles from './index.module.scss';
import { EditingLableValueGroup, LabelValueType } from './types';

type SocialExpTabProps = Readonly<{
  patientDetails: PatientDetails;
  updatePatientInfo: (payload: Object) => void;
  isUpdatePatientLoading: boolean;
}>;

const beverageVolumeList = ['少于100ml', '100~200ml', '200~300ml', '300ml以上'];

const SocialExpTabContent: React.FC<SocialExpTabProps> = (props) => {
  const { patientDetails } = props;

  const labelValueList = [
    {
      id: 0,
      label: (
        <>
          <span>咖啡日均摄入</span>
        </>
      ),
      key: 'coffeeIntake',
      value: patientDetails.coffeeIntake,
      type: LabelValueType.SELECT,
      selectList: beverageVolumeList,
    },
    {
      id: 1,
      label: '日均饮酒',
      key: 'wineIntake',
      value: patientDetails.wineIntake,
      type: LabelValueType.SELECT,
      selectList: beverageVolumeList,
    },
    {
      id: 2,
      label: '日均饮茶',
      key: 'teaIntake',
      value: patientDetails.teaIntake,
      type: LabelValueType.SELECT,
      selectList: beverageVolumeList,
    },
  ];

  const [
    editingValue,
    updateEditingValue,
  ] = useState<EditingLableValueGroup | null>(null);

  const previousLoadingState = useRef<boolean>(false);
  useEffect(() => {
    if (previousLoadingState && !props.isUpdatePatientLoading) {
      updateEditingValue(null);
    }

    previousLoadingState.current = props.isUpdatePatientLoading;
  }, [props.isUpdatePatientLoading]);

  return (
    <section>
      <div className={styles.diagnosisWrapper}>
        {labelValueList.map((labelValueItem) => (
          <SelectGroup
            key={labelValueItem.id}
            label={labelValueItem.label}
            labelKey={labelValueItem.key}
            value={labelValueItem.value}
            optionList={labelValueItem.selectList}
            onChange={(payload) => {
              if (payload === null) {
                updateEditingValue(null);
                return;
              }

              if (payload.labelKey === undefined) {
                return;
              }

              const { newValue, labelKey } = payload;

              updateEditingValue({
                key: labelKey,
                value: newValue,
              });
            }}
          />
        ))}
      </div>
      <div className={styles.updateUserInfoBtnWrapper}>
        {editingValue !== null && (
          <Button
            intent={Intent.PRIMARY}
            text="立即更新"
            onClick={() => {
              if (editingValue === null || editingValue.key === undefined) {
                return;
              }

              const uploadPayload: { [index: string]: string | number } = {};
              uploadPayload[editingValue.key] = editingValue.value;

              props.updatePatientInfo(uploadPayload);
            }}
            loading={props.isUpdatePatientLoading}
          />
        )}
      </div>
    </section>
  );
};

export default SocialExpTabContent;
