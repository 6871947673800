import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Position } from '@blueprintjs/core';

import { GlobalState, Dispatch } from '../../../store/configureStore';
import { retrieveArticleList } from '../../../actions/articles';
import {
  retrievePatientList,
  retrievePatientListByLabelId,
  retrievePatientListByGroupId,
  retrievePatientListByDoctor,
  retrievePatientListByLabelGroupId,
} from '../../../actions/patients';
import { PatientsState } from '../../../reducers/patients';
import PatientList from './PatientList';
import FilterList, { FilterType } from './FilterList';

import styles from './index.module.scss';
import { UserInfoState } from '../../../reducers/userInfo';
import {
  retrievePatientGroupList,
  retrieveLabelList,
  retrieveDoctorGroupList,
} from '../../../actions/userInfo';
import { AuthState } from '../../../reducers/auth';
import PatientListByGroup from './PatientListByGroup';
import PatientListByGroupEditing from './PatientListByGroupEditing';

type PatientListDrawerStateProps = Readonly<{
  userInfoModel: UserInfoState;
  patientsModel: PatientsState;
  authModel: AuthState;
}>;

type PatientListDrawerDispatchProps = Readonly<{
  retrieveArticleList: () => void;
  retrievePatientList: (ifGetFromFirstPage?: boolean) => void;
  retrievePatientGroupList: () => void;
  retrievePatientListByLabelId: (
    ifGetFromFirstPage: boolean,
    labelId: number
  ) => void;
  retrievePatientListByGroupId: (
    ifGetFromFirstPage: boolean,
    groupId: number
  ) => void;
  retrievePatientListByLabelGroupId: (
    ifGetFromFirstPage: boolean,
    groupId: number
  ) => void;
  retrievePatientListByDoctor: (ifGetFromFirstPage?: boolean) => void;
  retrieveLabelList: () => void;
  retrieveDoctorGroupList: () => void;
}>;

type PatientListDrawerOwnProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  selectedPatientId: number | null;
}>;

const PatientListDrawer: React.FC<
  PatientListDrawerStateProps &
    PatientListDrawerDispatchProps &
    PatientListDrawerOwnProps
> = (props) => {
  // 筛选类型
  const [filterType, updateFilterType] = useState<FilterType>(
    FilterType.following
  );

  // 分组查看时，正在编辑状态下的分组 id
  const [editingPatientGroupId, updateEditingPatientGroupId] = useState<
    number | null
  >(null);

  useEffect(() => {
    props.retrievePatientList(true);
  }, []);

  return (
    <Drawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      position={Position.LEFT}
    >
      <div className={styles.drawerContentWrapper}>
        <FilterList
          userInfoModel={props.userInfoModel}
          authModel={props.authModel}
          retrievePatientGroupList={props.retrievePatientGroupList}
          retrievePatientListByLabelGroupId={
            props.retrievePatientListByLabelGroupId
          }
          retrieveLabelList={props.retrieveLabelList}
          retrieveFollowingPatientList={props.retrievePatientList}
          retrievePatientListByLabelId={props.retrievePatientListByLabelId}
          retrievePatientListByGroupId={props.retrievePatientListByGroupId}
          retrieveDoctorGroupList={props.retrieveDoctorGroupList}
          retrievePatientListByDoctor={props.retrievePatientListByDoctor}
          filterType={filterType}
          updateFilterType={updateFilterType}
          updateEditingPatientGroupId={updateEditingPatientGroupId}
        />
        {/* 医生团队病患列表 */}
        {filterType === FilterType.byDoctorGroup && (
          <PatientListByGroup
            selectedPatientId={props.selectedPatientId}
            onPatientSelected={props.onClose}
          />
        )}

        {/* 分组查看 编辑状态时可勾选编辑的患者列表（主要功能是选择成员进入患者分组） */}
        {filterType === FilterType.byPatientsGroupEditing &&
          editingPatientGroupId !== null && (
            <PatientListByGroupEditing
              patientsModel={props.patientsModel}
              retrievePatientListByDoctor={props.retrievePatientListByDoctor}
              onConfirmCallback={() => {
                updateFilterType(FilterType.byPatientsGroup);
                props.retrievePatientListByLabelGroupId(
                  true,
                  editingPatientGroupId
                );
              }}
              editingPatientGroupId={editingPatientGroupId}
            />
          )}

        {/* 普通病患列表 */}
        {![
          FilterType.byDoctorGroup,
          FilterType.byPatientsGroupEditing,
        ].includes(filterType) && (
          <PatientList
            patientsModel={props.patientsModel}
            selectedPatientId={props.selectedPatientId}
            onPatientSelected={props.onClose}
          />
        )}

        <Button
          className={styles.drawerTriggerBtn}
          onClick={() => {
            props.onClose();
          }}
          minimal
        >
          <i className="pyr pyrP_list_button" />
        </Button>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  patientsModel: state.patients,
  userInfoModel: state.userInfo,
  authModel: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  retrieveArticleList: () => {
    dispatch(retrieveArticleList(true));
  },

  retrievePatientList: (ifGetFromFirstPage: boolean = false) => {
    dispatch(retrievePatientList(ifGetFromFirstPage));
  },

  retrievePatientGroupList: () => {
    dispatch(retrievePatientGroupList());
  },

  retrievePatientListByLabelGroupId: (
    ifGetFromFirstPage: boolean,
    groupId: number
  ) => {
    dispatch(retrievePatientListByLabelGroupId(ifGetFromFirstPage, groupId));
  },

  retrieveLabelList: () => {
    dispatch(retrieveLabelList());
  },

  retrieveDoctorGroupList: () => {
    dispatch(retrieveDoctorGroupList());
  },

  retrievePatientListByLabelId: (
    ifGetFromFirstPage: boolean,
    labelId: number
  ) => {
    dispatch(retrievePatientListByLabelId(ifGetFromFirstPage, labelId));
  },

  retrievePatientListByGroupId: (
    ifGetFromFirstPage: boolean,
    groupId: number
  ) => {
    dispatch(retrievePatientListByGroupId(ifGetFromFirstPage, groupId));
  },

  retrievePatientListByDoctor: (ifGetFromFirstPage?: boolean) => {
    dispatch(retrievePatientListByDoctor(ifGetFromFirstPage));
  },
});

export default connect<
  PatientListDrawerStateProps,
  PatientListDrawerDispatchProps,
  PatientListDrawerOwnProps,
  GlobalState
>(
  mapStateToProps,
  mapDispatchToProps
)(PatientListDrawer);
