/**
 * 分组查看 编辑状态时展示的可勾选操作（checkable）的患者列表
 */
import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';

import styles from './index.module.scss';
import { PatientItem, PatientsState } from '../../../reducers/patients';
import { PatientListResponse } from '../../../actions/patients';
import PatientItemView from './PatientItemView';
import PyrSpinner from '../../common/PyrSpinner';
import { Button, Intent } from '@blueprintjs/core';
import { AxiosResponse } from 'axios';
import requests from '../../../utils/requests';
import { loadUserTokenFromLocal } from '../../../utils/auth';
import { apiConfig } from '../../../constants/apiConfig';
import { warningToast } from '../../../utils/toaster';

type PatientListByGroupEditingProps = Readonly<{
  patientsModel: PatientsState;
  retrievePatientListByDoctor: (ifGetFromFirstPage?: boolean) => void;
  onConfirmCallback: () => void;
  editingPatientGroupId: number;
}>;

const PatientListByGroupEditing: React.FC<PatientListByGroupEditingProps> = (
  props
) => {
  const {
    patientsModel,
    retrievePatientListByDoctor,
    editingPatientGroupId,
    onConfirmCallback,
  } = props;

  const [checkedIdList, updateCheckedIdList] = useState<number[]>([]);

  const [groupPatientIdList, updateGroupPatientIdList] = useState<number[]>([]);
  const [
    isGroupPatientIdListLoading,
    setGroupPatientIdListLoading,
  ] = useState<boolean>(false);

  const onPatientSelected = () => {};

  useEffect(() => {
    const retrievePatientListByLabelGroupId = async () => {
      const tokenResult = await loadUserTokenFromLocal();

      if (!tokenResult.success) {
        return;
      }

      setGroupPatientIdListLoading(true);

      const res: AxiosResponse<PatientListResponse> = await requests.post(
        `${apiConfig.GET_PATIENT_LIST_BY_GID}?${queryString.stringify({
          page: 1,
          pageSize: 5000,
          gId: editingPatientGroupId,
        })}`,
        {},
        {
          headers: { token: tokenResult.token },
        }
      );

      if (res.data.success) {
        updateGroupPatientIdList(
          res.data.page.list.map((item) => item.patientId)
        );

        updateCheckedIdList(res.data.page.list.map((item) => item.patientId));
      } else {
        warningToast(res.data.message);
      }

      setGroupPatientIdListLoading(false);
    };

    retrievePatientListByDoctor(true);
    retrievePatientListByLabelGroupId();
  }, [
    retrievePatientListByDoctor,
    editingPatientGroupId,
    setGroupPatientIdListLoading,
  ]);

  // 其它分组的患者移除列表
  const [otherGroupRemovalIdList, updateOtherGroupRemovalIdList] = useState<
    number[]
  >([]);

  const [isConfirmLoading, setOnConfirmLoading] = useState<boolean>(false);

  const onConfirm = async () => {
    const tokenResult = await loadUserTokenFromLocal();

    if (!tokenResult.success) {
      return;
    }

    setOnConfirmLoading(true);

    const previousGroupPatientIdList = groupPatientIdList;

    await requests.post(
      apiConfig.EDIT_PATIENT_LABEL,
      [
        // 被选择移出当前分组的患者列表
        ...previousGroupPatientIdList
          .filter(
            (previousGroupPatientId) =>
              !checkedIdList.includes(previousGroupPatientId)
          )
          .map((uncheckedPreviousGroupPatientid) => ({
            labelId: null,
            patientId: uncheckedPreviousGroupPatientid,
          })),

        // 属于其它分组被当前页面的操作移除出了其所属分组，
        // 且没有进一步加入当前分组的患者列表
        ...otherGroupRemovalIdList.map((otherGroupRemovalId) => ({
          labelId: null,
          patientId: otherGroupRemovalId,
        })),

        // 被选择加入分组的患者列表
        ...checkedIdList.map((checkedId) => ({
          labelId: editingPatientGroupId,
          patientId: checkedId,
        })),
      ],
      {
        headers: { token: tokenResult.token },
      }
    );

    onConfirmCallback();
  };

  return (
    <div className={styles.patientListByGroupEditing}>
      <div className={styles.patientList}>
        {patientsModel.list.map((patientItem) => {
          const { patientId } = patientItem;
          const isChecked =
            (checkedIdList.includes(patientId) ||
              (patientItem.currentGroupId !== props.editingPatientGroupId &&
                patientItem.currentGroupId !== null)) &&
            !otherGroupRemovalIdList.includes(patientId);

          /**
           * 分组消极状态
           *
           * 表示当前用户拥有分组，但并不在当前分组列表的分组中
           */
          const isNegative =
            patientItem.currentGroupId !== null &&
            patientItem.currentGroupId !== editingPatientGroupId &&
            !otherGroupRemovalIdList.includes(patientId) &&
            !checkedIdList.includes(patientId);

          return (
            <PatientItemView
              data={patientItem}
              key={patientId}
              onPatientSelected={onPatientSelected}
              isSelected={false}
              checkMode={{
                isChecked,
                onCheckboxClick: () => {
                  if (isNegative) {
                    updateOtherGroupRemovalIdList([
                      ...otherGroupRemovalIdList,
                      patientId,
                    ]);

                    return;
                  }

                  if (
                    patientItem.currentGroupId !== null &&
                    patientItem.currentGroupId !== editingPatientGroupId
                  ) {
                    updateOtherGroupRemovalIdList(
                      isChecked
                        ? [...otherGroupRemovalIdList, patientId]
                        : otherGroupRemovalIdList.filter(
                            (id) => id !== patientId
                          )
                    );
                  }

                  updateCheckedIdList(
                    isChecked
                      ? checkedIdList.filter(
                          (checkedId) => checkedId !== patientId
                        )
                      : [...checkedIdList, patientId]
                  );
                },
              }}
              isNegative={isNegative}
            />
          );
        })}
        {(patientsModel.isLoading || isGroupPatientIdListLoading) && (
          <PyrSpinner />
        )}
        {patientsModel.hasBeenToTheEnd && (
          <p className={styles.fullyLoadedDesciption}>已加载全部内容</p>
        )}
      </div>
      <div className={styles.patientListByGroupEditing__confirmBtnWrapper}>
        <Button
          intent={Intent.PRIMARY}
          loading={isConfirmLoading}
          text="确定"
          onClick={onConfirm}
          large
        />
        <Button
          intent={Intent.PRIMARY}
          loading={isConfirmLoading}
          text="取消"
          onClick={onConfirmCallback}
          minimal
          large
        />
      </div>
    </div>
  );
};

export default PatientListByGroupEditing;
