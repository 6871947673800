import produce from 'immer';

import { SetAuthenticatedAction, SET_AUTHENTICATED, SET_UNAUTHENTICATED,SetUnauthenticatedAction } from '../actions/auth';
import { updateUserTokenToLocal, removeUserTokenFromLocal } from '../utils/auth';

type SignedState = Readonly<{
  isAuthenticated: true;
  token: string;
}>;

type UnsignedState = Readonly<{
  isAuthenticated: false;
  token: null;
}>;

export type AuthState = SignedState | UnsignedState;

const defaultAuthState: AuthState = {
  isAuthenticated: false,
  token: null,
};

export default function auth(
  state = defaultAuthState,
  action: SetAuthenticatedAction|SetUnauthenticatedAction
) {
  switch (action.type) {
    case SET_AUTHENTICATED: {
      const { token } = action.payload;
      updateUserTokenToLocal(token);

      return produce(state, (draftState) => {
        draftState.isAuthenticated = true;
        draftState.token = token;
      });
    };
    case SET_UNAUTHENTICATED: {
      removeUserTokenFromLocal();

      return produce(state, (draftState) => {
        draftState.isAuthenticated = false;
        draftState.token = null;
      });
    }
  }
  return state;
}
