import React from 'react';
import classNames from 'classnames';
import { MoreOutlined } from '@ant-design/icons';

import ShenShanXian from '../../assets/images/tempSvg/shen_shan_xian.png';
import XuYongZhi from '../../assets/images/tempSvg/xu_yong_zhi.png';
import ZhouGuangMing from '../../assets/images/tempSvg/zhou_guang_ming.png';
import styles from './index.module.scss';

const MyPatientsView: React.FC<{}> = (props) => {
  return (
    <div className={`${styles.listGroup} ${styles.listGroup__myPatient}`}>
      <p className={styles.listGroup__title}>我的患者</p>
      <button
        className={classNames({
          [styles['listGroup__myPatient__patientBtn']]: true,
          [styles['listGroup__myPatient__patientBtn--active']]: true,
        })}
      >
        <div>
          <div className={styles.listGroup__myPatient__avatar}>
            <img src={ShenShanXian} alt="" />
          </div>
          <span className={styles.listGroup__myPatient__username}>沈善贤</span>
        </div>
        <div>
          <span className={styles.listGroup__myPatient__defaultIndicator}>
            默认
          </span>
          <button className={styles.usernameWrapper__more}>
            <MoreOutlined size={30} />
          </button>
        </div>
      </button>
      <button
        className={classNames({
          [styles['listGroup__myPatient__patientBtn']]: true,
          [styles['listGroup__myPatient__patientBtn--active']]: false,
        })}
      >
        <div>
          <div className={styles.listGroup__myPatient__avatar}>
            <img src={XuYongZhi} alt="" />
          </div>
          <span className={styles.listGroup__myPatient__username}>徐永芝</span>
        </div>
        <div>
          <button className={styles.usernameWrapper__more}>
            <MoreOutlined size={30} />
          </button>
        </div>
      </button>
      <button
        className={classNames({
          [styles['listGroup__myPatient__patientBtn']]: true,
          [styles['listGroup__myPatient__patientBtn--active']]: false,
        })}
      >
        <div>
          <div className={styles.listGroup__myPatient__avatar}>
            <img src={ZhouGuangMing} alt="" />
          </div>
          <span className={styles.listGroup__myPatient__username}>周光明</span>
        </div>
        <div>
          <button className={styles.usernameWrapper__more}>
            <MoreOutlined size={30} />
          </button>
        </div>
      </button>
      <button
        className={classNames({
          [styles['listGroup__myPatient__patientBtn']]: true,
          [styles['listGroup__myPatient__patientBtn--active']]: false,
        })}
      >
        <div>
          <div className={styles.listGroup__myPatient__avatar}>
            <img
              src="https://oss.payouran.zjhospital.com.cn/pyr-image/20201210/184/1772784568.jpg"
              alt=""
            />
          </div>
          <span className={styles.listGroup__myPatient__username}>蒋明芳</span>
        </div>
        <div>
          <button className={styles.usernameWrapper__more}>
            <MoreOutlined size={30} />
          </button>
        </div>
      </button>

      {/* 添加患者 */}
      <button className={styles.listGroup__myPatient__addNewPatientBtn}>
        + 添加新患者
      </button>
    </div>
  );
};

export default MyPatientsView;
